import React, { useState, useEffect } from 'react';

const FormTable = () => {
    const [forms, setForms] = useState([]);
    const [editingForm, setEditingForm] = useState(null);

    useEffect(() => {
        fetchForms();
    }, []);

    const fetchForms = async () => {
        try {
            const response = await fetch('https://connectmydesigner.com/api/forms');
            const data = await response.json();
            setForms(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
    };

    const handleEdit = (form) => {
        setEditingForm(form);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`https://connectmydesigner.com/api/forms/${id}`, {
                method: 'DELETE',
            });
            fetchForms();
        } catch (error) {
            console.error('Error deleting form:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setEditingForm({
            ...editingForm,
            [type === 'checkbox' ? value : name]: type === 'checkbox' ? checked : value
        });
    };

    const handleUpdate = async () => {
        try {
            await fetch(`https://connectmydesigner.com/api/forms/${editingForm._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingForm),
            });
            setEditingForm(null);
            fetchForms();
        } catch (error) {
            console.error('Error updating form:', error);
        }
    };

    return (
        <div className='container'>
            <h2>Form Submissions</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Residential</th>
                        <th>Commercial</th>
                        <th>Exterior</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {forms.map((form) => (
                        <tr key={form._id}>
                            <td>{form.email}</td>
                            <td>{form.Residential3d ? 'Yes' : 'No'}</td>
                            <td>{form.Commercial3d ? 'Yes' : 'No'}</td>
                            <td>{form.Exterior3d ? 'Yes' : 'No'}</td>
                            <td>{form.status}</td>
                            <td>
                                <button onClick={() => handleEdit(form)}>Edit</button>
                                <button onClick={() => handleDelete(form._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingForm && (
                <div className='edit-form'>
                    <h3>Edit Form</h3>
                    <form onSubmit={handleUpdate}>
                        <div>
                            <label>Email</label>
                            <input type='email' name='email' value={editingForm.email} onChange={handleChange} readOnly />
                        </div>
                        <div>
                            <input type='checkbox' value='Residential3d' checked={editingForm.Residential3d} onChange={handleChange} /> Residential
                        </div>
                        <div>
                            <input type='checkbox' value='Commercial3d' checked={editingForm.Commercial3d} onChange={handleChange} /> Commercial
                        </div>
                        <div>
                            <input type='checkbox' value='Exterior3d' checked={editingForm.Exterior3d} onChange={handleChange} /> Exterior
                        </div>
                        <div>
                            <label>Status</label>
                            <select name='status' value={editingForm.status} onChange={handleChange}>
                                <option value='UnderVerification'>Under Verification</option>
                                <option value='Verified'>Verified</option>
                                <option value='Rejected'>Rejected</option>
                                <option value='UnderReview'>Under Review</option>
                                <option value='Other'>Other</option>
                            </select>
                        </div>
                        <button type='button' onClick={handleUpdate}>Update</button>
                        <button type='button' onClick={() => setEditingForm(null)}>Cancel</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default FormTable;
