import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Dos from './dos';
import Dont from './dont';
import FooterDesigner from '../designer/footer-designer.js';
import HeaderUser from "../user/header-user";
import PaymentGuidelines from './PaymentGuidelines';

const CommunityGuidelines = () => {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(true);

    const toggleGuidelines = () => {
        setShowCommunityGuidelines(!showCommunityGuidelines);
    };

    return (
        <div className=''>
            <HeaderUser/>
            <div className='container mt-lg-5 pt-5 mt-3'>
           
                <div className="d-flex col-12">
                    <h4 className={`  ${showCommunityGuidelines ? 'gradient-text col-lg-3 col-4' : 'normal-text col-lg-3 col-4'}`}>Community Guidelines</h4>
                    <label className="switch my-auto mb-lg-2">
                        <input type="checkbox" onChange={toggleGuidelines} checked={!showCommunityGuidelines} />
                        <span className="slider"></span>
                    </label>
                    <h4 className={`  ${!showCommunityGuidelines ? 'gradient-text col-lg-3 col-4' : 'normal-text col-lg-3 col-4'}`}>Payment Guidelines</h4> 
               
            </div>
          
        </div>
            <style jsx>{`
                .switch .slider {
                    transition: 0.4s;
                }
                .switch input:checked + .slider {
                    transition: 0.4s;
                }
                .fade-enter {
                    opacity: 0;
                }
                .fade-enter-active {
                    opacity: 1;
                    transition: opacity 300ms ease-in;
                }
                .fade-exit {
                    opacity: 1;
                }
                .fade-exit-active {
                    opacity: 0;
                    transition: opacity 300ms ease-in;
                }
            `}</style>
            <div className="tab-content container  my-4 px-lg-5">
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={showCommunityGuidelines ? "community" : "payment"}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade"
                    >
                        {showCommunityGuidelines ? (
                            <div className='row py-5'>
                                <div className='col-lg-6 px-3'>
                                    <Dos />
                                </div>
                                <div className='col-lg-6 px-'>
                                    <Dont />
                                </div>
                            </div>
                        ) : (
                            <PaymentGuidelines />
                        )}
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <FooterDesigner/>
        </div>
    );
};

export default CommunityGuidelines;