import React, { useState } from 'react';
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';

const tnclist = [
    {
        title: "1. Information We Collect",
        points: [
            "A. Personal Information:",
            " ●  Name, email address, phone number, city, and other contact details.",
            " ●  Profile information such as skills, portfolio, and qualifications.",
            " ●  Payment and transaction information (billing addresses, bank details, etc.).",
            "B. Non-Personal Information:",
            " ●  Usage data (e.g., IP address, browser type, device information, pages viewed).",
            " ●  Cookies and similar technologies to enhance user experience."
        ]
    },
    {
        title: "2. How We Use Your Information",
        points: [
            " ●  To Provide Services: We use your personal data to facilitate communication between designers and clients, manage accounts, and provide project-based services.",
            " ●  To Personalize Your Experience: We tailor recommendations and content based on your activity and profile information.",
            " ●  For Payments and Transactions: We process payments and ensure secure transactions through encrypted channels.",
            " ●  To Improve Our Services: We use aggregate data to analyze trends, monitor performance, and develop new features for CMD.",
            " ●  For Communication: We may send you service-related emails (e.g., updates, support messages, newsletters) that are essential to the platform's operation."
        ]
    },
    {
        title: "3. Data Sharing and Disclosure",
        points: [
            " ●  Service Providers: We may share your information with trusted third-party vendors who help operate our platform, such as payment processors, hosting services, and email providers.",
            " ●  Legal Compliance: We may disclose your data if required by law or in response to valid legal requests (e.g., court orders, government investigations).",
            " ●  Business Transfers: In the event of a merger, acquisition, or sale of CMD's assets, your personal information may be transferred as part of that transaction."
        ]
    },
    {
        title: "4. Data Security",
        points: [
            " ●  CMD is committed to protecting your information. We use appropriate administrative, technical, and physical security measures to protect your personal data from unauthorized access, disclosure, or alteration. These measures include encryption, secure servers, and access control protocols.",
            " ●  However, please be aware that no method of transmission over the internet is completely secure, and we cannot guarantee absolute security."
        ]
    },
    {
        title: "5. Cookies and Tracking Technologies",
        points: [
            "CMD uses cookies and similar tracking technologies to enhance your user experience by:",
            " ●  Remembering your preferences.",
            " ●  Analyzing website traffic and user behavior.",
            " ●  Offering personalized content and ads.",
            "You can control or disable cookies through your browser settings, but please note that doing so may limit your access to some of CMD's features."
        ]
    },
    {
        title: "6. Your Rights and Choices",
        points: [
            " ●  You have the following rights regarding your personal information:",
            " ●  Access: You can request access to the data we have about you.",
            " ●  Correction: You can request corrections to inaccurate or incomplete information.",
            " ●  Deletion: You may request the deletion of your personal data, subject to certain legal obligations.",
            " ●  Opt-Out: You can opt out of receiving marketing communications at any time by following the instructions in our emails or contacting us directly.",
            " ●  To exercise any of these rights, please contact our support team at hello@connectmydesigner.com"
        ]
    },
    {
        title: "7. Retention of Data",
        points: [
            " ● CMD retains your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Once your data is no longer needed, it will be deleted or anonymized."
        ]
    },
    {
        title: "8. International Data Transfers",
        points: [
            " ● If you are accessing CMD from outside India, please note that your data may be transferred to, processed, and stored in India. By using our services, you consent to the transfer of your data to India, where privacy regulations may differ from those in your country."
        ]
    },
    {
        title: "9. Children's Privacy",
        points: [
            " ● CMD is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we discover that we have inadvertently collected data from someone under 18, we will promptly delete it."
        ]
    },
    {
        title: "10. Changes to This Privacy Policy",
        points: [
            " ● We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with the updated \"Effective Date.\" Your continued use of CMD after the changes go into effect signifies your acceptance of the revised policy."
        ]
    },
    {
        title: "11. Contact Us",
        points: [
            " ● If you have any questions or concerns about this Privacy Policy or CMD's data practices, please contact us:",
            " ● Email: hello@connectmydesigner.com",
            " ● Mailing Address: Connect My Designer Swarajya building 101, opposite Dot fit Gym, near Jupiter Hospital, Prathamesh Park, Baner, Pune, Maharashtra 411045"
        ]
    }
];

const PrivacyPolicy = () => {
    const [selectAll, setSelectAll] = useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(tnclist.length).fill(false)
    );

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckedState(new Array(tnclist.length).fill(newSelectAll));
    };

    const handleItemCheck = (index) => {
        const updatedCheckedState = checkedState.map((item, idx) =>
            idx === index ? !item : item
        );

        setCheckedState(updatedCheckedState);
        setSelectAll(updatedCheckedState.every(item => item));
    };

    return (
        <div>
            <HeaderUser />

            <div className='container px-lg-5'>
                <div className=' col-12'>
                    <div className=' w-100 border-top pt-1'>
                        <div className=' ps-3 d-flex pt-lg-5' style={{ borderRadius: "10px 10px 10px var(--Cart-Till, 0px)" }}>
                            <h2 className='  my-auto ps-2 py-5 gradient-text'>Privacy Policy of Connect My Designer</h2>
                        </div>
                        <div>
                            <p>  At <span className='fw-bold'>Connect My Designer (CMD)</span>, we prioritize your privacy and are committed to safeguarding
                                your personal information. This Privacy Policy explains how we collect, use, share, and protect
                                your data when you visit or use our website, mobile app, and services.</p>
                            <p>
                                By accessing or using CMD, you agree to this Privacy Policy. If you do not agree with this policy,
                                please do not use our services    </p>
                        </div>

                        {tnclist.map((dontItem, index) => (
                            <div className=' rounded-3 my-2' key={index}>
                                <div className='dosdontstext rounded-top-3 px-2 py-1 fw-semibold '>
                                    <span className='px-2'>{dontItem.title}</span>
                                </div>
                                <div className='text-gray-400 rounded-bottom-3 px-2 py-1 '>
                                    {dontItem.points.map((point, idx) => (
                                        <div className='d-flex' key={idx}>
                                            {/* <i className="fa-solid fa-circle p-2 font-10px"></i> */}
                                            <span>{point}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <FooterDesigner />
        </div>
    );
};
export default PrivacyPolicy;