import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';  
import PortfolioFormVid from './PortfolioFormVid';  
import ManagePortfolioVid from './ManagePortfolioVid';  
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

const MyPortfolioGroupsVid = () => {
  const [groups, setGroups] = useState([]);
  const [images, setImages] = useState({});
  const [showAddGroupModal, setShowAddGroupModal] = useState(false); 
  const [showManageModal, setShowManageModal] = useState(false); 
  const [selectedGroup, setSelectedGroup] = useState(null); 
  const { user } = useAuth(); 

  const fetchMyGroups = async () => {
    try {
      const response = await axios.get('https://connectmydesigner.in/Vidportfolioapi/mygroups', {
        params: { userEmail: user.email }
      });
      setGroups(response.data);

      response.data.forEach(group => fetchGroupImages(group._id));
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  const fetchGroupImages = async (groupId) => {
    try {
      const response = await axios.get(`https://connectmydesigner.in/Vidportfolioapi/groups/${groupId}`);
      setImages(prevImages => ({ ...prevImages, [groupId]: response.data.images }));
    } catch (error) {
      console.error('Error fetching group images:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMyGroups();
    }
  }, [user]);

  const handleManageGroup = (group) => {
    setSelectedGroup(group);
    setShowManageModal(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className='px-1 py-3 border-top border-bottom'>
      <div className='d-flex justify-content-between '>
        <span className='fs-2 fw-bold'>Portfolio (Vr Video):</span>
      <div className='d-flex'>
      <button onClick={() => setShowAddGroupModal(true)} className='btn  border my-auto '>   Add New <i className="fa-solid fa-plus"></i>  </button>
      <Link to="/myallportfoliovid" className='btn border  my-auto mx-2'>Manage <i className="fa-solid fa-edit"></i></Link>
      </div>
      </div>
      <div className='d-flex flex-wrap'>
        {groups.length > 0 ? (
          groups.slice(0, 2).map(group => (  // Limiting to first 2 groups
            <div key={group._id} className=' col-6'>
              <div className='d-flex position-relative '>
                <span className='fs-4 px-2 position-absolute  z-max  w-100 px-2 top-0 start-0'> {group.groupName}</span>
                {/* <button onClick={() => handleManageGroup(group)} className='btn btn-primary mx-2'>   Manage       </button> */}
              </div>
              <div className=' px-4 py-2 '>
                <Slider {...sliderSettings}>
                  {images[group._id] && images[group._id].map((image, index) => (
                    <div key={index}>
                    

<video
                                               src={`https://connectmydesigner.com/${image}`}
                                                style={{ width: '100%', maxHeight: '40vh', objectFit: 'cover' }}
                                                // controls
                                                muted
                                                autoPlay
                                                loop
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ))
        ) : (
        <div className=' w-100  div-center my-3'>
          


              <button className="col-7 do border-dotted-1px pt-lg-5 px-lg-5 px-2 pt-4 pb-1 pb-lg-4 rounded bg-white mt-5" onClick={() => setShowAddGroupModal(true)} >
            <i className="fa fa-plus p-3 border-dotted-1px rounded-circle" aria-hidden="true"></i>
            <p className="py-3">Add New Portfolio Group</p>
          </button>
        </div>
        )}
      </div>

      {/* Modal for PortfolioForm (Add Group) */}
      {showAddGroupModal && (
        <div className='z-max'  style={{
          position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', width: '80vw', height: '80vh', padding: '20px', position: 'relative', overflowY: 'auto', borderRadius: '8px',
          }}>
            <button
              onClick={() => setShowAddGroupModal(false)}
              style={{
                position: 'absolute', top: '10px', right: '10px', fontSize: '20px', border: 'none', background: 'none', cursor: 'pointer'
              }}
            >
              &times;
            </button>
            <PortfolioFormVid />
          </div>
        </div>
      )}

      {/* Modal for ManagePortfolio (Manage Group) */}
      {showManageModal && selectedGroup && (
        <div className='z-max' style={{ 
          position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', width: '80vw', height: '80vh', padding: '20px', position: 'relative', overflowY: 'auto', borderRadius: '8px',
          }}>
            <button
              onClick={() => setShowManageModal(false)}
              style={{
                position: 'absolute', top: '10px', right: '10px', fontSize: '20px', border: 'none', background: 'none', cursor: 'pointer'
              }}
            >
              &times;
            </button>
            <ManagePortfolioVid group={selectedGroup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPortfolioGroupsVid;
