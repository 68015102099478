import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'bootstrap/dist/css/bootstrap.min.css';

const PrintButton = ({ designers }) => {
    const handlePrint = async (option) => {
        let filteredDesigners = [...designers];
        let columns = [];
        let rows = [];

        switch (option) {
            case 'printAll':
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printApproved':
                filteredDesigners = filteredDesigners.filter(d => d.verification === 'Approved');
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printRejected':
                filteredDesigners = filteredDesigners.filter(d => d.verification === 'Rejected');
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printOnHold':
                filteredDesigners = filteredDesigners.filter(d => d.verification === 'On Hold');
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printLatest100':
                filteredDesigners = filteredDesigners.slice(0, 100);
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printPending':
                filteredDesigners = filteredDesigners.filter(d => d.verification === 'Pending');
                columns = ['Name', 'Joining Date', 'Mobile No', 'Email', 'Design Category', 'Status'];
                rows = filteredDesigners.map(d => [
                    d.name,
                    new Date(d.joiningdate).toLocaleDateString(),
                    d.mobileNo,
                    d.email,
                    d.skills.join(', '),
                    d.verification
                ]);
                break;
            case 'printNameAndMobile':
                columns = ['Name', 'Mobile No'];
                rows = filteredDesigners.map(d => [d.name, d.mobileNo]);
                break;
            case 'printNameMobileEmail':
                columns = ['Name', 'Email'];
                rows = filteredDesigners.map(d => [d.name, d.email]);
                break;
            default:
                break;
        }

        const doc = new jsPDF();

        // Load the logo image
        const img = new Image();
        img.src = '/assets/images/cmdlogo.png';
        img.onload = () => {
            doc.addImage(img, 'PNG', 10, 10, 50, 20);
            const pageWidth = doc.internal.pageSize.getWidth();
            const title = 'Designers';
            const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const textOffset = (pageWidth - textWidth) / 2;
            doc.text(title, textOffset, 20);
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 30,
                theme: 'grid',
                styles: {
                    fontSize: 8,
                    cellPadding: 2,
                    overflow: 'linebreak'
                },
                headStyles: {
                    fillColor: [128, 0, 128] // Purple color for table heading
                },
                margin: { top: 20, right: 10, bottom: 10, left: 10 },
                tableWidth: 'wrap',
                columnStyles: {
                    0: { cellWidth: 'auto' },
                    1: { cellWidth: 'auto' },
                    2: { cellWidth: 'auto' },
                    3: { cellWidth: 'auto' },
                    4: { cellWidth: 50 }, // Fixed width for the Design Category column
                    5: { cellWidth: 'auto' }
                },
                didDrawPage: (data) => {
                    if (data.pageCount > 1) {
                        doc.setPage(data.pageCount);
                        doc.addImage(img, 'PNG', 10, 10, 50, 20);
                    }
                }
            });

            const date = new Date();
            const dateString = date.toLocaleDateString().replace(/\//g, '-');
            const timeString = date.toLocaleTimeString().replace(/:/g, '-');
            const fileName = `${option}-${dateString}-${timeString}.pdf`;

            doc.save(fileName);
        };

        // Reset the select element
        const selectElement = document.getElementById('printSelect');
        selectElement.value = '';
    };

    return (
        <div className='d-flex'>
            <div className='col-6'></div>
            <div className="custom-dropdown col-6  bg-yellow rounded border">
                
                <select id="printSelect" className="btn py-2 form-select " onChange={(e) => handlePrint(e.target.value)}>
                    <option value="">Print</option>
                    <option value="printAll">Print All</option>
                    <option value="printApproved">Print Approved</option>
                    <option value="printRejected">Print Rejected</option>
                    <option value="printOnHold">Print On Hold</option>
                    <option value="printLatest100">Print Latest 100</option>
                    <option value="printPending">Print Pending</option>
                    <option value="printNameAndMobile">Print Name and Mobile No</option>
                    <option value="printNameMobileEmail">Print Name and Email</option>
                </select>
            </div>
        </div>

    );
};

export default PrintButton;
