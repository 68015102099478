import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { syncUserProfile } from '../firebase'; // Assume syncUserProfile is exported from firebase.js

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification link
      await sendEmailVerification(user);

      // Sync the new user profile with your backend
      await syncUserProfile(user);

      // Show success message and inform user to verify their email
      setSuccess(true);
      setVerificationSent(true);
      setError(''); // Clear any previous errors

      // Sign the user out to prevent them from proceeding until verification
      await signOut(auth);

      // Show success message and hold on the page until email is verified
      const interval = setInterval(async () => {
        await user.reload(); // Reload user data to check for verification status
        if (user.emailVerified) {
          clearInterval(interval); // Clear the interval once verified
          navigate('/auth/login'); // Redirect to login page
        }
      }, 3000); // Check every 3 seconds

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists.');
      } else {
        setError('Error registering user: ' + error.message);
      }
    }
  };

  return (
    <div className=' d-flex div-center h-100vh bg-white  '>
      <div className=' d-flex div-center   col-12 '>

        <div className='col-6 d-lg-block d-none text-center padding-login text-white header-bg-dark'>
          <div className='p-5 container '>
            <div className='div-center'>
              <img className='w-75 p-5 ' src='/assets/images/login1.png' />
            </div>
            <div>
              <h2>
                Secured Payments
              </h2>
              <p>
                Use our escrow-based payment system for <br />
                secure transactions. Designers get paid <br />
                only after you approve the work.
              </p>
            </div>
          </div>
        </div>



        <div className="justify-content-md-center col-lg-7 pe-lg-5">
          <div className='text-center'>
          <a href="/"> <img
              src="/assets/images/logoblue.png"

              className="w-50 py-2"
              alt="CONNECT MY DESIGNER"
            /></a>
            <h2 className='fw-bold gradient-text py-3'>Register</h2>
            {error && <Alert className='font-13px p-2' variant="danger">{error}</Alert>} {success && (
              <Alert variant="success" className='font-13px p-2'>
                Registration successful! Please check your email for a verification link. You must verify your email before proceeding.
              </Alert>)}
            <Form onSubmit={handleRegister}>
              <Form.Group controlId="formBasicEmail">
                <input className='p-2  my-2 w-50 rounded border '
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <input className='p-2  my-2 w-50 rounded border '
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formConfirmPassword">
                <input className='p-2  my-2 w-50 rounded border '
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Button className='btn border-warning px-5 bg-yellow-200 text-dark ' type="submit" disabled={verificationSent}>
                {verificationSent ? 'Verification Sent...' : 'Proceed'}
              </Button>
            </Form>
            <p className='p-2'>Already have an account? <Link to="/auth/login">Login</Link></p>
          </div>
        </div>
      </div>



    </div>
  );
};

export default Register;
