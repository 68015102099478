import React, { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "../user/HeaderUser.css";

const Header = () => {
  const { user, logout } = useAuth();
  const [scrolled, setScrolled] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isFixed, setIsFixed] = useState(true); // New state for navbar fixed position
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const vh70 = window.innerHeight * 0.2;

      setScrolled(offset > 0);
      setIsFixed(offset < vh70); // Navbar is fixed until scroll reaches 70vh
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      logout();
      navigate("/");
    }
  };



  const renderDropdownMenu = () => {
    if (user) {
      return (
        <Dropdown.Menu className="profile-dropdown-menu">


          <Dropdown.Item as="button" onClick={() => navigate("/user/profile")}>
            Profile
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleLogout}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      );
    } else {
      return (
        <Dropdown.Menu className="profile-dropdown-menu">
          <Dropdown.Item as="button" className="btn border" onClick={() => navigate("/auth/login")}>
            Login
          </Dropdown.Item>
        </Dropdown.Menu>
      );
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <Navbar
        expand="lg"
        className={`navbar-custom pb-5  d-lg-block d-none pt-0 ${scrolled ? "scrolled" : ""} ${isFixed ? "fixed-top" : ""}`}
      >
        <div className="d-flex height-60px w-100vw ">
          <Navbar.Brand className=" cut-corner-box position-absulute d-md-block d-lg-block d-none m-0 p-0">
            <Link to="/">  <img
              src="/assets/images/cmdlogo-white.png"
              width="auto"
              height="80px"
              className="align-top px-2 py-0 m-0 ps-4"
              alt="Logo"
            /></Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" onClick={toggleMenu}>
            {menuOpen ? (
              <h3>&times;</h3> /* "X" icon */
            ) : (
              <h3>&#9776;</h3> /* Hamburger icon */
            )}
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="div-end ms-auto pt-4 mt-1 me-4 gap-2 font-20px ">
              <Nav.Link as={Link} to="/user/JoinCommunity" className="text-white text-center nav-links-hover">
                <span className="text-yellow">J</span>oin Our Community
              </Nav.Link>
              <Nav.Link as={Link} to="/user/CommunityGuidelines" className="text-white text-center nav-links-hover">
                <span className="text-yellow">P</span>latform Guidelines
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="text-white text-center nav-links-hover me-2">
                <span className="text-yellow">A</span>bout Us
              </Nav.Link>

              {/* <div className="d-flex col-lg-6 gap-2 px-lg-5"> */}
              <Button as={Link} to="/apply-as-designer" className="rounded w-200px ms-lg-auto text-black shadow border-gradient-btn-2 mx-3">
                Apply as a designer
              </Button>
              <Dropdown align="end">
                {user ? (
                  <Dropdown align="end" className="d-none d-lg-block my-auto">
                    <Dropdown.Toggle as="button" className="profile-button">


                      <img
                        // src={user.photoURL || '/assets/images/defouldp.png'}
                        src={user && user.photoURL ? user.photoURL : '/assets/images/defouldp.png'}
                        alt="Profile"
                        className="profile-icon"
                      />
                    </Dropdown.Toggle>
                    {renderDropdownMenu()}
                  </Dropdown>
                ) : (
                  <Nav.Link className="d-none d-lg-block">
                    <Link
                      to="/auth/login"
                      className="text-decoration-none text-white btn border-warning border-2 px-3"
                    >
                      Login
                    </Link>
                  </Nav.Link>
                )}
                {renderDropdownMenu()}
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="mobile menu">
        <ul className="nav-mobile d-block d-lg-none">
          <li className="d-flex px-2 ">
            
          
          <div className="mx-auto">
          <Link to="/">  <img  src="/assets/images/cmdlogo-white.png"   width="auto"  height="70px"   className="align-top"    alt="Logo"    /></Link>
          </div>
          {/* <button as={Link} to="/apply-as-designer" className="rounded btn p-1 my-auto  text-white border ms-auto ">
                Apply as a designer
              </button>
                   */}
          </li>
          
          <li className="menu-container">
            <input id="menu-toggle" type="checkbox" />
            <label htmlFor="menu-toggle" className="menu-button col-2">
              <svg className="icon-open" viewBox="0 0 24 24">
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
              <svg className="icon-close" viewBox="0 0 100 100">
                <path d="M83.288 88.13c-2.114 2.112-5.575 2.112-7.69 0L53.66 66.188c-2.113-2.112-5.572-2.112-7.686 0l-21.72 21.72c-2.114 2.113-5.572 2.113-7.687 0l-4.693-4.692c-2.114-2.114-2.114-5.573 0-7.688l21.72-21.72c2.112-2.115 2.112-5.574 0-7.687L11.87 24.4c-2.114-2.113-2.114-5.57 0-7.686l4.842-4.842c2.113-2.114 5.57-2.114 7.686 0l21.72 21.72c2.114 2.113 5.572 2.113 7.688 0l21.72-21.72c2.115-2.114 5.574-2.114 7.688 0l4.695 4.695c2.112 2.113 2.112 5.57-.002 7.686l-21.72 21.72c-2.112 2.114-2.112 5.573 0 7.686L88.13 75.6c2.112 2.11 2.112 5.572 0 7.687l-4.842 4.84z" />
              </svg>
            </label>
            <ul className="menu-sidebar">
              <li><Nav.Link as={Link} to="/user/JoinCommunity" className="text-dark">Join Our Community</Nav.Link></li>
              <li><Nav.Link as={Link} to="/user/CommunityGuidelines" className="text-dark">Community Guidelines</Nav.Link></li>
              <li><Nav.Link as={Link} to="/about" className="text-dark">About Us</Nav.Link></li>
              <li><Nav.Link as={Link} to="/howtostart"  className="text-dark">How To Start</Nav.Link></li>
              <li><Nav.Link as={Link} to="/apply-as-designer" className="text-dark">Apply as a Designer</Nav.Link></li>
              <li><Nav.Link as={Link} to="/user/profile" className="text-dark">Profile</Nav.Link></li>

             <li>
    {user ? (
      <Nav.Link as="button" onClick={handleLogout} className="text-dark">Logout</Nav.Link>
    ) : (
      <Nav.Link as={Link} to="/auth/login" className="text-dark">Login</Nav.Link>
    )}
  </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
