import React from 'react';
import AllRequestsTable from './helplist';

const ForgotPass = () => {
  return (
    <div>
    <AllRequestsTable/>
    </div>
  );
};

export default ForgotPass;
