
import React, { useState, useRef, useEffect } from 'react';
import FooterDesigner from '../designer/footer-designer.js';
import HeaderUser from "../user/header-user";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const JoinCommunity = () => {

  const [fontSizes, setFontSizes] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const calculateFontSize = (base, scale) => {
        return `${(base * (width / 1440) * scale).toFixed(2)}rem`;
      };

      setFontSizes({
        h1: calculateFontSize(2.5, 1.5),
        p: calculateFontSize(2, 0.8),

      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className=''>
      <HeaderUser />

      <div className='pt-lg-4'>
        <img className='w-100  ' src='/assets/images/Community.png' />

      </div>
      <div className='p-lg-5 p-3 d-lg-flex'>
        <div className="col-lg-6 col-12 py-md-5 ps-lg-4  my-auto">
          <h1 className="text-lg-start text-center line-height-lg " >
            <span className="gradient-text" style={{ fontSize: fontSizes.span }}> Our Community</span>

          </h1>

          <p className="auto-font-p py-3 text-lg-start text-center  text-gray-400" >
            At Connect My Designer (CMD), we believe in fostering a vibrant and supportive community for all our designers. When you join CMD, you become a part of India's first specialized marketplace for 3d designers  designers. You will be part of a global community of top 3D designers, VR walkthrough experts, and 2D drawing specialists.

          </p>
          <div className="my-4   ">

            {/* <button className="rounded col-sm-8 col-12  col-lg-4 py-2 px-3    shadow border-gradient-btn-2 ">Apply as a designer</button> */}
            <Button as={Link} to="/apply-as-designer" className="rounded  w-200px ms-lg-auto text-black shadow border-gradient-btn-2 mx-3">
                Apply as a designer
              </Button>

          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center justify-content-center">
          <img className='w-75 p-lg-4' src='/assets/images/Community2.png' />
        </div>

      </div>




      <div className='homebg-lite' style={{ backgroundImage: "url('/assets/images/bg-Benifits.png')", backgroundSize: "100% 100%" }}
      >
        <div className='container pt-lg-3 text-center why  '>
          <h1><span className="gradient-text  " style={{ fontSize: fontSizes.span }}> Why Join CMD?   </span></h1>
        </div>
        <div className='d-flex flex-wrap container px-lg-5 px-1  '>
          <div className='col-lg-3 col-6  px-lg-4 px-1 pt-lg-5 pt-3 '>
            <div className=' border pt-lg-2 shadow-sm my-lg-5 mt-2 mt-lg-0  rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon1.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Build Your Brand</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                  Create a unique profile, showcase your portfolio, and grow your personal brand.
                </p>
              </div>
            </div>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon2.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Secure Payments:</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Clients pay in advance, ensuring you get paid promptly without hassle.                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-6  px-lg-4 px-1 pt-lg-5 pt-3 '>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon3.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Exclusive Community</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Connect with top-tier designers and industry experts                </p>
              </div>
            </div>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon4.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Flexible Work Opportunities</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Connect with top-tier designers and industry experts                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-6  px-lg-4 px-1 pt-lg-5 pt-3 '>
          <div className=' border pt-lg-2 shadow-sm my-lg-5 mt-2 mt-lg-0  rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon5.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Constant Growth</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Take on more projects, enhance your earnings, and advance your career.                </p>
              </div>
            </div>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon6.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Projects Analysis </h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                view all project history in one place and see payments status.                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-6  px-lg-4 px-1 pt-lg-5 pt-3 '>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon7.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Advanced Tools & Resources</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Access state-of-the-art design tools and resources to elevate your work.                </p>
              </div>
            </div>
            <div className=' border pt-2 shadow-sm my-lg-5 mt-2 rounded-5 bg-white '>
              <div className='div-center '>
                <img className='w-50 px-2 py-lg-4 py-1' src='/assets/images/whyicon8.png' />
              </div>
              <div>
                <h3 className=" text-center line-height-lg font-auto-h3 " >Skill Enhancement</h3>
                <p className=" text-center line-height-lg text-gray-400 py-2 px-lg-4 px-1 " >
                Benefit from exclusive courses and training programs to upgrade your skills.                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div
  className='container py-5 Register-bg'
  style={{
    backgroundImage: "url('/assets/images/HowtoRegister.png')",
    backgroundSize: window.innerWidth <= 768 ? "100% 60%" : "50% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  }}
>
        <div className='container pt-lg-3 text-center why  '>
          <h1><span className="gradient-text  " style={{ fontSize: fontSizes.span }}> How to Register ?   </span></h1>
        </div>
        <div className='container py-3 px-lg-5 text-center text-gray-400'>
          <h5>
            Becoming a part of CMD is simple. Click the "Apply as a Designer" button below and fill out your profile. Once approved, you'll have access to a world of opportunities to showcase your talent and grow your business.
         
            Join CMD today and take the next step in your design career. Together, let's create amazing spaces and build a community of top designers.
          </h5>

          <Button as={Link} to="/apply-as-designer" className="rounded  w-200px ms-lg-auto text-black shadow border-gradient-btn-2 mx-3">
                Apply as a designer
              </Button>
          {/* <button className="rounded my-4  col-lg-4 col-sm-5 py-2 px-3   Display-6 shadow border-gradient-btn-2  ">Apply as a designer</button> */}
        </div>
      </div>
      <FooterDesigner/>
    </div>
  );
};

export default JoinCommunity;
