import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AllRequestsTable = () => {
   const [helpRequests, setHelpRequests] = useState([]);

   useEffect(() => {
      const fetchHelpRequests = async () => {
         try {
            const response = await axios.get('https://connectmydesigner.in/api/help');
            setHelpRequests(response.data);
         } catch (error) {
            console.error('Error fetching help requests', error);
         }
      };

      fetchHelpRequests();
   }, []);

   

   return (
      <div>
         <h2>All Help Requests</h2>
         <table border="1">
            <thead>
               <tr>
                  <th>Mobile Number</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Message</th>
                  <th>email</th>
               </tr>
            </thead>
            <tbody>
               {helpRequests.map((request, index) => (
                  <tr key={index}>
                     <td>{request.helpmobileNo}</td>
                     <td>{request.helpname}</td>
                     <td>{request.helpcategory}</td>
                     <td>{request.helpMessage}</td>
                     <td>{request.helpEmail}</td>
                  </tr>
               ))}
            </tbody>
         </table>










      </div>
   );
};

export default AllRequestsTable;
