import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import GoogleLoginButton from './GoogleLoginButton';
import { useAuth } from '../AuthContext';
import { Carousel } from 'react-bootstrap';
import { signInWithEmail, auth } from '../firebase';
import { AuthErrorCodes, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';

const Login = () => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [resetMode, setResetMode] = useState(false);
  const [oobCode, setOobCode] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleEmailLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmail(email, password);
      const loggedInUser = userCredential.user;

      if (loggedInUser.emailVerified) {
        setSuccess(true);
        // const redirectPath = location.state?.from || '/designer/home';
        // setTimeout(() => {
        //   navigate(redirectPath);
        // }, 2000);
        const redirectPath = location.state?.from?.pathname || '/';
setTimeout(() => {
  navigate(redirectPath);
}, 1000);

      } else {
        setError('Please verify your email before logging in.');
      }
    } catch (error) {
      setSuccess(false);
      switch (error.code) {
        case AuthErrorCodes.USER_DELETED:
          setError('No account found with this email.');
          break;
        case AuthErrorCodes.INVALID_PASSWORD:
          setError('Incorrect password.');
          break;
        case 'auth/invalid-credential':
          setError('No email or password matching.');
          break;
        case AuthErrorCodes.INVALID_EMAIL:
          setError('Invalid email format.');
          break;
        case AuthErrorCodes.USER_DISABLED:
          setError('This account has been disabled. Please contact support.');
          break;
        default:
          setError('Error logging in. Please check your email and password.');
          break;
      }
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setError('');
    } catch (error) {
      setSuccess(false);
      setError('Error sending password reset email: ' + error.message);
    }
  };

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      setSuccess(true);
      setError('');
      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
    } catch (error) {
      setSuccess(false);
      setError('Error resetting password: ' + error.message);
    }
  };

  return (
    <div className=' d-flex div-center h-100vh bg-white  '>
      <div className=' d-flex div-center   col-12 '>
        
        <div className='col-6 h-100vh-login d-lg-block d-none text-center padding-login text-white header-bg-dark'>
          <div className='p-5 container'>
            <Carousel fade interval={4000} controls={false}>
              <Carousel.Item>
                <div className='div-center'>
                  <img className='w-75-img-login p-5' src='/assets/images/login1.png' alt="Secured Payments" />
                </div>
                <div>
                  <h2>Secured Payments</h2>
                  <p>
                    Use our escrow-based payment system for <br />
                    secure transactions. Designers get paid <br />
                    only after you approve the work.
                  </p>
                </div>
              </Carousel.Item>
              {/* <Carousel.Item>
                <div className='div-center'>
                  <img className='w-75-img-login p-5' src='/assets/images/Verified.png' alt="Quality Assurance" />
                </div>
                <div>
                  <h2>Verified Designers</h2>
                  <p>
                  Work with pre-screened, experienced <br/>
                   designers for high -quality results. 
                  </p>
                </div>
              </Carousel.Item> */}
              <Carousel.Item>
                <div className='div-center'>
                  <img className='w-75-img-login p-5' src='/assets/images/apm.png' alt="24/7 Support" />
                </div>
                <div>
                  <h2>Advanced Project Management</h2>
                  <p>
                  Manage multiple projects with <br/>
                  CMD'S onboard  communication <br/>
                  tools and dashboards. 
                
                  </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className='div-center'>
                  <img className='w-75-img-login p-5' src='/assets/images/ai-powered.png' alt="24/7 Support" />
                </div>
                <div>
                  <h2>AI-Powered Hiring</h2>
                  <p>
                  Let our AI match you with the <br/>
                   best clients based on your profile . 
                
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
           <style jsx>{`
        .carousel-indicators {
          bottom: -30px;
        }
      `}</style>
        </div>
        <div className="justify-content-md-center col-lg-7 pe-lg-5">
          <div className='text-center'>
          <a href="/">
          <img
              src="/assets/images/logoblue.png"

              className="w-50 py-2"
              alt="CONNECT MY DESIGNER"
            />
          </a>
            <h2 className='fw-bold gradient-text py-3'>{resetMode ? 'Reset Password' : 'Login To CMD'}</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{resetMode ? 'Password reset email sent!' : 'Login successful! Redirecting...'}</Alert>}

            {resetMode ? (
              <>
                <Form onSubmit={oobCode ? handleConfirmResetPassword : handlePasswordReset}>
                  <Form.Group controlId="formBasicEmail" className='text-center'>

                    <input
                      type="email"
                     className='p-2  my-2 w-50 rounded border '
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={!!oobCode} // Disable email input if oobCode is present
                    />
                  </Form.Group>

                  <Button className='btn border-warning px-5 bg-yellow-200 text-dark my-2' type="submit">
                    {oobCode ? 'Reset Password' : 'Send Verification Email'}
                  </Button>
                </Form>

                <div className="mt-3">
                 
                  <Button variant="link" onClick={() => setResetMode(false)}>Back</Button>
                  <Button variant="link" onClick={() => navigate('/auth/register')}>
                    Register
                  </Button>
                </div>
              </>
            ) : (
              <>
                <GoogleLoginButton />
                <div className='d-flex p-3'>
                  <div className='text-center col-12'> Or with e-mail</div> 
                </div>
                <Form onSubmit={handleEmailLogin}>
                  <Form.Group controlId="formBasicEmail">

                    <input className='p-2  my-2  w-50 rounded border ' type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
      <div className="position-relative d-inline-block w-50">
        <input
          className="p-2 my-2 w-100 rounded border"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: '#888', // Adjust the color as needed
          }}
        >
 {showPassword ? (
            <i className="fa-regular fa-eye-slash"></i>
          ) : (
            <i className="fa-regular fa-eye"></i>
          )}        </span>
      </div>
    </Form.Group>
                 <div className='div-end w-75 '>
                 <p className='  '><button className='btn ' variant="link" onClick={() => setResetMode(true)}><a className='text-decoration-none'>Forgot Password?  </a>
                 </button></p>
                 </div>
                  <button className=' ounded  login-btn  w-25 px-0 py-lg-2  Display-6 shadow btn font-14px-mob mr-lg-2 ' type="submit">  Login   </button>
                </Form>
                <p className='text-decoration-none p-3'>Don't have an account? <Link to="/auth/register">Sign up</Link></p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
