import React, { useState, useEffect } from 'react';
import { useAuth } from '../firebase'; // Assuming you have a custom hook for Firebase Auth
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editForm, setEditForm] = useState({});
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { user, loading } = useAuth(); // Custom hook for Firebase Auth (Assumed)

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('https://connectmydesigner.com/profiles');
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleDelete = async (id) => {
        try {
            await fetch(`https://connectmydesigner.com/profile/${id}`, {
                method: 'DELETE',
            });
            setUsers(users.filter(user => user._id !== id));
            setSelectedUser(null);
        } catch (error) {
            console.error('Error deleting profile:', error);
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setEditForm(user);
    };

    const handleSave = async (id) => {
        try {
            await fetch(`https://connectmydesigner.com/profile/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editForm),
            });
            setUsers(users.map(user => (user._id === id ? { ...user, ...editForm } : user)));
            setSelectedUser(null); // Close edit form after save
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditForm({
            ...editForm,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setEditForm({
                ...editForm,
                [name]: reader.result // Store base64 string of the file
            });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!user) {
        return <p>You need to log in to view the user list.</p>;
    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-3">
                    <h2>User List</h2>
                    <ul className="list-group">
                        {users.map(user => (
                            <li className="list-group-item" key={user._id} onClick={() => handleEdit(user)}>
                                {user.username}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-md-9">
                    {selectedUser && (
                        <div>
                            <h2>Edit User</h2>
                            <form className='row'>
                                {Object.keys(editForm).map((key) => (
                                    key !== '_id' && key !== 'Aadhar' && key !== 'Pan' && key !== 'Certificate' && key !== 'Workexample' && key !== 'profilePhoto' ? (
                                        <div className="mb-3 w-50" key={key}>
                                            <label className="form-label">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                            <input
                                                type={key === 'DOB' ? 'date' : (typeof editForm[key] === 'boolean' ? 'checkbox' : 'text')}
                                                className="form-control"
                                                name={key}
                                                value={typeof editForm[key] === 'boolean' ? undefined : editForm[key]}
                                                checked={typeof editForm[key] === 'boolean' ? editForm[key] : undefined}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    ) : (
                                        <div className="mb-3 w-50" key={key}>
                                            <label className="form-label">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name={key}
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                            {editForm[key] && (
                                                <img
                                                    src={editForm[key]} // Ensure editForm[key] contains base64 string
                                                    alt={`${key} Preview`}
                                                    className="img-thumbnail mt-2"
                                                    style={{ maxWidth: '100px' }}
                                                />
                                            )}
                                        </div>
                                    )
                                ))}
                                <div>
                                    <button type="button" className="mx-2 btn btn-primary" onClick={() => handleSave(selectedUser._id)}>Save</button>
                                    <button type="button" className="mx-2 btn btn-secondary" onClick={() => setSelectedUser(null)}>Cancel</button>
                                    <button type="button" className="mx-2 btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete</button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>

            {confirmDelete && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Delete</h5>
                                <button type="button" className="btn-close" onClick={() => setConfirmDelete(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this user?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setConfirmDelete(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={() => { handleDelete(selectedUser._id); setConfirmDelete(false); }}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserList;

