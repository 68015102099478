import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import SkillsComponent from "./SkillsComponent";

const StepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    documentFile: null,
    additionalInfo: '',
    selectedGender: '',
    description: '',
    address: '',
    location: '',
    dob: '',
    qualification: '',
    experience: '',
    profile: null,
    portfolio: null,
    degree: null,
    aadhaar: null,
    pan: null,
    selectedSkills: '',
    selectedStyles: '',
    otherdata: ''
  });
  const [filePreviews, setFilePreviews] = useState({
    profilePreview: null,
    portfolioPreview: null,
    degreePreview: null,
    aadhaarPreview: null,
    panPreview: null
  });

  useEffect(() => {
    // Cleanup URLs created by URL.createObjectURL to prevent memory leaks
    return () => {
      Object.values(filePreviews).forEach(url => {
        if (url) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [filePreviews]);
  const skillsList = ['3D Design', 'Walkthrough', '3D Exterior', 'Landscape', '2D Drawing'];
  const stylesList = ['simple', 'Modern 3D', 'Contemporary'];
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const saveAndNext = () => nextStep();

  const handleGenderChange = (e) => {
    setFormData({ ...formData, selectedGender: e.target.value });
  };

  const handleFileChange = (e, key, previewKey) => {
    const file = e.target.files[0];
    setFormData({ ...formData, [key]: file });
    if (file) {
      setFilePreviews({ ...filePreviews, [previewKey]: URL.createObjectURL(file) });
    }
  };
  const handleFileChangeSkill = (e, skill) => {
    setFiles({ ...files, [skill]: e.target.files[0] });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const form = new FormData();
    Object.keys(formData).forEach(key => {
      form.append(key, formData[key]);
    });

    try {
      await axios.post('https://connectmydesigner.com/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };



  const [selectedSkills, setSelectedSkills] = useState([]);
  const [files, setFiles] = useState({});
  const [links, setLinks] = useState({});
  const [selectedStyles, setSelectedStyles] = useState([]);

  useEffect(() => {
    if (selectedSkills.length || selectedStyles.length) {
      handleSaveData();
    }
  }, [selectedSkills, selectedStyles, files, links]);

  const handleCheckboxChange = (skill) => {
    setSelectedSkills((prev) =>
      prev.includes(skill) ? prev.filter((s) => s !== skill) : [...prev, skill]
    );
  };



  const handleLinkChange = (e, skill) => {
    setLinks({ ...links, [skill]: e.target.value });
  };

  const handleStyleChange = (style) => {
    setSelectedStyles((prev) =>
      prev.includes(style) ? prev.filter((s) => s !== style) : [...prev, style]
    );
  };

  const isLinkValid = (link) => {
    return link && link.includes('.');
  };

  const handleSaveData = async () => {
    const formData = new FormData();
    formData.append('selectedSkills', JSON.stringify(selectedSkills));
    formData.append('selectedStyles', JSON.stringify(selectedStyles));
    Object.keys(files).forEach(skill => {
      formData.append(skill, files[skill]);
    });
    formData.append('links', JSON.stringify(links));

    try {
      await axios.post('https://connectmydesigner.com/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };



  const renderStep = () => {
    return (
      <div className='d-flex div-'>
        <div className='col-9 px-2 '>
          <div >
            <div className='  pt-2 px-2'>
              <h2 className='fw-bold'>Welcome to CMD</h2>
             
            </div>
            <div className=' col-10 px-2   text-secondary'>
              <p>Your application should only take a few minutes. Based on the information you provide, our screening team will determine the best path for you going forward.</p>
            </div>
          </div>
          <div className=' d-md-flex' style={{ borderBottom: "2px solid gray" }}>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Personal Details</span>
            </div>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Documentation</span>
            </div>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Other Details</span>
            </div>
          </div>
          {step === 1 && (
            <div className=''>
              <div className='w-100 py-2 d-flex'>
                <div className='col-lg-3 text-center'>
                  <span>Profile Photo *</span>
                  <div
                    className='border-dashed rounded  mx-lg-5'
                    onClick={() => document.getElementById('profileInput').click()}
                    style={{ width: '174px', height: '154px', cursor: 'pointer', padding: '1px' }}
                  >
                    <input
                      type="file"
                      id="profileInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'profile', 'profilePreview')}
                    />
                    {filePreviews.profilePreview ? (
                      <img className='rounded' src={filePreviews.profilePreview} alt="Profile Preview" style={{ width: '170px', height: '150px' }} />
                    ) : (
                      <>
                        <span className="material-symbols-outlined">
                          upload
                        </span>
                        <p>Upload Here</p>
                        <p>Max size: 5GB</p>
                      </>
                    )}
                  </div>
                </div>

                <div className='col-lg-9 col-8 my-auto  '>
                  <label className='pt-2' htmlFor="Name">Full Name *</label>
                  <input type="text" className="form-control w-50 rounded-1" id="Name" name="name" placeholder="Enter your full name" value={formData.name} onChange={handleInputChange} />
                  <div className="">
                    <div className="py-2">
                      <label className="form-label">Gender *</label>
                      <div className="radio-group">
                        <label className={`btn border text-dark py-1 my-0 btn-outline-warning ${formData.selectedGender === 'male' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="male" checked={formData.selectedGender === 'male'} onChange={handleGenderChange} />
                          Male
                        </label>
                        <label className={`btn border text-dark py-1 my-0 mx-1 btn-outline-warning ${formData.selectedGender === 'female' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="female" checked={formData.selectedGender === 'female'} onChange={handleGenderChange} />
                          Female
                        </label>
                        <label className={`btn border text-dark py-1 my-0 btn-outline-warning ${formData.selectedGender === 'other' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="other" checked={formData.selectedGender === 'other'} onChange={handleGenderChange} />
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='col-lg-8 col-12 px-5'>
                <div className=''>
                  <label className='pt-2' htmlFor="description">Description</label>
                  <textarea className="form-control rounded-1" id="description" name="description" placeholder="Enter a description" value={formData.description} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="address">Address</label>
                  <textarea type="text" className="form-control rounded-1" id="address" name="address" placeholder="Enter your current address" value={formData.address} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="location">Location(city)*</label>
                  <input type="text" className="form-control rounded-1" id="location" name="location" placeholder="Enter your location" value={formData.location} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="dob">Date of Birth</label>
                  <input type="date" placeholder=" 08/08/2001" className="form-control rounded-1" id="dob" name="dob" value={formData.dob} onChange={handleInputChange} />
                </div>
                <div className=''>
                  <label className='pt-2' htmlFor="qualification">Qualification</label>
                  <input type="text" className="form-control rounded-1" id="qualification" name="qualification" placeholder="Enter your qualification" value={formData.qualification} onChange={handleInputChange} />
                </div>
                <div className=''>
                  <label className='pt-2' htmlFor="experience">Experience</label>
                  <input type="text" className="form-control rounded-1" id="experience" name="experience" placeholder="Enter your experience" value={formData.experience} onChange={handleInputChange} />
                </div>

                <div className=' pt-2'>
                  <span className=''>Portfolio Photo *</span>
                  <div
                    className='border-dashed text-center rounded '
                    onClick={() => document.getElementById('portfolioInput').click()}
                    style={{ width: '354px', height: '200px', cursor: 'pointer', padding: '  1px' }}
                  >
                    <input
                      type="file"
                      id="portfolioInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'portfolio', 'portfolioPreview')}
                    />
                    {filePreviews.portfolioPreview ? (
                      <img src={filePreviews.portfolioPreview} alt="Portfolio Preview" style={{ width: '350px', height: '196px' }} className='rounded' />
                    ) : (
                      <>
                        <span className="material-symbols-outlined">
                          upload
                        </span>
                        <p>Upload Here</p>
                        <p>Max size: 5GB</p>
                      </>
                    )}
                  </div>
                </div>


              </div>
            </div>
          )}
          {step === 2 && (
            <div className='col-10'>

              <div className=' pt-2'>
                <span className=''>Degree Certificate *</span>
                <div
                  className='border-dashed text-center rounded '
                  onClick={() => document.getElementById('DegreeCertificate').click()}
                  style={{ width: '354px', height: '200px', cursor: 'pointer', padding: '  1px' }}
                >
                  <input
                    type="file"
                    id="DegreeCertificate"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'degree', 'degreePreview')}
                  />
                  {filePreviews.degreePreview ? (
                    <img src={filePreviews.degreePreview} alt="degree Preview" style={{ maxWidth: '350px', maxHeight: '196px' }} className='rounded' />
                  ) : (
                    <>
                      <span className="material-symbols-outlined">
                        upload
                      </span>
                      <p>Upload Here</p>
                      <p>Max size: 5GB</p>
                    </>
                  )}
                </div>
              </div>
              <div className=' pt-2'>
                <span className=''>Aadhaar Card*</span>
                <div
                  className='border-dashed text-center rounded '
                  onClick={() => document.getElementById('AadhaarCard').click()}
                  style={{ width: '354px', height: '200px', cursor: 'pointer', padding: '  1px' }}
                >
                  <input
                    type="file"
                    id="AadhaarCard"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'aadhaar', 'aadhaarPreview')}
                  />
                  {filePreviews.aadhaarPreview ? (
                    <img src={filePreviews.aadhaarPreview} alt="aadhaar Preview" style={{ maxWidth: '350px', maxHeight: '196px' }} className='rounded' />
                  ) : (
                    <>
                      <span className="material-symbols-outlined">
                        upload
                      </span>
                      <p>Upload Here</p>
                      <p>Max size: 5GB</p>
                    </>
                  )}
                </div>
              </div>
              <div className=' pt-2'>
                <span className=''>PAN Card *</span>
                <div
                  className='border-dashed text-center rounded '
                  onClick={() => document.getElementById('PANCard').click()}
                  style={{ width: '354px', height: '200px', cursor: 'pointer', padding: '  1px' }}
                >
                  <input
                    type="file"
                    id="PANCard"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'pan', 'panPreview')}
                  />
                  {filePreviews.panPreview ? (
                    <img src={filePreviews.panPreview} alt="pan Preview" style={{ maxWidth: '350px', maxHeight: '196px' }} className='rounded' />
                  ) : (
                    <>
                      <span className="material-symbols-outlined">
                        upload
                      </span>
                      <p>Upload Here</p>
                      <p>Max size: 5GB</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className='col-10'>
              <form>
                <div className="form-group">
                  <label for="projectExperience">Have you worked on online/digital projects? <span className="text-danger">*</span></label>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="projectExperience" id="yesExperience" value="yes" />
                    <label className="form-check-label" for="yesExperience">
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="projectExperience" id="noExperience" value="no" />
                    <label className="form-check-label" for="noExperience">
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label for="jobCommitment">Which type of job commitment do you prefer? <span className="text-danger">*</span></label>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobCommitment" id="fullTime" value="full-time" />
                    <label className="form-check-label" for="fullTime">
                      Full Time (40 hours/week)
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobCommitment" id="partTime" value="part-time" />
                    <label className="form-check-label" for="partTime">
                      Part Time (20 hours/week)
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="jobCommitment" id="hourly" value="hourly" />
                    <label className="form-check-label" for="hourly">
                      Hourly (10 hours/week)
                    </label>
                  </div>
                </div>




                <div>
                {/* <SkillsComponent /> */}

                  <div>
                    <label className='pt-2' htmlFor="otherdata">otherdata</label>
                    <textarea type="text" className="form-control rounded-1" id="otherdata" name="otherdata" placeholder="Enter your current otherdata" value={formData.otherdata} onChange={handleInputChange} />
                  </div>
                </div>




              </form>
            </div>
          )}
        </div>

        <div className=''>
          <div className='position-fixed h-100  end-0 col-lg-3  px-2  d-grid gradient-bg'>
            <div className='text-center py-2 px-3 text-white my-auto'>
              <div className='svg'>

                <img src="/assets/icons/Group1.png"></img>

              </div>
              <div className='fw-bold'>
                <p>
                  Jobs Come to You
                </p>
              </div>
              <div className=''>
                <p>
                  Once you’re in the network, jobs based on your skills and interests are ready and waiting for you.
                </p>
              </div>
            </div>
            <div className='text-center py-4 px-4 text-white my-auto'>
              <div className=''>
                <img src="/assets/icons/Group2.png"></img>
              </div>
              <div className='fw-bold'>
                <p>
                  Jobs Come to You
                </p>
              </div>
              <div className=''>
                <p>
                  Gain access to a worldwide community of experts, who serve as both peers and mentors.              </p>
              </div>
            </div>
            <div className='text-center py-2 px-4 text-white my-auto'>
              <div className='svg'>
                <img src="/assets/icons/Group3.png"></img>
              </div>
              <div className='fw-bold'>
                <p>
                  Jobs Come to You
                </p>
              </div>
              <div className=''>
                <p>
                  Toptal has built a network of experts trusted by the world’s best clients. No need to build your reputation from scratch to succeed.              </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <Form>
        {renderStep()}
        <Row>
          <Col>
            {step > 1 && (
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
            )}
          </Col>
          <Col>
            {step < 3 && (
              <Button variant="primary" onClick={saveAndNext}>
                Next
              </Button>
            )}
            {step === 3 && (
              <Button variant="success" onClick={handleSubmit}>
                Submit
              </Button>
              
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepForm;
