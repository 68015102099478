import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, ListGroup, Row, Col, Image, Button } from 'react-bootstrap';

const AllUsers = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://connectmydesigner.com/users');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleUserClick = async (userId) => {
        try {
            const response = await axios.get(`https://connectmydesigner.com/users/${userId}`);
            const userData = response.data;

            const convertBufferToUrl = (buffer, type) => {
                return buffer ? URL.createObjectURL(new Blob([new Uint8Array(buffer.data)], { type })) : null;
            };

            setSelectedUser({
                ...userData,
                profileUrl: convertBufferToUrl(userData.profile, 'image/jpeg'),
                portfolioUrl: convertBufferToUrl(userData.portfolio, 'image/jpeg'),
                degreeUrl: convertBufferToUrl(userData.degree, 'image/jpeg'),
                aadhaarUrl: convertBufferToUrl(userData.aadhaar, 'image/jpeg'),
                panUrl: convertBufferToUrl(userData.pan, 'image/jpeg'),
                documentFileUrl: convertBufferToUrl(userData.documentFile, 'application/pdf'),
                selectedSkills: userData.selectedSkills || [],
                selectedStyles: userData.selectedStyles || [],
            });
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleDeleteUser = async (userId) => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return; // User canceled the delete operation
        }

        try {
            console.log(`Attempting to delete user with ID: ${userId}`);
            await axios.delete(`https://connectmydesigner.com/users/${userId}`);
            setUsers(users.filter(user => user._id !== userId));
            if (selectedUser && selectedUser._id === userId) {
                setSelectedUser(null);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Failed to delete user. Please try again.');
        }
    };

    return (
        <Container>
            <h1>All Users</h1>
            <ListGroup>
                {users.map(user => (
                    <ListGroup.Item key={user._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span onClick={() => handleUserClick(user._id)} style={{ cursor: 'pointer' }}>{user.name}</span>
                        <Button variant="danger" onClick={() => handleDeleteUser(user._id)}>Delete</Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            {selectedUser && (
                <div style={{ marginTop: '20px' }}>
                    <h2>User Details</h2>
                    <Row>
                        <Col md={4}>
                            {selectedUser.profileUrl && (
                                <Image src={selectedUser.profileUrl} alt="Profile" thumbnail />
                            )}
                        </Col>
                        <Col md={8}>
                            <p><strong>Name:</strong> {selectedUser.name}</p>
                            <p><strong>Additional Info:</strong> {selectedUser.additionalInfo}</p>
                            <p><strong>Gender:</strong> {selectedUser.selectedGender}</p>
                            <p><strong>Description:</strong> {selectedUser.description}</p>
                            <p><strong>Address:</strong> {selectedUser.address}</p>
                            <p><strong>other:</strong> {selectedUser.otherdata}</p>

                            <p><strong>Location:</strong> {selectedUser.location}</p>
                            <p><strong>Date of Birth:</strong> {selectedUser.dob}</p>
                            <p><strong>Qualification:</strong> {selectedUser.qualification}</p>
                            <p><strong>Experience:</strong> {selectedUser.experience}</p>
                            <p><strong>Skills:</strong> {selectedUser.selectedSkills.join(', ')}</p>
                            <p><strong>Styles:</strong> {selectedUser.selectedStyles.join(', ')}</p>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                        {selectedUser.documentFileUrl && (
                            <Col>
                                <strong>Document File:</strong>
                                <embed src={selectedUser.documentFileUrl} type="application/pdf" width="100%" height="400px" />
                            </Col>
                        )}
                        {selectedUser.portfolioUrl && (
                            <Col>
                                <strong>Portfolio:</strong>
                                <Image src={selectedUser.portfolioUrl} alt="Portfolio" thumbnail />
                            </Col>
                        )}
                        {selectedUser.degreeUrl && (
                            <Col>
                                <strong>Degree Certificate:</strong>
                                <Image src={selectedUser.degreeUrl} alt="Degree" thumbnail />
                            </Col>
                        )}
                        {selectedUser.aadhaarUrl && (
                            <Col>
                                <strong>Aadhaar Card:</strong>
                                <Image src={selectedUser.aadhaarUrl} alt="Aadhaar" thumbnail />
                            </Col>
                        )}
                        {selectedUser.panUrl && (
                            <Col>
                                <strong>PAN Card:</strong>
                                <Image src={selectedUser.panUrl} alt="PAN" thumbnail />
                            </Col>
                        )}
                    </Row>
                    {selectedUser.otherdata && (
                        <Row>
                            <Col>
                                <p><strong>Other:</strong> {selectedUser.otherdata}</p>
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </Container>
    );
};

export default AllUsers;
