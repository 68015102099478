
import React from 'react';
const doList = [
    {
        title: "1. Provide Accurate Information:",
        points: ["Ensure that all information on your profile is accurate and up-to-date, including your skills, experience, and portfolio."]
    }, {
        title: "2. Use CMD's Communication Tools:",
        points: ["Use CMD's communication tools to interact professionally with clients.",
            "Respond to client messages promptly and courteously."]
    }, {
        title: "3. Deliver Quality Work:",
        points: ["Ensure all work delivered meets the standards promised in your profile and project agreement.",
            "Pay attention to detail and client specifications."]
    }, {
        title: "4. Meet Deadlines:",
        points: ["Deliver projects on or before the agreed-upon deadlines.",
            "Communicate any potential delays to the client as soon as possible."]
    }, {
        title: "5. Update Your Portfolio Regularly:",
        points: ["Regularly update your portfolio with new projects.",
            "Add new skills and certifications as you acquire them."]
    }, {
        title: "6. Maintain Quality Visuals:",
        points: ["Make sure all images and videos uploaded to your portfolio are of high quality.",
"They should also accurately represent your work."
            
        ]
    }, {
        title: "7. Adhere to Guidelines:",
        points: ["Adhere to CMD’s guidelines and terms of service.",
            "Report any suspicious activity or violations of CMD policies."]
    }, {
        title: "8. Accept Constructive Feedback:",
        points: ["Be open to constructive feedback and use it to improve your work.",
            "Aim to exceed client expectations whenever possible."]
    }, {
        title: "9. Be Transparent with Costs:",
        points: ["Clearly outline your rates and any additional costs upfront.",
            "Ensure there are no hidden fees or unexpected charges."]
    },
    {
        title: "10. Stay Active on the Platform:",
        points: ["Regularly log in to your account, check for new opportunities, and engage with potential clients to maintain a strong presence."
        ]
    },
    {
        title: "11. Be Professional in Communication:",
        points: ["Keep all communication respectful, clear, and professional, whether with clients or other designers on the platform."]
    }
];


const Dos = () => {
    return (
        <div className='d-flex col-12'>
            <div className=' w-100 border-top pt-1'>
                <div className='  ps-3 pt-2 d-flex ' style={{ borderRadius: "10px 10px 10px var(--Cart-Till, 0px)" }}>
                    <svg className='my-auto' xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                        <path d="M18.0496 7.98306C17.9145 8.14599 17.7802 8.30969 17.6476 8.47253V16.335C17.6476 17.0085 17.0794 17.5566 16.3811 17.5566H3.79975C3.10056 17.5566 2.53316 17.0085 2.53316 16.335V4.20006C2.53316 3.52569 3.10056 2.97843 3.79975 2.97843H15.6777C16.1522 2.43117 16.5922 1.92942 17.0549 1.42854L17.4501 0.994472L17.678 0.755023C17.2736 0.612539 16.8362 0.535156 16.3811 0.535156H3.79975C1.70397 0.535156 0 2.17866 0 4.20006V16.335C0 18.3555 1.70397 19.9999 3.79975 19.9999H16.3811C18.476 19.9999 20.1809 18.3556 20.1809 16.335V5.50071C19.5213 6.23778 18.829 7.04485 18.0496 7.98306Z" fill="#248B04" />
                        <path d="M21.8083 0.62118C21.428 0.0190672 20.5998 -0.179551 19.9583 0.17744C19.5992 0.377275 19.2657 0.605447 18.9412 0.843441C18.7772 0.961395 18.6264 1.08926 18.4734 1.21643C18.3215 1.34446 18.1726 1.47528 18.025 1.60758C17.7267 1.87026 17.4591 2.15615 17.1831 2.43604L16.7715 2.85761L16.3704 3.28579C15.8313 3.854 15.3125 4.43491 14.7949 5.01642L14.0297 5.89564L13.2846 6.78764C12.3006 7.98283 11.3425 9.19287 10.4256 10.4288C9.96493 11.0453 9.51368 11.6675 9.06605 12.2921C8.90159 12.5256 8.7387 12.7601 8.57573 12.9945C8.22486 12.7203 7.90872 12.4161 7.62388 12.0766C7.04429 11.3944 6.5924 10.5598 6.21616 9.65556L6.20764 9.63505C5.95632 9.03494 5.24866 8.71211 4.58406 8.90977C3.89039 9.11604 3.50619 9.81107 3.72593 10.4622C3.90937 11.0059 4.12171 11.552 4.38794 12.0908C4.65463 12.6286 4.97271 13.1612 5.35552 13.665C5.73871 14.1679 6.18578 14.6396 6.67638 15.0572C7.16606 15.4765 7.69787 15.8389 8.23995 16.1515C8.90113 16.5313 9.77361 16.3622 10.2144 15.7541L10.241 15.7175C10.6793 15.1127 11.129 14.5128 11.5805 13.9147C12.0327 13.3167 12.4882 12.721 12.9526 12.1316C13.8826 10.9538 14.823 9.78473 15.7958 8.64387C16.7692 7.50319 17.7602 6.38076 18.7898 5.29814L19.1748 4.89108L19.5676 4.49306C19.8304 4.22881 20.0897 3.96014 20.3484 3.69459C20.6057 3.42747 20.8493 3.14559 21.0904 2.86361C21.3319 2.58224 21.5642 2.29105 21.7711 1.97152L21.8019 1.92441C22.0513 1.53743 22.0713 1.03737 21.8083 0.62118Z" fill="#248B04" />
                    </svg> <h2 className='ps-2 my-auto    gradient-text '>Do's of connect my designer</h2>
                </div>

                {doList.map((doItem, index) => (
                    <div className=' rounded-3 my-2' key={index}>
                        <div className=' rounded-top-3 px-3 py-1 dosdontstext '>
                            <span>{doItem.title}</span>
                        </div>
                        <div className='text-gray-400 rounded-bottom-3 px-2 py-1 '>
                            {doItem.points.map((point, idx) => (
                                <div className='d-flex' key={idx}>
                                    <i className="fa-solid fa-circle p-2 font-10px"></i>
                                    <span>{point}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default Dos;