import React, { useState, useEffect } from 'react';
import { useAuth } from '../firebase';
import Pricing3DComponent from './residential3d';
import Commercial3DComponent from './Commercial3d';
import Exterior3d from './Exterior3D';

const Design3dForm = () => {
    const [formData, setFormData] = useState({
        Residential3d: false,
        Commercial3d: false,
        Exterior3d: false,
        status: 'UnderVerification', // Default selected radio button
        email: '' // To store user's email
    });
    const [step, setStep] = useState(1); // To manage steps of the form
    const [activeTab, setActiveTab] = useState(''); // To manage active tab
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            setFormData(prevData => ({ ...prevData, email: user.email }));
            checkFormSubmission(user.email);
        }
    }, [user]);

    useEffect(() => {
        if (formData.Residential3d) {
            setActiveTab('Residential');
        } else if (formData.Commercial3d) {
            setActiveTab('Commercial');
        } else if (formData.Exterior3d) {
            setActiveTab('Exterior');
        }
    }, [formData.Residential3d, formData.Commercial3d, formData.Exterior3d]);

    const checkFormSubmission = async (email) => {
        try {
            const response = await fetch(`https://connectmydesigner.com/api/forms/${email}`);
            if (response.ok) {
                const form = await response.json();
                setFormData(form);
                setStep(2);
            }
        } catch (error) {
            console.error('Error checking form submission:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [type === 'checkbox' ? value : name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://connectmydesigner.com/api/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            console.log(`Submit status: ${result.message}`);
            setStep(2); // Move to question2 after successful submission
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (
        <div className='container card border p-5 my-1'>
            {step === 1 && (
                <form onSubmit={handleSubmit}>
                    <div className='question1'>
                        <label>1. Please select the 3D design category you will provide.</label>
                        <div>
                            <input type='checkbox' value='Residential3d' checked={formData.Residential3d} onChange={handleChange} /> Residential
                        </div>
                        <div>
                            <input type='checkbox' value='Commercial3d' checked={formData.Commercial3d} onChange={handleChange} /> Commercial
                        </div>
                        <div>
                            <input type='checkbox' value='Exterior3d' checked={formData.Exterior3d} onChange={handleChange} /> Exterior
                        </div>
                        <div className='d-none'>
                            <input type="radio" name="status" value='UnderVerification' checked={formData.status === 'UnderVerification'} onChange={handleChange} /> Under Verification
                            <input type="radio" name="status" value='Verified' checked={formData.status === 'Verified'} onChange={handleChange} /> Verified
                            <input type="radio" name="status" value='Rejected' checked={formData.status === 'Rejected'} onChange={handleChange} /> Rejected
                            <input type="radio" name="status" value='UnderReview' checked={formData.status === 'UnderReview'} onChange={handleChange} /> Under Review
                            <input type="radio" name="status" value='Other' checked={formData.status === 'Other'} onChange={handleChange} /> Other
                        </div>
                        <div>
                            <button type='button'>Back</button>
                            <button type='submit'>Submit and Next</button>
                        </div>
                    </div>
                </form>
            )}
            {step === 2 && (
                <div className='question2'>
                    <label>2. This is the second question</label>
                    <div className="tabs">
                        {formData.Residential3d && (
                            <button 
                                type='button' 
                                className={`tab ${activeTab === 'Residential' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Residential')}
                            >
                                Residential
                            </button>
                        )}
                        {formData.Commercial3d && (
                            <button 
                                type='button' 
                                className={`tab ${activeTab === 'Commercial' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Commercial')}
                            >
                                Commercial
                            </button>
                        )}
                        {formData.Exterior3d && (
                            <button 
                                type='button' 
                                className={`tab ${activeTab === 'Exterior' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Exterior')}
                            >
                                Exterior
                            </button>
                        )}
                    </div>
                    <div className="tab-content">
                        {activeTab === 'Residential' && <p><Pricing3DComponent/></p>}
                        {activeTab === 'Commercial' && <p><Commercial3DComponent/></p>}
                        {activeTab === 'Exterior' && <p><Exterior3d/></p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Design3dForm;
