import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { useAuth } from '../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

const ThreeDPortfolioForm = () => {
  const { user, loading: authLoading } = useAuth();
  const [formData, setFormData] = useState({
    projectName: '',
    aboutProject: '',
    projectTags: '',
    toolsUsed: '',
    cost: '',
    pinProject: false,
    draft: false,
    privet: false,
    files: [],
    pinnedImages: []
  });
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, files: [...formData.files, ...e.target.files] });
  };

  const handleAddTag = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setTags([...tags, e.target.value]);
      e.target.value = '';
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handlePinImage = (index) => {
    setFormData({
      ...formData,
      pinnedImages: formData.pinnedImages.includes(index)
        ? formData.pinnedImages.filter((i) => i !== index)
        : [...formData.pinnedImages, index]
    });
  };

  const handleSubmit = async (draftMode) => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const data = new FormData();
      data.append('projectName', formData.projectName);
      data.append('aboutProject', formData.aboutProject);
      data.append('projectTags', tags.join(','));
      data.append('toolsUsed', formData.toolsUsed);
      data.append('cost', formData.cost);
      data.append('pinProject', formData.pinProject);
      data.append('pinImage', formData.pinnedImages.length > 0);
      data.append('draft', draftMode);
      data.append('privet', formData.privet);
      data.append('userEmail', user.email);

      formData.files.forEach((file, index) => {
        data.append('files', file);
      });

      const response = await axios.post('http://localhost:5151/portfolio', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setSuccess('Portfolio submitted successfully!');
        setFormData({
          projectName: '',
          aboutProject: '',
          projectTags: '',
          toolsUsed: '',
          cost: '',
          pinProject: false,
          draft: false,
          privet: false,
          files: [],
          pinnedImages: []
        });
        setTags([]);
      }
    } catch (err) {
      setError('Failed to submit portfolio. Please try again.');
    }

    setLoading(false);
  };

  return (
    <div className="container">
      <h2>Submit 3D Portfolio</h2>
      {authLoading ? (
        <Spinner animation="border" />
      ) : (
        <Form>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}

          <Form.Group controlId="formProjectName">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formAboutProject">
            <Form.Label>About Project</Form.Label>
            <Form.Control
              as="textarea"
              name="aboutProject"
              value={formData.aboutProject}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formTags">
            <Form.Label>Tags</Form.Label>
            <Form.Control type="text" placeholder="Press enter to add tag" onKeyUp={handleAddTag} />
            <div className="tag-container">
              {tags.map((tag, index) => (
                <div key={index} className="tag">
                  {tag} <Button onClick={() => handleRemoveTag(index)}>x</Button>
                </div>
              ))}
            </div>
          </Form.Group>

          <Form.Group controlId="formToolsUsed">
            <Form.Label>Tools Used</Form.Label>
            <Form.Control
              type="text"
              name="toolsUsed"
              value={formData.toolsUsed}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formCost">
            <Form.Label>Cost</Form.Label>
            <Form.Control type="number" name="cost" value={formData.cost} onChange={handleChange} />
          </Form.Group>

          <Form.Group controlId="formPinProject">
            <Form.Check
              type="checkbox"
              label="Pin this project"
              name="pinProject"
              checked={formData.pinProject}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formFiles">
            <Form.Label>Images</Form.Label>
            <Form.Control type="file" multiple onChange={handleFileChange} />
            <div className="image-list">
              {formData.files.length > 0 &&
                formData.files.map((file, index) => (
                  <div key={index} className="image-item">
                    <img src={URL.createObjectURL(file)} alt="uploaded" width="100" />
                    <Button onClick={() => handlePinImage(index)}>
                      {formData.pinnedImages.includes(index) ? 'Unpin' : 'Pin'}
                    </Button>
                  </div>
                ))}
            </div>
          </Form.Group>

          <Row>
            <Col>
              <Button variant="primary" onClick={() => handleSubmit(false)} disabled={loading}>
                {loading ? 'Submitting...' : 'Publish'}
              </Button>
            </Col>
            <Col>
              <Button variant="secondary" onClick={() => handleSubmit(true)} disabled={loading}>
                {loading ? 'Saving...' : 'Add to Draft'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default ThreeDPortfolioForm;
