import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SelectedDesignerComponent from './SelectedDesignerComponent';
import PrintButton from './PrintButton';

const DesignerList = ({ goBack }) => {
    const [designers, setDesigners] = useState([]);
    const [selectedDesigner, setSelectedDesigner] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [sortOption, setSortOption] = useState('newFirst');
    const designersPerPage = 15;

    useEffect(() => {
        const fetchDesigners = async () => {
            try {
                const response = await axios.get('https://connectmydesigner.com/api/users');
                setDesigners(response.data);
            } catch (error) {
                console.error('Error fetching designers:', error);
            }
        };

        fetchDesigners();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setCurrentPage(1); // Reset to first page on filter change
    };

    const handleSortChange = (option) => {
        setSortOption(option);
        setCurrentPage(1); // Reset to first page on sort change
    };

    const getRelevanceScore = (designer, term) => {
        const termLower = term.toLowerCase();
        const fullText = `${designer.name} ${designer.joiningdate} ${designer.mobileNo} ${designer.email} ${designer.skills.join(' ')} ${designer.verification}`.toLowerCase();

        let score = 0;
        if (fullText.includes(termLower)) score += 1;

        return score;
    };

    const sortDesigners = (designers, sortOption) => {
        switch (sortOption) {
            case 'oldFirst':
                return designers.sort((a, b) => new Date(a.joiningdate) - new Date(b.joiningdate));
            case 'newFirst':
                return designers.sort((a, b) => new Date(b.joiningdate) - new Date(a.joiningdate));
            case 'nameAZ':
                return designers.sort((a, b) => a.name.localeCompare(b.name));
            default:
                return designers;
        }
    };

    const filterAndSortDesigners = (designers, searchTerm, selectedFilter, sortOption) => {
        let filtered = designers;

        if (searchTerm) {
            const terms = searchTerm.split(' ');
            filtered = filtered
                .map(designer => ({
                    ...designer,
                    relevance: terms.reduce((score, term) => score + getRelevanceScore(designer, term), 0)
                }))
                .filter(designer => designer.relevance > 0)
                .sort((a, b) => b.relevance - a.relevance);
        }

        if (selectedFilter !== 'all') {
            filtered = filtered.filter(designer => designer.verification === selectedFilter);
        }

        return sortDesigners(filtered, sortOption);
    };

    const paginate = (designers, currentPage, designersPerPage) => {
        const startIndex = (currentPage - 1) * designersPerPage;
        return designers.slice(startIndex, startIndex + designersPerPage);
    };

    const filteredDesigners = filterAndSortDesigners(designers, searchTerm, selectedFilter, sortOption);
    const paginatedDesigners = paginate(filteredDesigners, currentPage, designersPerPage);
    const totalPages = Math.ceil(filteredDesigners.length / designersPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const counts = {
        all: designers.length,
        approved: designers.filter(designer => designer.verification === 'Approved').length,
        pending: designers.filter(designer => designer.verification === 'Pending').length,
        onHold: designers.filter(designer => designer.verification === 'On hold').length,
        rejected: designers.filter(designer => designer.verification === 'Rejected').length
    };

    return (
        <div className='table-responsive'>
            {!selectedDesigner ? (
                <>
                    <button className='btn btn-secondary ' onClick={goBack}>Back</button>
                    <div className='d-flex my-1 '>
                        <div className='d-flex w-50 border rounded '>
                            <div className='my-auto px-2'>
                                <i className="fa-solid fa-magnifying-glass fw-bold "></i>
                            </div>
                            <input
                                type='text'
                                className='form-control border-0'
                                placeholder=' Search designers...'
                                value={searchTerm}
                                onChange={handleSearch}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                style={isFocused ? { boxShadow: 'none', border: 'none', outline: 'none' } : {}}
                            />
                        </div>
                        <div className='mx-2 py-0'>
                            <select
                                className='form-select py-2'
                                value={sortOption}
                                onChange={(e) => handleSortChange(e.target.value)}
                            >
                                <option value='newFirst'>New First</option>
                                <option value='oldFirst'>Old First</option>
                                <option value='nameAZ'>Name A-Z</option>
                            </select>
                        </div>
                        <div className='ms-auto'>
                            <PrintButton designers={filteredDesigners} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-around border-bottom-main border-bottom gap-0 my-3'>
                        <button className={` ${selectedFilter === 'all' ? 'border-bottom-filter-activ px-3 py-2' : ' px-3 py-2 border-bottom-filter bg-white'}`} onClick={() => handleFilterChange('all')}>
                            Total Designer <span className="badge  bg-yellow-200 border-warning border">{counts.all}</span>
                        </button>
                        <button className={` ${selectedFilter === 'Approved' ? 'border-bottom-filter-activ px-3 py-2' : ' px-3 py-2 border-bottom-filter bg-white'}`} onClick={() => handleFilterChange('Approved')}>
                            Approved Designer <span className="badge bg-yellow-200 border-warning border">{counts.approved}</span>
                        </button>
                        <button className={` ${selectedFilter === 'Pending' ? 'border-bottom-filter-activ px-3 py-2' : ' px-3 py-2 border-bottom-filter bg-white'}`} onClick={() => handleFilterChange('Pending')}>
                            Pending Designer <span className="badge bg-yellow-200 border-warning border">{counts.pending}</span>
                        </button>
                        <button className={` ${selectedFilter === 'On hold' ? 'border-bottom-filter-activ px-3 py-2' : ' px-3 py-2 border-bottom-filter bg-white'}`} onClick={() => handleFilterChange('On hold')}>
                            On hold Designer <span className="badge bg-yellow-200 border-warning border">{counts.onHold}</span>
                        </button>
                        <button className={` ${selectedFilter === 'Rejected' ? 'border-bottom-filter-activ px-3 py-2' : ' px-3 py-2 border-bottom-filter bg-white'}`} onClick={() => handleFilterChange('Rejected')}>
                            Rejected designer <span className="badge bg-yellow-200 border-warning border">{counts.rejected}</span>
                        </button>
                    </div>
                    <table className='table  font-13px'>
                        <thead className=''>
                            <tr >
                                <th className='border text-center px-1'>Sr.No</th>
                                <th className='border text-center px-1'>User Name</th>
                                <th className='border text-center px-1 font-12px' >Joining date</th>
                                <th className='border text-center px-1'>Number</th>
                                <th className='border text-center px-1'>Email ID</th>
                                <th className='border text-center px-1'>Design Category</th>
                                <th className='border text-center px-1'>Status</th>
                                <th className='border text-center  px-1'> View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedDesigners.map((designer, index) => (
                                <tr key={designer._id}>
                                    <td className='text-center pb-0 '>{(currentPage - 1) * designersPerPage + index + 1}</td>
                                    <td className='text-center pb-0 ' style={{ maxWidth:"150px" , overflow:"scroll"}}>{designer.name}</td>
                                    <td className='text-center pb-0 '>{new Date(designer.joiningdate).toLocaleDateString()}</td>
                                    <td className='text-center pb-0 '>{designer.mobileNo}</td>
                                    <td className='text-center pb-0 ' style={{ maxWidth:"150px" , overflow:"scroll"}}>{designer.email}</td>
                                    <td className='text-center pb-0 font-12px' style={{ maxWidth:"150px" , overflow:"scroll"}}>{designer.skills.join(', ')}</td>
                                    <td className='text-center pb-0 font-12px '>{designer.verification}</td>
                                    <td className='text-center pb-0 '>
                                        <button className='border-warning border-1 rounded-1 bg-yellow-200 px-0 mx-0 py-1 mb-1 mt-0 font-12px 'style={{ minWidth:"70px" }} onClick={() => setSelectedDesigner(designer)}>
                                        <i class="fa-regular fa-eye"></i> View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='pagination div-center'>
                        <button className=' border-0 bg-white' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left px-2"></i>
                        </button>
                        {[...Array(totalPages)].map((_, i) => (
                            <button
                                key={i}
                                className={`btn ${currentPage === i + 1 ? 'text-dark' : 'text-secondary'}`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button className=' border-0 bg-white' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right px-2"></i>
                        </button>
                    </div>
                </>
            ) : (
                <SelectedDesignerComponent designer={selectedDesigner} goBack={() => setSelectedDesigner(null)} />
            )}
        </div>
    );
};

export default DesignerList;
