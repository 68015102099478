import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ViewPricingData = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://connectmydesigner.com/pricing3d');
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching the data', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this entry?')) {
            try {
                await axios.delete(`https://connectmydesigner.com/pricing3d/${id}`);
                setData(data.filter(item => item._id !== id));
            } catch (error) {
                console.error('Error deleting the data', error);
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="container mt-5">
            <h2>View Pricing Data</h2>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Charge Type</th>
                        <th>Price Per Room</th>
                        <th>Price Per Sq Feet</th>
                        <th>Days For Design</th>
                        <th>Revisions</th>
                        <th>Views</th>
                        <th>Extra Revision</th>
                        <th>Extra Revision Price</th>
                        <th>Extra Views</th>
                        <th>Extra Views Price</th>
                        <th>Urgent Design</th>
                        <th>Urgent Design Price</th>
                        <th>Entrance Foyer</th>
                        <th>Addons</th>
                        <th>Inclusion Extras</th>
                        <th>Additional Inclusions</th>
                        <th>Other Addons</th>
                        <th>Exclusions</th>
                        <th>User Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item._id}>
                            <td>{item.chargeType}</td>
                            <td>{item.pricePerRoom}</td>
                            <td>{item.pricePerSqFeet}</td>
                            <td>{item.daysForDesign}</td>
                            <td>{item.revisions}</td>
                            <td>{item.views}</td>
                            <td>{item.extraRevision}</td>
                            <td>{item.extraRevisionPrice}</td>
                            <td>{item.extraViews}</td>
                            <td>{item.extraViewsPrice}</td>
                            <td>{item.urgentDesign}</td>
                            <td>{item.urgentDesignPrice}</td>
                            <td>{item.entranceFoyer}</td>
                            <td>
                                <ul>
                                    {item.addons && Object.entries(item.addons).map(([key, value]) => (
                                        <li className='badge' key={key}>{key}: ₹ {value} </li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    {item.inclusionExtras && item.inclusionExtras.map((inclusion, i) => (
                                        <li className='badge' key={i}>{inclusion}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    {item.additionalInclusions && item.additionalInclusions.map((additionalInclusion, i) => (
                                        <li className='badge' key={i}>{additionalInclusion}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    {item.otherAddons && item.otherAddons.map((addon, i) => (
                                        <li className='badge' key={i}>{addon.name}: {addon.price}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    {item.exclusions && Object.entries(item.exclusions).map(([key, value]) => (
                                        <li className='badge' key={key}>{key}: ₹ {value} </li>
                                    ))}
                                </ul>
                            </td>
                            <td>{item.userEmail}</td>
                            <td>
                                <button className="btn btn-danger" onClick={() => handleDelete(item._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ViewPricingData;
