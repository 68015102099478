import React, { useRef, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DesignCatagoryCarousel = () => {
  const videoRef = useRef(null);const carouselRef = useRef(null);

  // Auto-scroll functionality
  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (carouselRef.current) {
        const scrollWidth = carouselRef.current.scrollWidth;
        const clientWidth = carouselRef.current.clientWidth;
        if (carouselRef.current.scrollLeft + clientWidth >= scrollWidth) {
          carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
          carouselRef.current.scrollBy({ left: clientWidth, behavior: 'smooth' });
        }
      }
    }, 3000); // Adjust the time interval for auto-scrolling

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="carousel-container overflow-hidden" ref={carouselRef}>
      <div className="carousel-inner d-flex">
        <div className="col-lg-3 col-md-4 col-12">
          <div className="border px-2 py-2 bg-journey-Commercial rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card">
            <h5 className="fw-bold shadow py-1 text-white">Commercial Design</h5>
            <div>
              <img className="h-100-w-100 rounded" src="/assets/images/Commercial.jpeg" alt="Commercial Design" />
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-4 col-12">
          <div className="border px-2 py-2 bg-journey-Exterior rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card">
            <h5 className="fw-bold shadow py-1 text-white">Exterior 3D Design</h5>
            <div>
              <img className="h-100-w-100 rounded" src="/assets/images/Exterior.png" alt="Exterior Design" />
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-4 col-12">
          <div className="border px-2 py-2 bg-journey-Landscape rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card">
            <h5 className="fw-bold shadow py-1 text-white">Landscape Design</h5>
            <div>
              <img className="h-100-w-100 rounded" src="/assets/images/Landscape.png" alt="Landscape Design" />
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-4 col-12">
          <div className="border px-2 py-2 bg-journey-Interior rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card">
            <h5 className="fw-bold shadow py-1 text-white">3D Design</h5>
            <div>
              <img className="h-100-w-100 rounded" src="/assets/images/Interior.png" alt="Interior Design" />
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-4 col-12">
          <div className="border px-2 py-2 bg-journey-Walkthroughs objet-fit-cover rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card">
            <h5 className="fw-bold shadow py-1 text-white">VR Walkthroughs</h5>
            <div>
              <video className="p-0 m-0 objet-fit-cover border h-100-w-100 rounded" ref={videoRef} src="/assets/videos/vr-test1.mp4" alt="loading..." autoPlay muted loop />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignCatagoryCarousel;