import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';
import './faq.css'; // Make sure to create this CSS file

const FAQ = () => {
  return (
    <div className="faq-page">
      <HeaderUser />
      <Container className="py-5">
        <h1 className="text-center my-5">Frequently Asked Questions</h1>
        <div className='faqsection'>
          <div className='col-lg-12'>
            <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']} alwaysOpen className='p-0'>
              <Accordion.Item eventKey="0" className='faq-item'>
                <Accordion.Header className='faq-header'>1. What kind of projects can I find on CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  You can find a variety of projects ranging from residential and commercial 3D designs, VR walkthroughs, to 2D architectural drawings.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="1">
                <Accordion.Header className='faq-header'>2. How does the payment process work on CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  CMD uses an escrow-based payment system where clients deposit payments upfront, which are released to designers upon project completion and approval.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="2">
                <Accordion.Header className='faq-header'>3. Can I set my own rates on CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  Yes, designers can set their own rates based on their expertise and the complexity of the projects they undertake.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="3">
                <Accordion.Header className='faq-header'>4. How do I communicate with clients on CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  CMD provides seamless communication tools, including in-platform messaging and video calls, to facilitate smooth collaboration between clients and designers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="4">
                <Accordion.Header className='faq-header'>5. Are there any fees to join CMD as a designer?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  CMD is free for designers to join and post their profiles. We charge up to 25% on each project, which is applied when the client approves the work. For example, if a project is worth ₹10,000, ₹7,500 (75%) will be credited to your account, and the remaining ₹2,500 is CMD's fee. Designers can withdraw their 75% at any time to their bank account. Please note that international bank accounts may incur additional charges.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className='faq-item'>
                <Accordion.Header className='faq-header'>6. What happens if there is a dispute with a client?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  CMD has a dedicated dispute resolution team to handle any issues between clients and designers, ensuring fair and timely resolutions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="6">
                <Accordion.Header className='faq-header'>7. How do I start a project once accepted?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  Once accepted, browse available projects, apply to those that match your skills, and start collaborating with clients after they select you for their projects.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="7">
                <Accordion.Header className='faq-header'>8. What happens if a client requests changes after project completion?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  CMD allows for a review and revision process where clients can request changes within a specified period. Clear guidelines on this process will be provided.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="8">
                <Accordion.Header className='faq-header'>9. Can I work on multiple projects simultaneously on CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  Yes, designers can work on multiple projects simultaneously, provided they manage their time and commitments effectively.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className='faq-item' eventKey="9">
                <Accordion.Header className='faq-header'>10. Can international designers apply to CMD?</Accordion.Header>
                <Accordion.Body className='faq-body'>
                  <i className="fas fa-chevron-right me-2"></i>
                  Currently, CMD focuses on the Indian market, but we welcome applications from international designers and plan to expand our services globally.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
      <FooterDesigner />
    </div>
  );
};

export default FAQ;