import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../firebase'; // Adjust the import path as necessary
const skillsList = ['3D Design', 'Walkthrough', '3D Exterior', 'Landscape', '2D Drawing'];
const stylesList = ['simple', 'Modern 3D', 'Contemporary'];
const softwaresList = ['AutoCAD', 'SketchUp', 'Revit', '3ds Max', 'Blender', 'Rhino', 'Photoshop', 'Illustrator', 'InDesign', 'ZBrush'];

const StepForm = () => {
  const { user } = useAuth();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [files, setFiles] = useState({});
  const [links, setLinks] = useState({});
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedSoftwares, setSelectedSoftwares] = useState([]);
  const [customStyle, setCustomStyle] = useState('');
  const [customSoftware, setCustomSoftware] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    documentFile: null,
    additionalInfo: '',
    selectedGender: '',
    description: '',
    address: '',
    location: '',
    dob: '',
    qualification: '',
    experience: '',
    profile: null,
    portfolio: null,
    degree: null,
    aadhaar: null,
    pan: null,
    selectedSkills: [],
    selectedStyles: [],
    otherdata: ''
  });
  const [filePreviews, setFilePreviews] = useState({
    profilePreview: null,
    portfolioPreview: null,
    degreePreview: null,
    aadhaarPreview: null,
    panPreview: null
  });

  useEffect(() => {
    return () => {
      Object.values(filePreviews).forEach(url => {
        if (url) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [filePreviews]);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const saveAndNext = () => nextStep();

  const handleGenderChange = (e) => {
    setFormData({ ...formData, selectedGender: e.target.value });
  };

  const handleFileChange = (e, skill, key, previewKey) => {
    const file = e.target.files[0];
    setFormData({ ...formData, [key]: file });
    setFiles({ ...files, [skill]: e.target.files[0] })
    if (file) {
      setFilePreviews({ ...filePreviews, [previewKey]: URL.createObjectURL(file) });
    }
  };
  // const handleFileChange = (e, skill) => {
  //   setFiles({ ...files, [skill]: e.target.files[0] });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSkillsChange = (selectedSkills, key) => {
    setFormData({ ...formData, [key]: selectedSkills });
  };
  const handleCheckboxChange = (setter, value) => {
    setter((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };


  const handleLinkChange = (e, skill) => {
    setLinks({ ...links, [skill]: e.target.value });
  };

  const isLinkValid = (link) => {
    return link && link.includes('.');
  };

  const handleSaveData = async () => {
    if (!user) {
      setAlertMessage('User not authenticated.');
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('email', user.email); // Include user email
    formData.append('selectedSkills', JSON.stringify(selectedSkills));
    formData.append('selectedStyles', JSON.stringify(selectedStyles));
    formData.append('selectedSoftwares', JSON.stringify(selectedSoftwares));
    Object.keys(files).forEach(skill => {
      formData.append(skill, files[skill]);
    });
    formData.append('links', JSON.stringify(links));

    try {
      await axios.post('https://connectmydesigner.com/submitSkills', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlertMessage('Data stored successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);
      if (error.response && error.response.data) {
        console.error('Server response:', error.response.data);
      }
      setAlertMessage('Error storing data.');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setAlertMessage(''), 3000); // Clear alert message after 3 seconds
    }
  };

  const handleSubmit = async () => {
    const form = new FormData();
    Object.keys(formData).forEach(key => {
      form.append(key, formData[key]);
    });

    try {
      await axios.post('https://connectmydesigner.com/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };

  const handleCustomInput = (setter, value, customValue, customSetter) => {
    setter(prev => [...prev, value]);
    customSetter('');
  };

  const handleSubmitdata = async () => {
    const form = new FormData();
    Object.keys(formData).forEach(key => {
      form.append(key, formData[key]);
    });

    try {
      await axios.post('https://connectmydesigner.com/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };

  const renderSkillSelection = (selectedSkills, key) => (
    <div className=''>
      <label className='pt-2' htmlFor={key}>{key === 'selectedSkills' ? 'Skills' : 'Styles'} *</label>
      <input
        type="text"
        className="form-control rounded-1"
        id={key}
        placeholder={`Enter ${key === 'selectedSkills' ? 'skills' : 'styles'}`}
        value={selectedSkills.join(', ')}
        onChange={(e) => handleSkillsChange(e.target.value.split(',').map(skill => skill.trim()), key)}
      />
    </div>
  );

  const renderStep = () => {
    return (
      <div className='d-flex div-'>
        <div className='col-9 px-2 '>
          <div>
            <div className='pt-2 px-2'>
              <h2 className='fw-bold'>Welcome to CMD</h2>
            </div>
            <div className='col-10 px-2 text-secondary'>
              <p>Your application should only take a few minutes. Based on the information you provide, our screening team will determine the best path for you going forward.</p>
            </div>
          </div>
          <div className='d-md-flex' style={{ borderBottom: "2px solid gray" }}>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Personal Details</span>
            </div>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Documentation</span>
            </div>
            <div className='btn py-0 col-4'>
              <span className='font-20px fw-semibold'>Other Details</span>
            </div>
          </div>
          {step === 1 && (
            <div className=''>
              <div className='w-100 py-2 d-flex'>
                <div className='col-lg-3 text-center'>
                  <span>Profile Photo *</span>
                  <div
                    className='border-dashed rounded mx-lg-5'
                    onClick={() => document.getElementById('profileInput').click()}
                    style={{ width: '174px', height: '154px', cursor: 'pointer', padding: '1px' }}
                  >
                    <input
                      type="file"
                      id="profileInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'profile', 'profilePreview')}
                    />
                    {filePreviews.profilePreview ? (
                      <img className='rounded' src={filePreviews.profilePreview} alt="Profile Preview" style={{ width: '170px', height: '150px' }} />
                    ) : (
                      <>
                        <span className="material-symbols-outlined">
                          upload
                        </span>
                        <p>Upload Here</p>
                        <p>Max size: 5GB</p>
                      </>
                    )}
                  </div>
                </div>
                <div className='col-lg-9 col-8 my-auto'>
                  <label className='pt-2' htmlFor="Name">Full Name *</label>
                  <input type="text" className="form-control w-50 rounded-1" id="Name" name="name" placeholder="Enter your full name" value={formData.name} onChange={handleInputChange} />
                  <div className="">
                    <div className="py-2">
                      <label className="form-label">Gender *</label>
                      <div className="radio-group">
                        <label className={`btn border text-dark py-1 my-0 btn-outline-warning ${formData.selectedGender === 'male' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="male" checked={formData.selectedGender === 'male'} onChange={handleGenderChange} />
                          Male
                        </label>
                        <label className={`btn border text-dark py-1 my-0 mx-1 btn-outline-warning ${formData.selectedGender === 'female' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="female" checked={formData.selectedGender === 'female'} onChange={handleGenderChange} />
                          Female
                        </label>
                        <label className={`btn border text-dark py-1 my-0 btn-outline-warning ${formData.selectedGender === 'other' ? 'active' : ''}`}>
                          <input type="radio" name="gender" value="other" checked={formData.selectedGender === 'other'} onChange={handleGenderChange} />
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-8 col-12 px-5'>
                <div className=''>
                  <label className='pt-2' htmlFor="description">Description</label>
                  <textarea className="form-control rounded-1" id="description" name="description" placeholder="Enter a description" value={formData.description} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="address">Address</label>
                  <textarea type="text" className="form-control rounded-1" id="address" name="address" placeholder="Enter your current address" value={formData.address} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="location">Location(city)*</label>
                  <input type="text" className="form-control rounded-1" id="location" name="location" placeholder="Enter your location" value={formData.location} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="dob">Date of Birth</label>
                  <input type="date" placeholder="08/08/2001" className="form-control rounded-1" id="dob" name="dob" value={formData.dob} onChange={handleInputChange} />
                </div>
                <div className=''>
                  <label className='pt-2' htmlFor="qualification">Qualification</label>
                  <input type="text" className="form-control rounded-1" id="qualification" name="qualification" placeholder="Enter your qualification" value={formData.qualification} onChange={handleInputChange} />
                </div>
                <div>
                  <label className='pt-2' htmlFor="experience">Experience</label>
                  <textarea className="form-control rounded-1" id="experience" name="experience" placeholder="Enter your experience" value={formData.experience} onChange={handleInputChange} />
                </div>
                <div className='pt-3'>
                  <span className='fw-semibold'>Portfolio *</span>
                  <div
                    className='border-dashed rounded'
                    onClick={() => document.getElementById('portfolioInput').click()}
                    style={{ width: '352px', height: '202px', cursor: 'pointer', padding: '1px' }}
                  >
                    <input
                      type="file"
                      id="portfolioInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'portfolio', 'portfolioPreview')}
                    />
                    {filePreviews.portfolioPreview ? (
                      <img className='rounded' src={filePreviews.portfolioPreview} alt="Portfolio Preview" style={{ width: '350px', height: '200px' }} />
                    ) : (
                      <>
                        <span className="material-symbols-outlined">
                          upload
                        </span>
                        <p>Upload Here</p>
                        <p>Max size: 5GB</p>
                      </>
                    )}
                  </div>
                </div>
                <div className='d-flex col-12 pt-4'>
                  <button className="btn btn-dark py-2 w-25" onClick={saveAndNext}>Save & Next</button>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className=''>
              <div className='w-100 py-2 d-flex'>
                <div className='col-lg-3 text-center'>
                  <span>Degree Certificate *</span>
                  <div
                    className='border-dashed rounded mx-lg-5'
                    onClick={() => document.getElementById('degreeInput').click()}
                    style={{ width: '174px', height: '154px', cursor: 'pointer', padding: '1px' }}
                  >
                    <input
                      type="file"
                      id="degreeInput"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e, 'degree', 'degreePreview')}
                    />
                    {filePreviews.degreePreview ? (
                      <img className='rounded' src={filePreviews.degreePreview} alt="Degree Preview" style={{ width: '170px', height: '150px' }} />
                    ) : (
                      <>
                        <span className="material-symbols-outlined">
                          upload
                        </span>
                        <p>Upload Here</p>
                        <p>Max size: 5GB</p>
                      </>
                    )}
                  </div>
                </div>
                <div className='col-lg-9 col-8 my-auto'>
                  <div className="py-2">
                    <label className='pt-2' htmlFor="aadhaar">Aadhaar *</label>
                    <div
                      className='border-dashed rounded'
                      onClick={() => document.getElementById('aadhaarInput').click()}
                      style={{ width: '174px', height: '154px', cursor: 'pointer', padding: '1px' }}
                    >
                      <input
                        type="file"
                        id="aadhaarInput"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, 'aadhaar', 'aadhaarPreview')}
                      />
                      {filePreviews.aadhaarPreview ? (
                        <img className='rounded' src={filePreviews.aadhaarPreview} alt="Aadhaar Preview" style={{ width: '170px', height: '150px' }} />
                      ) : (
                        <>
                          <span className="material-symbols-outlined">
                            upload
                          </span>
                          <p>Upload Here</p>
                          <p>Max size: 5GB</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="py-2">
                    <label className='pt-2' htmlFor="pan">Pan *</label>
                    <div
                      className='border-dashed rounded'
                      onClick={() => document.getElementById('panInput').click()}
                      style={{ width: '174px', height: '154px', cursor: 'pointer', padding: '1px' }}
                    >
                      <input
                        type="file"
                        id="panInput"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, 'pan', 'panPreview')}
                      />
                      {filePreviews.panPreview ? (
                        <img className='rounded' src={filePreviews.panPreview} alt="Pan Preview" style={{ width: '170px', height: '150px' }} />
                      ) : (
                        <>
                          <span className="material-symbols-outlined">
                            upload
                          </span>
                          <p>Upload Here</p>
                          <p>Max size: 5GB</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex col-12 pt-4'>
                <button className="btn btn-secondary py-2 w-25 me-3" onClick={prevStep}>Back</button>
                <button className="btn btn-dark py-2 w-25" onClick={saveAndNext}>Save & Next</button>
              </div>
            </div>
          )}
          {step === 3 && (
            <form onSubmit={handleSubmitdata} className='col-9'>
            <div className=''>
          <div>
          <div className='p-1 form-control'>
              <span className='text-secondary'>Skills:</span>
              {selectedSkills.filter(skill => (skill === 'Walkthrough' ? files[skill] || isLinkValid(links[skill]) : files[skill])).map(skill => (
                <span key={skill} className="badge">{skill}</span>
              ))}
            </div>
            {skillsList.map((skill) => (
              <div key={skill} className="skill-item px-2">
                <input type="checkbox" id={skill} checked={selectedSkills.includes(skill)} onChange={() => handleCheckboxChange(setSelectedSkills, skill)} />
                <label className='p-2' htmlFor={skill}>{skill}</label>
                <input type="file" onChange={(e) => handleFileChange(e, skill)} required={selectedSkills.includes(skill) && skill !== 'Walkthrough'} />
                {skill === 'Walkthrough' && (
                  <input
                    className='form-control' type="text" placeholder="Link goes here" value={links[skill] || ''}
                    onChange={(e) => handleLinkChange(e, skill)}
                  />
                )}
              </div>
            ))}
          </div>
        
          </div>
            <div className="py-2">
              <label className="form-label">Style * </label><span key="Softwares" className='badge'>{selectedStyles.join(', ')}</span>
              <div className="d-flex ">
              <div>
              {stylesList.map((style) => (
                  <label key={style} className={`btn border my-1 mx-1 text-dark py-1 my-0 btn-outline-warning ${selectedStyles.includes(style) ? 'active' : ''}`}>
                    <input className="hidden-radio" type="checkbox" value={style} checked={selectedStyles.includes(style)} onChange={() => handleCheckboxChange(setSelectedStyles, style)} />
                    {style}
                  </label>
                ))}
              </div>
                <div className=' rounded border  d-flex p- custom-input my-1 mx-1 ' >
                <input type="text" className="border-0  rounded   placeholder-text"placeholder="Add custom style" value={customStyle} onChange={(e) => setCustomStyle(e.target.value)} />
                <div type="" className=" rounded-circle    " onClick={() => handleCustomInput(setSelectedStyles, customStyle, customStyle, setCustomStyle)}>
                  <span class="material-symbols-outlined pt-1 curser-pointer " >
                    add_circle
                  </span></div>
                </div>
                
                </div>
            </div>
            <div className="py-2 ">
              <label className="form-label ">Softwares * </label><span key="Softwares" className='badge'>{selectedSoftwares.join(', ')}</span>
              <div className="  ">
                <div>
                  {softwaresList.map((software) => (
                    <label key={software} className={`btn border my-1 mx-1 custom-radio  text-dark py-1 my-0 btn-outline-warning ${selectedSoftwares.includes(software) ? 'active' : ''}`}>
                      <input type="radio"className="hidden-radio"  value={software} checked={selectedSoftwares.includes(software)} onChange={() => handleCheckboxChange(setSelectedSoftwares, software)} />
                      {software}
                    </label>
                  ))}
                </div>
                <div className='border col-2 rounded  d-flex p- custom-input my-1 mx-1  ' >
                  <input type="text" className="border  rounded custom-radio  placeholder-text" placeholder="Add other software" value={customSoftware} onChange={(e) => setCustomSoftware(e.target.value)} />
                  <div type="" className=" rounded-circle    " onClick={() => handleCustomInput(setSelectedSoftwares, customSoftware, customSoftware, setCustomSoftware)}>
                    <span class="material-symbols-outlined pt-1 curser-pointer " >
                    add_circle
                  </span></div>
                </div>
              </div>
            </div>
    
            
          </form>
          )}
        </div>
        <div className='col-3'>
          <div className='shadow rounded w-100 py-3 px-2 h-100'>
            <div className='d-flex'>
              <span className='material-symbols-outlined'>help</span>
              <p className='fw-semibold text-center px-2 pt-2'>In case of any assistance, please contact 9876543210</p>
            </div>
          </div>
        </div>
      </div>
    );
  }; 
  //  <div className='mt-auto d-md-flex'>
    
    
  // <button type="submit" className="btn btn-primary" disabled={isSubmitting}>veryfy and Upload files</button>
  // <div>
  // {alertMessage && <div className="div-center alert-info">{alertMessage}</div>}
  // </div>
  // </div>

  return (
    <Container>
      <Form>
        {renderStep()}
        <Row>
          <Col>
            {step > 1 && (
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
            )}
          </Col>
          <Col>
            {step < 3 && (
              <Button variant="primary" onClick={saveAndNext}>
                Next
              </Button>
            )}
            {step === 3 && (
              <Button variant="success" onClick={handleSubmit}>
                Submit
              </Button>
              
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepForm;
