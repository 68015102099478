import React from 'react';

const ForgotPass = () => {
  return (
    <div>
      <h1>ForgotPass Page</h1>
      <p>This is the ForgotPass page content.</p>
    </div>
  );
};

export default ForgotPass;
