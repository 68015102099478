import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ViewCommercial3D = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://connectmydesigner.com/commercial3d');
        setData(response.data);
      } catch (err) {
        setError('Error fetching data');
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://connectmydesigner.com/commercial3d/${id}`);
      setData(data.filter((item) => item._id !== id));
    } catch (err) {
      setError('Error deleting data');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Commercial 3D Design Pricing Data</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Price Per Sq Feet</th>
            <th>Days For Design</th>
            <th>Revisions</th>
            <th>Views</th>
            <th>Extra Revision</th>
            <th>Extra Revision Price</th>
            <th>Extra Views</th>
            <th>Extra Views Price</th>
            <th>Urgent Design</th>
            <th>Urgent Design Price</th>
            <th>Inclusion Extras</th>
            <th>Additional Inclusions</th>
            <th>Exclusions</th>
            <th>User Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item._id}>
              <td>{item.pricePerSqFeet}</td>
              <td>{item.daysForDesign}</td>
              <td>{item.revisions}</td>
              <td>{item.views}</td>
              <td>{item.extraRevision}</td>
              <td>{item.extraRevisionPrice}</td>
              <td>{item.extraViews}</td>
              <td>{item.extraViewsPrice}</td>
              <td>{item.urgentDesign}</td>
              <td>{item.urgentDesignPrice}</td>
              <td>{item.inclusionExtras?.join(', ')}</td>
              <td>{item.additionalInclusions?.join(', ')}</td>
              <td>
                {item.exclusions && Object.keys(item.exclusions).map((exclusion) => (
                  <div key={exclusion}>
                    {exclusion}: {item.exclusions[exclusion]}
                  </div>
                ))}
              </td>
              <td>{item.userEmail}</td>
              <td>
                <button className="btn btn-danger" onClick={() => handleDelete(item._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCommercial3D;
