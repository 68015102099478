import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Spinner, Tabs, Tab, Alert, Form, Button, Dropdown } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

const DesignerPricingTab = ({ designerEmail }) => {
    const [residentialData, setResidentialData] = useState([]);
    const [commercialData, setCommercialData] = useState([]);
    const [exteriorData, setExteriorData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [residentialResponse, commercialResponse, exteriorResponse, userResponse] = await Promise.all([
                    axios.get(`https://connectmydesigner.in/pricing3d?email=${designerEmail}`),
                    axios.get(`https://connectmydesigner.in/commercial3d?email=${designerEmail}`),
                    axios.get(`https://connectmydesigner.in/pricing3dExterior?email=${designerEmail}`),
                    axios.get(`https://connectmydesigner.com/api/users/email/${designerEmail}`)
                ]);
                setResidentialData(residentialResponse.data);
                setCommercialData(commercialResponse.data);
                setExteriorData(exteriorResponse.data);
                setVerificationStatus(userResponse.data.verification);
                setSelectedStatus(userResponse.data.verification);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [designerEmail]);

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSave = async () => {
        try {
            await axios.put(`https://connectmydesigner.com/api/users/email/${designerEmail}`, { verification: selectedStatus });
            setVerificationStatus(selectedStatus);
            setIsEditing(false);
            // alert('Verification status updated successfully');
        } catch (error) {
            console.error('Error updating verification status:', error);
            alert('Failed to update verification status');
        }
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    if (isLoading) {
        return <div className="text-center"><Spinner animation="border" /></div>;
    }

    const renderPricingData = (data, title, isCommercial = false) => {
        if (data.length === 0) {
            return <div>No {title} pricing data available for this designer.</div>;
        }

        return (
            <div>
                
                {data.map((item) => (
                    <div className='d-flexs' key={item._id}>
                        <Card.Body className='bg-white'>
                            <h2 className='fw-bold'>
                                ₹ {item.pricePerRoom || item.pricePerSqFeet || item.pricePerView} /{isCommercial ? "perSqFt" : item.chargeType}
                            </h2>
                            <span className='fw-bold'>
                                <i className="fa-regular fa-clock "></i> <strong>{item.daysForDesign}</strong> days &nbsp; | &nbsp; <strong><i className="fa-solid fa-rotate"></i> {item.revisions}</strong> Revision
                            </span>
                            <div className="d-flex justify-content-between ">
                                <div className='d-block border  no-bullets p-3 shadow rounded w-50  carrd-bg-priceing'>
                                    <p className='fw-semibold'>Includes:</p>
                                    {item.inclusionExtras.map((inclusion, index) => (
                                        <li key={index}>{inclusion}</li>
                                    ))}
                                </div>
                                <div className=' no-bullets border p-3 shadow rounded w-50 mx-3 carrd-bg-priceing'>
                                    <p className='fw-semibold'>Extras:</p>
                                    {item.exclusions && Object.entries(item.exclusions).map(([key, value]) => (
                                        <div key={key}>
                                            <li>{key}: ₹ {value}</li>
                                        </div>
                                    ))}
                                </div>
                                {item.pricePerRoom && (
                                    <div className='d-block no-bullets border  p-3 shadow rounded w-50  carrd-bg-priceing'>
                                        <p className='fw-semibold'>Addons:</p>
                                        {item.addons && Object.entries(item.addons).map(([key, value]) => (
                                            <li key={key}>{key}: ₹ {value}</li>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </div>
                ))}
            </div>
        );
    };

    const renderAlert = () => {
        switch (verificationStatus) {
            case 'Approved':
                return (
                    <div className="   pt-2 px-4 mb-2 d-flex rounded-right-verification bg-verification-Verified border-verification-Verified justify-content-between align-items-center">
                        <div>
                            <h4>Verified</h4>
                            <p className='subtitle-verification'>Congratulations! Your profile has been approved. You are now ready to connect with clients and start taking on projects. Welcome to Connect My Designer!</p>
                        </div>
                        <PencilSquare onClick={handleEditClick} className="edit-icon" />
                    </div>
                );
            case 'Pending':
                return (
                    <div  className="   pt-2 px-4 mb-2 d-flex rounded-right-verification bg-verification-UnderVerification border-verification-UnderVerification justify-content-between align-items-center">
                        <div>
                            <h4>Under Verification</h4>
                            <p className='subtitle-verification'>Your profile is under verification. This may take 24-48 hours. Our team may contact you for additional information, so please be available.</p>
                        </div>
                        <PencilSquare onClick={handleEditClick} className="edit-icon" />
                    </div>
                );
            case 'On hold':
                return (
                    <div  className="   pt-2 px-4 mb-2 d-flex rounded-right-verification bg-verification-UnderReview border-verification-UnderReview justify-content-between align-items-center">
                        <div>
                            <h4>Under Review</h4>
                            <p className='subtitle-verification'>Your profile is under review. Our team may contact you for additional assessment of your skills as some information seems to be mismatching.</p>
                        </div>
                        <PencilSquare onClick={handleEditClick} className="edit-icon" />
                    </div>
                );
            case 'Rejected':
                return (
                    <div  className="   pt-2 px-4 mb-2 d-flex rounded-right-verification bg-verification-Rejected border-verification-Rejected justify-content-between align-items-center">
                        <div>
                            <h4>Rejected</h4>
                            <p className='subtitle-verification'>Your profile has been rejected as per CMD's policy. You do not fit the criteria. You can reapply after upgrading your skills and portfolio within a few months.</p>
                        </div>
                        <PencilSquare onClick={handleEditClick} className="edit-icon" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container">
            <div className="verification">
                {renderAlert()}
                {isEditing && (
                    <div className="d-flex  pb-2 gap-2 div-end">
                        <div controlId="verificationStatus " className='' >
                           
                            <select className='col-12 btn border px-3 ' as="select" value={selectedStatus} onChange={handleStatusChange}>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="On hold">On hold</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </div>
                        <button  onClick={handleSave} className=" col-2 bg-yellow-200 border-warning btn border p-1">Save</button>
                    </div>
                )}
            </div>
            <Tabs defaultActiveKey="residential" id="pricing-tabs" className='mb-1 d-flex justify-content-between'>
                <Tab eventKey="residential" title="Residential">
                    {renderPricingData(residentialData, 'Residential')}
                </Tab>
                <Tab eventKey="commercial" title="Commercial">
                    {renderPricingData(commercialData, 'Commercial', true)}
                </Tab>
                <Tab eventKey="exterior" title="Exterior">
                    {renderPricingData(exteriorData, 'Exterior')}
                </Tab>
            </Tabs>
        </div>
    );
};

export default DesignerPricingTab;
