import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';  
import PortfolioFormVid from './PortfolioFormVid';  
import ManagePortfolioVid from './ManagePortfolioVid';  
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MyAllPortfolioGroupsVid = () => {
  const [groups, setGroups] = useState([]);
  const [images, setImages] = useState({});
  const [showAddGroupModal, setShowAddGroupModal] = useState(false); 
  const [showManageModal, setShowManageModal] = useState(false); 
  const [selectedGroup, setSelectedGroup] = useState(null); 
  const { user } = useAuth(); 

  const fetchMyGroups = async () => {
    try {
      const response = await axios.get('https://connectmydesigner.in/Vidportfolioapi/mygroups', {
        params: { userEmail: user.email }
      });
      setGroups(response.data);

      response.data.forEach(group => fetchGroupImages(group._id));
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  const fetchGroupImages = async (groupId) => {
    try {
      const response = await axios.get(`https://connectmydesigner.in/Vidportfolioapi/groups/${groupId}`);
      setImages(prevImages => ({ ...prevImages, [groupId]: response.data.images }));
    } catch (error) {
      console.error('Error fetching group images:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMyGroups();
    }
  }, [user]);

  const handleManageGroup = (group) => {
    setSelectedGroup(group);
    setShowManageModal(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className='d-flex justify-content-between'>
        <h2>Portfolio (Vr Video):</h2>
        <button onClick={() => setShowAddGroupModal(true)} style={{ marginBottom: '20px' }}>
          Add New Group
        </button>
      </div>
      <div className='d-flex flex-wrap'>
        {groups.length > 0 ? (
          groups.map(group => (
            <div key={group._id} className='border rounded col-6'>
              <div className='d-flex div-center'>
                <span className='fs-3'>{group.groupName}</span>
                <button onClick={() => handleManageGroup(group)} className='btn btn-primary mx-2'>
                  Manage
                </button>
              </div>
              <div className=' px-5 py-2'>
                <Slider {...sliderSettings}>
                  {images[group._id] && images[group._id].map((image, index) => (
                    <div key={index}>
                   

                        <video
                                               src={`https://connectmydesigner.com/${image}`}
                                                style={{ width: '100%', maxHeight: '50vh', objectFit: 'cover' }}
                                                // controls
                                                muted
                                                autoPlay
                                                loop
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ))
        ) : (
          <p>No groups available</p>
        )}
      </div>

      {/* Modal for PortfolioForm (Add Group) */}
      {showAddGroupModal && (
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', width: '80vw', height: '80vh', padding: '20px', position: 'relative', overflowY: 'auto', borderRadius: '8px',
          }}>
            <button
              onClick={() => setShowAddGroupModal(false)}
              style={{
                position: 'absolute', top: '10px', right: '10px', fontSize: '20px', border: 'none', background: 'none', cursor: 'pointer'
              }}
            >
              &times;
            </button>
            <PortfolioFormVid />
          </div>
        </div>
      )}

      {/* Modal for ManagePortfolio (Manage Group) */}
      {showManageModal && selectedGroup && (
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', width: '80vw', height: '80vh', padding: '20px', position: 'relative', overflowY: 'auto', borderRadius: '8px',
          }}>
            <button
              onClick={() => setShowManageModal(false)}
              style={{
                position: 'absolute', top: '10px', right: '10px', fontSize: '20px', border: 'none', background: 'none', cursor: 'pointer'
              }}
            >
              &times;
            </button>
            <ManagePortfolioVid group={selectedGroup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAllPortfolioGroupsVid;
