import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios';
import './ChatApp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { auth } from '../firebase';

const socket = io('http://localhost:9999'); // Connect to Node.js backend

const ChatApp = () => {
  const [users, setUsers] = useState([]); // Registered users list
  const [selectedUser, setSelectedUser] = useState(null); // Selected chat user
  const [messages, setMessages] = useState([]); // Chat messages
  const [message, setMessage] = useState(''); // Current message
  const [file, setFile] = useState(null); // Current file to be uploaded
  const [currentUser, setCurrentUser] = useState(null); // Current logged-in user
  const [lastSeen, setLastSeen] = useState(null); // Last seen status of selected user

  useEffect(() => {
    // Get the current user from Firebase Auth
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user.email);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  useEffect(() => {
    // Fetch all users from the backend
    const fetchUsers = async () => {
      try {
        const response = await axios.get('http://localhost:9999/api/getAllUsers');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser && currentUser) {
      const fetchMessages = async () => {
        try {
          const response = await axios.get('http://localhost:9999/api/messages', {
            params: { from: currentUser, to: selectedUser.email },
          });
          setMessages(response.data);

          // Mark messages as read
          await axios.post('http://localhost:9999/api/markAsRead', {
            from: selectedUser.email,
            to: currentUser,
          });
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };

      const fetchLastSeen = async () => {
        try {
          const response = await axios.get('http://localhost:9999/api/lastSeen', {
            params: { email: selectedUser.email },
          });
          setLastSeen(response.data.lastSeen);
        } catch (error) {
          console.error('Error fetching last seen:', error);
        }
      };

      fetchMessages();
      fetchLastSeen();
    }
  }, [selectedUser, currentUser]);

  useEffect(() => {
    if (currentUser) {
      // Listen for incoming messages from the server
      socket.on('receiveMessage', (newMessage) => {
        if (
          (newMessage.from === selectedUser?.email && newMessage.to === currentUser) ||
          (newMessage.from === currentUser && newMessage.to === selectedUser?.email)
        ) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      });

      // Update last seen on component mount
      socket.emit('updateLastSeen', currentUser);
    }
  }, [selectedUser, currentUser]);



const handleSendMessage = async () => {
    if (currentUser && selectedUser) {
      let newMessage = {
        from: currentUser,
        to: selectedUser.email,
        content: message || '', // Ensure message is not null, even when sending a file
        timestamp: new Date(),
        read: false,
      };
  
      if (file) {
        // Handle file upload
        const formData = new FormData();
        formData.append('file', file);
  
        try {
          const response = await axios.post('http://localhost:9999/api/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
  
          if (response.data && response.data.fileUrl) {
            newMessage.fileUrl = response.data.fileUrl; // Attach the file URL to the message
          } else {
            console.error('File upload failed: No fileUrl returned');
            return; // Do not proceed if file upload fails
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          return; // Exit function if file upload fails
        }
      }
  
      socket.emit('sendMessage', newMessage); // Send message to the server
      setMessages([...messages, newMessage]); // Update local chat
      setMessage(''); // Clear input
      setFile(null); // Clear file
    }
  };
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-4">
          <h3>Users</h3>
          <ul className="list-group">
            {users.map((u) => (
              <li
                key={u.email}
                onClick={() => setSelectedUser(u)}
                className={`list-group-item ${selectedUser?.email === u.email ? 'active' : ''}`}
              >
                {u.displayName} ({u.email})
              </li>
            ))}
          </ul>
        </div>

        <div className="col-md-8">
          {selectedUser ? (
            <>
              <h3>
                Chat with {selectedUser.displayName} <br />
                <small>
                  {lastSeen ? `Last seen at ${new Date(lastSeen).toLocaleTimeString()}` : 'Online'}
                </small>
              </h3>
              <div className="chat-box border p-3 mb-3" style={{ height: '400px', overflowY: 'auto' }}>
                {messages.map((msg, index) => (
                  <div key={index} className={`message ${msg.from === currentUser ? 'text-end' : 'text-start'}`}>
                    <div className={`message-content ${msg.from === currentUser ? 'sender' : 'receiver'}`}>
                      <p>{msg.content}</p>
                      {msg.fileUrl && (
                        <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer">Download File</a>
                      )}
                      <small>{new Date(msg.timestamp).toLocaleTimeString()}</small>

                    
                    </div>
                  </div>
                ))}
              </div>

              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <button className="btn btn-primary" onClick={handleSendMessage}>
                  Send
                </button>
              </div>
            </>
          ) : (
            <p>Select a user to start chatting</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
