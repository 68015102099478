import React, { useState } from 'react';
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';


const tnclist = [
    {
      title: "1. Acceptance of Terms",
      points: [
        "By registering, using CMD’s services, or accessing the CMD website, you accept these Terms and Conditions, our Privacy Policy, and other related policies. If you do not agree to these terms, you may not use CMD’s services."
      ]
    },
    {
      title: "2. Changes to the Terms",
      points: [
        "CMD reserves the right to modify these Terms and Conditions at any time. Changes will be effective upon posting on the CMD website. Continued use of CMD’s services after changes are posted constitutes your acceptance of the modified terms."
      ]
    },
    {
      title: "3. User Accounts",
      points: [
        "Eligibility: To use CMD, you must be at least 18 years of age. By creating an account, you represent that you are legally capable of entering into binding contracts.",
        "Account Information: You agree to provide accurate and complete information when registering for an account. You are responsible for keeping your account information up-to-date and maintaining the confidentiality of your login details.",
        "Account Usage: You are responsible for all activities under your account. If you suspect any unauthorized use, notify CMD immediately."
      ]
    },
    {
      title: "4. CMD’s Role",
      points: [
        "CMD is a marketplace that facilitates connections between designers and clients. CMD acts only as a mediator and is not responsible for the points of the projects, the quality of services provided, or the outcome of any transaction."
      ]
    },
    {
      title: "5. Designer and Client Responsibilities",
      points: [
        "For Designers:",
        " Provide accurate profile information, portfolio, and pricing details.",
        " Deliver projects according to the agreed-upon terms and deadlines.",
        " Use CMD’s communication tools professionally.",
        "For Clients:",
        " Provide clear project briefs, requirements, and expectations.",
        " Respond to designer queries in a timely manner.",
        " Approve or provide feedback on projects within a reasonable time frame."
      ]
    },
    {
      title: "6. Payment Terms and Escrow System",
      points: [
        "CMD uses a secured escrow system to ensure that both clients and designers are protected.",
        "For Clients: Payment is required upfront when starting a project. Funds will be held in escrow and released to the designer only when the client approves the work.",
        "For Designers: You will be able to view the funds in escrow upon starting a project. Once the client approves the work, the funds will be credited to your CMD earnings dashboard. You can then request a withdrawal, which will be processed within 3-5 business days.",
        "CMD charges a commission of up to 25% on each transaction, deducted from the designer’s earnings."
      ]
    },
    {
      title: "7. Refund Policy",
      points: [
        "Refund Eligibility: Refunds may be requested by the client if:",
        " The project is not delivered within the agreed timeline.",
        " The work delivered does not meet the agreed-upon quality or specifications.",
        "Refund requests must be submitted within 5 days of project delivery.",
        "Refund Process: Upon receiving a refund request, CMD will mediate between the client and the designer to attempt to resolve the issue. If no resolution is reached, CMD may refund the client and deduct the equivalent amount from the designer’s account.",
        "Non-Refundable Situations:",
        " Refunds will not be issued for projects that are approved by the client.",
        " Refunds are not available for services that have already been completed as per the agreement."
      ]
    },
    {
      title: "8. Dispute Resolution",
      points: [
        "In the event of a dispute between a client and a designer, CMD will attempt to mediate the dispute:",
        "Mediation Process: CMD’s support team will review the project, communications, and any evidence provided by both parties to determine an appropriate resolution.",
        "CMD’s Role as Mediator: CMD does not guarantee the success of any mediation and acts solely as a neutral party to facilitate discussions. CMD’s decision is final and binding."
      ]
    },
    {
      title: "9. Project Cancellation",
      points: [
        "Client-Initiated Cancellations: Clients can cancel a project if the designer has not started the work or if the designer fails to deliver within the agreed timeline.",
        "Designer-Initiated Cancellations: Designers may cancel a project if the client is unresponsive for an extended period or if the project requirements are significantly changed after the start of the project.",
        "CMD reserves the right to charge cancellation fees or withhold funds if a cancellation is initiated without valid reason."
      ]
    },
    {
      title: "10. Intellectual Property",
      points: [
        "For Designers: By submitting work through CMD, you agree to transfer all intellectual property rights of the completed work to the client once the client has approved and paid for the project. Until the client makes full payment, the designer retains all rights to the work.",
        "For Clients: Upon payment completion, you obtain full ownership of the intellectual property of the work delivered, unless otherwise agreed upon with the designer."
      ]
    },
    {
      title: "11. User Conduct",
      points: [
        "Do not engage in harassment, abusive language, or discriminatory practices.",
        "Do not submit false information, plagiarized work, or attempt to defraud other users.",
        "Do not circumvent CMD’s payment systems by arranging off-platform transactions.",
        "CMD reserves the right to suspend or terminate accounts that violate these guidelines."
      ]
    },
    {
      title: "12. Limitation of Liability",
      points: [
        "CMD is not liable for any damages, losses, or claims arising from:",
        " The use or inability to use the platform.",
        " Disputes between clients and designers.",
        " Errors or inaccuracies in user profiles, portfolios, or project descriptions.",
        "In no event shall CMD’s total liability exceed the fees collected by CMD for the transaction in question."
      ]
    },
    {
      title: "13. Termination of Service",
      points: [
        "CMD reserves the right to suspend or terminate your account without notice if you violate these Terms and Conditions or if CMD deems your actions detrimental to the platform or other users."
      ]
    },
    {
      title: "14. Governing Law",
      points: [
        "These Terms and Conditions are governed by and construed in accordance with the laws of India. Any legal action or dispute arising out of or related to these terms shall be subject to the jurisdiction of the courts in Pune, India."
      ]
    },
    {
      title: "15. Contact Us",
      points: [
        "If you have any questions regarding these Terms and Conditions, please contact us:",
        "Email: hello@connectmydesigner.com",
        "Mailing Address: Swarajya building 101, opposite Dot fit Gym, near Jupiter Hospital, Prathamesh Park, Baner, Pune, Maharashtra 411045"
      ]
    }
  ];
  

const TermsAndConditions = () => {
    const [selectAll, setSelectAll] = useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(tnclist.length).fill(false)
    );

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckedState(new Array(tnclist.length).fill(newSelectAll));
    };

    const handleItemCheck = (index) => {
        const updatedCheckedState = checkedState.map((item, idx) =>
            idx === index ? !item : item
        );

        setCheckedState(updatedCheckedState);
        setSelectAll(updatedCheckedState.every(item => item));
    };

    return (
        <div>
<HeaderUser/>
      
        <div className='container px-lg-5'>

        <div className=' col-12'>
        <div className=' w-100 border-top pt-1'>
            <div className=' ps-3 d-flex pt-lg-5' style={{ borderRadius: "10px 10px 10px var(--Cart-Till, 0px)" }}>
        
                    <h2 className='  my-auto ps-2 py-5 gradient-text'>Terms And Conditions of connect my designer</h2>
                </div>
                            

                {tnclist.map((dontItem, index) => (
                    <div className=' rounded-3 my-2' key={index}>
                        <div className='dosdontstext rounded-top-3 px-2 py-1 fw-semibold '>
                      
                        <span  className='px-2'>{dontItem.title}</span>
                        </div>
                        <div className='text-gray-400 rounded-bottom-3 px-2 py-1 '>
                            {dontItem.points.map((point, idx) => (
                                <div className='d-flex' key={idx}>
                                    <i className="fa-solid fa-circle p-2 font-10px"></i>
                                    <span>{point}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        
            </div>
        </div>
        </div>
        <FooterDesigner/>               
        </div>
    );
};

export default TermsAndConditions;