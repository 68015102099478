import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import TabComponent from '../designerDashbord/TabComponent';
import M from './Apply-designer';
import { useNavigate } from 'react-router-dom';
import FooterDesigner from '../designer/footer-designer.js';
import HeaderUser from "../user/header-user";
import { Container } from 'react-bootstrap';
import PricingDisplay from '../user/myPricingDisplay';
import HelpForm from './help';
import MyFaq from './MyFaq';
import MyPortfolioGroups from './MyPortfolio';
import MyPortfolioGroupsVid from './MyPortfolioGroupsVid';

import Ticker from './Ticker';
// import FooterDesigner from '../footer-designer';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [showVerificationBox, setShowVerificationBox] = useState(true);
  const [imageUrl, setImageUrl] = useState(''); // New state for processed image

  const handleClose = () => {
    setShowVerificationBox(false);
    localStorage.setItem('verificationBoxClosed', 'true');
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://connectmydesigner.com/api/users?email=${user.email}`);
          const profiles = response.data;
          const userProfile = profiles.find(profile => profile.email === user.email);

          // Check if the verification status has changed
          const storedVerificationStatus = localStorage.getItem('verificationStatus');
          const storedBoxClosed = localStorage.getItem('verificationBoxClosed') === 'true';

          if (userProfile.verification !== storedVerificationStatus) {
            localStorage.setItem('verificationStatus', userProfile.verification);
            localStorage.setItem('verificationBoxClosed', 'false');
            setShowVerificationBox(true);
          } else if (storedBoxClosed) {
            setShowVerificationBox(false);
          }

          setProfile(userProfile);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchProfile();
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    navigate('/auth/login');
    return null;
  }

  if (!profile) {
    return <M />;
  }

  // Defensive programming: Ensure arrays before mapping
  const skills = Array.isArray(profile.skills) ? profile.skills : [];
  const styles = Array.isArray(profile.styles) ? profile.styles : [];
  const software = Array.isArray(profile.software) ? profile.software : [];

  const getShortenedName = (fullName) => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastNameInitial = nameParts.length > 1 ? nameParts[1].charAt(0) : '';
    return `${firstName}`;
  };

  const shortenedName = getShortenedName(profile.name);

  return (
    <div>
      <Container className="container-fluid">
        <HeaderUser />
        <HelpForm />
        <div className="d-lg-flex mt-of-profile">
          <div className="col-lg-4 col-12 ">
            <div>
              <div className='card    bottom-0 top-0 my-card-profile fixed-left-center w-30 rounded-4 shadow p-2'>

                <div className="text-center profile-img-size shadow-sm rounded-circle mx-auto">
                  <img
                    src={`https://connectmydesigner.com/uploads/${profile.profilePhoto}`}
                    alt=" "
                    className="rounded-circle shadow profile-img-size"
                  // style={{ minWidth: "130px", minHeight: "130px", maxWidth: "130px", maxHeight: "130px" }}
                  />
                </div>
                <span className='font-12px mt-1 text-center'><span className='bg-yellow-400 px-1 rounded'>Online</span></span>
                <div className="text-center div-center px-1 border-bottonm-1px-solid">
                  <div>
                    <div className='fw-bold pt-2 font-20px-fix card-subtitle text-greadiant '>
                      Designer {shortenedName}
                    </div>
                  </div>
                </div>
                <div className='div-center font-15px fw-normal text-secondary mx-4 px-1 py-0 rounded font-14px'>
                  <div><img src='' alt='' /></div>
                  <span> {profile.verification}</span>
                </div>
                <span className="p-1 font-14px "><span className='fw-semibold'>City:</span> {profile.city}</span>
                <span className="p-1 font-14px "><span className='fw-semibold'>Status:</span> Available</span>
                <span className="p-1 font-14px "><span className='fw-semibold'>Experience:</span> {profile.experience}</span>
                <span className="p-1 font-14px "><span className='fw-semibold'>Response time:</span> 30 min</span>
                <span className="p-1 font-14px d-flex "><span className='fw-semibold'>Starting From:</span> <PricingDisplay /></span>
                <div className='border'></div>
                <span className='p-1 font-14px '>
                  <p className='text-center p-0 m-0 fw-bold font-14px'>About me</p>
                  <span>
                    <div className='font-14px max-height-dec '> {profile.description?.split(' ').slice(0, 23).join(' ')}</div>
                  </span>
                </span>
                <div className='border'></div>
                <div className=' py-2 rounded  mt-1'>
                  <div className='py-1 d-flex flex-'>
                    <div><span className='px-1 fw-bold font-14px'>Skills:</span> </div>
                    <div className='d-flex flex-wrap font-14px'>
                      {skills.slice(0, 3).map((skill, index) => (
                        <div key={index} className="me-lg-1 font-14px">
                          {skill},
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='py-1 d-flex'>
                    <div><span className='px-1 fw-bold font-14px'>Style:</span> </div>
                    <div className='d-flex flex-wrap font-14px'>
                      {styles.slice(0, 3).map((style, index) => (
                        <div key={index} className="me-lg-1 font-14px">
                          {style},
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='py-1 d-flex'>
                    <div>
                      <span className='px-1 fw-bold font-14px'>Software:</span>
                    </div>
                    <div className='d-flex flex-wrap  font-14px'>
                      {software.slice(0, 3).map((software, index) => (
                        <div key={index} className="me-1 font-14px">
                          {software},
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="text-center pt-3"></div>
              </div>

            </div>
          </div>
          <div className='col-lg-8 '>
            <div>
              {showVerificationBox && (
                <div
                  className={`pt-2 px-4 mb-2 d-flex rounded-right-verification ${profile.verification === 'Approved' ? 'bg-verification-Verified border-verification-Verified' :
                    profile.verification === 'Pending' ? 'bg-verification-UnderVerification border-verification-UnderVerification' :
                      profile.verification === 'On hold' ? 'bg-verification-UnderReview border-verification-UnderReview' :
                        'bg-verification-Rejected border-verification-Rejected'
                    } justify-content-between align-items-center`}>
                  <div>
                    <h4>
                      {profile.verification === 'Approved' ? 'Approved' :
                        profile.verification === 'Pending' ? 'Pending' :
                          profile.verification === 'On hold' ? 'On Hold' : 'Rejected'}
                    </h4>
                    <p className='subtitle-verification'>
                      {profile.verification === 'Approved'
                        ? "Congratulations! Your profile has been approved. You are now ready to connect with clients and start taking on projects. Welcome to Connect My Designer!"
                        : profile.verification === 'Pending'
                          ? "Your profile is pending approval. Please wait as we review your information. This process may take some time."
                          : profile.verification === 'On hold'
                            ? "Your profile is on hold. Our team may contact you for additional information or clarification."
                            : "Your profile has been rejected as per CMD's policy. You do not fit the criteria. You can reapply after upgrading your skills and portfolio within a few months."}
                    </p>
                  </div>
                  <button onClick={handleClose} className="btn-close top-0"></button>
                </div>
              )}
            </div>
            <div>
              <div className='border '><Ticker/></div>
            </div>
            <div className='border px-lg-3'>
              
              <TabComponent />

{/* <PortfolioAndOfferCard/> */}

           <MyPortfolioGroups/>
           <MyPortfolioGroupsVid/>



<div>
  <MyFaq/>
</div>



            </div>
          </div>
        </div>

      </Container>

    </div>
  );
};

export default Profile;
