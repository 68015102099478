

import React from "react";
import { Link } from "react-router-dom";
const FooterDesigner = () => {
  return (
    <footer className="header-bg-dark text-light  pt-lg-4 pb-lg-2"  >
      <div className=" px-5 pt-2 ">
        <div className="d-flex flex-wrap text-center text-lg-start  ">
          <div className="col-lg-2 col-sm-4 my-lg-auto pb-3 col-12 py-lg-5"
            style={{
              alignItems: window.innerWidth <= 768 ? "between" : "center",
              position: "relative", // Required for the pseudo-element to work
              overflow: "hidden"    // Prevent the pseudo-element from overflowing
            }}
          >
            <img
              src="/assets/images/cmdlogo-white.png"
              alt="Connect My Designer"
              className=""
              style={{ width: "92%", position: "relative", zIndex: 2 }} // Ensure the image stays above the background
            />

            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url("/assets/images/favicon.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.2, // 30% opacity
              zIndex: 1,
              backgroundSize: "90%",
              backgroundRepeat: "no-repeat",
            }} />
          </div>


          <div className="col-lg-2 col-sm-4 col-6">
            <h5 className="text-warning  pb-3">For Designer</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/howtostart" className="text-light ">
                  <p> How to start</p>
                </Link>
              </li>
              {/* <li>
                <Link to="/user/Category" className="text-light ">
                  <p> Category</p>
                </Link>
              </li> */}
              <li>
                <Link to="/benefits" className="text-light ">
                  <p>    Benefits</p>
                </Link>
              </li>
              <li>
                <Link to="/FAQ" className="text-light ">
                  <p> FAQ</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4 col-6">
            <h5 className="text-warning pb-3">Company</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about" className="text-light ">
                  <p> About Us</p>
                </Link>
              </li>
              <li>
                <Link to="/user/JoinCommunity" className="text-light ">
                  <p>Join Community</p>
                </Link>
              </li>
              <li>
                <Link to="/Blog" className="text-light ">
                  <p> Blog</p>
                </Link>
              </li>

            </ul>
          </div>
          <div className="col-lg-2 col-sm-4 col-12">
            <h5 className="text-warning pb-3">Support</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/helpnsupport" className="text-light ">
                  <p> Help & Support</p>
                </Link>
              </li>
              <li>
                <Link to="/user/CommunityGuidelines" className="text-light ">
                  <p>  Guidelines</p>
                </Link>
              </li>

            </ul>
          </div>

          <div className="col-lg-4 col-sm-4 col-12  d-flex">
            <div className="mx-auto d-lg-flex">
              <div>
                <h5 className="text-warning pb-3">Contacts us</h5>
                <ul className="list-unstyled">
                  <li className="text-light py-2 ">hello@connectmydesigner.com</li>
                  <li className="text-light py-2 ">Swarajya building 101,  near Jupiter Hospital, Prathamesh Park,       Baner, Pune, Maharashtra 411045</li>
                </ul>
              </div>

              <div className="d-flex d-lg-block text-center div-center ms-lg-5  ">
                <p>
                  <a href="https://www.facebook.com/profile.php?id=61564915407064&mibextid=ZbWKwL" className="text-info" target="_blank">
                    <img src="/assets/icons/facebook.png" alt="Facebook" style={{ width: '30px', height: '30px' }} />
                  </a>
                </p>
                <p>
                  <a href="https://twitter.com/CMD_2024" className="text-warning" target="_blank">
                    <img src="/assets/icons/twitter.png" alt="Twitter" style={{ width: '34px', height: '34px' }} className="border rounded-circle p-1 bg-gray" />
                  </a>
                </p>
                <p>
                  <a href="https://www.instagram.com/connect_my_designer?igsh=bHFyM3gzazF0Y2gw" className="text-denger" target="_blank">
                    <img src="/assets/icons/instagram.png" alt="Instagram" style={{ width: '30px', height: '30px' }} />
                  </a>
                </p>
                <p>
                  <a href="https://www.linkedin.com/company/connect-my-designer/" className="text-warning" target="_blank">
                    <img src="/assets/icons/linkedin.png" alt="LinkedIn" style={{ width: '30px', height: '30px' }} />
                  </a>
                </p>
                <p>
                  <a href="https://www.youtube.com/@Connectmydesigner" className="text-warning" target="_blank">
                    <img src="/assets/icons/youtube.png" alt="Youtube" style={{ width: '30px', height: '30px' }} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          © 2024 Connect My Designer | <Link to="/privacy-policy" className="text-warning">Privacy Policy</Link> | <Link to="/cmd-t&c" className="text-warning">Terms & conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterDesigner;
