import React, { useState, useEffect } from 'react';
import { useAuth } from '../firebase'; // Assuming you have a custom hook for Firebase Auth

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const { user, loading } = useAuth(); // Get the authenticated user and loading state

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        const response = await fetch(`https://connectmydesigner.com/profile?email=${user.email}`);
        const data = await response.json();
        setProfile(data);
      }
    };

    fetchProfile();
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <p>You need to log in to view your profile.</p>;
  }

  if (!profile) {
    return <p>Loading profile data...</p>;
  }

  return (
    <div className="profile-card">
      
      <div className="">
              <div className="">
              <div
                  className="col-md-4 col-12 " >
                  <div className=" px-4 card  position-fixed   py-2 border-0  shadow-lg col-lg-3 " >
                    <div className="  text-center  ">
                      <div className="  ">
                        <img
                          className="profile-image "
                          src={user.photoURL || "/assets/images/hero-r1.png"}
                          alt={`${profile.username}'s Best Design`}
                          style={{ width:"100px", height:"100px" }}
                        />
                        <div className="green-dot"></div>
                      </div>

                      <div>
                        <div className="badge bg-gray text-black" >
                          <span>
                            Last seen :{profile.lastSeen}
                          </span>
                        </div>

                        <div className="w-100 border-top mt-2 font-20px   fw-bold">
                          <span className=" px-2 ">{profile.username}</span><span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <circle cx="10" cy="10" r="10" fill="#323567" />
                              <path d="M12.375 11.6667C11.8804 11.6667 11.4369 11.8948 11.1386 12.2536L8.20402 10.3926C8.2338 10.2666 8.24999 10.1352 8.24999 9.99998C8.24999 9.86473 8.23378 9.73334 8.20402 9.60734L11.1386 7.74639C11.4369 8.10516 11.8803 8.3333 12.375 8.3333C13.271 8.3333 14 7.58563 14 6.66664C14 5.74768 13.271 5 12.375 5C11.479 5 10.75 5.74768 10.75 6.66666C10.75 6.80191 10.7662 6.9333 10.796 7.0593L7.86138 8.92025C7.56307 8.56148 7.11966 8.33334 6.62499 8.33334C5.72897 8.33334 5 9.08102 5 10C5 10.919 5.72898 11.6667 6.62499 11.6667C7.11967 11.6667 7.56309 11.4385 7.8614 11.0797L10.796 12.9407C10.7662 13.0667 10.75 13.1981 10.75 13.3333C10.75 14.2523 11.479 15 12.375 15C13.271 15 14 14.2523 14 13.3333C14 12.4144 13.271 11.6667 12.375 11.6667ZM12.375 5.76924C12.8575 5.76924 13.25 6.17184 13.25 6.66668C13.25 7.16152 12.8575 7.56412 12.375 7.56412C11.8925 7.56412 11.5 7.16152 11.5 6.66668C11.5 6.17184 11.8925 5.76924 12.375 5.76924ZM6.62501 10.8974C6.14254 10.8974 5.75001 10.4948 5.75001 10C5.75001 9.50516 6.14254 9.10256 6.62501 9.10256C7.10749 9.10256 7.50002 9.50516 7.50002 10C7.50002 10.4948 7.10749 10.8974 6.62501 10.8974ZM12.375 14.2308C11.8925 14.2308 11.5 13.8282 11.5 13.3333C11.5 12.8385 11.8925 12.4359 12.375 12.4359C12.8575 12.4359 13.25 12.8385 13.25 13.3333C13.25 13.8282 12.8575 14.2308 12.375 14.2308Z" fill="white" />
                            </svg>
                          </span>
                        </div>
                        <div className="d-flex w-100 div-center ">
                          <img className="approved " src="/assets/images/approved.png">
                          </img>
                          <div className="font-small px-1 text-secondary">
                            <span>
                              Varified Designer
                            </span>
                          </div>
                        </div>


                      </div>
                      <div className="line-height border-bottom py-2">
                        {/* <div className="text-start  ">City:
                          <span className="text-secondary">{city ? ` ${city}` : profile.location}{" "}</span>
                        </div> */}
                        <div className="text-start  py-1 "><span>
                          Status:
                        </span>
                          <span
                            style={{ fontSize: "16px", }}
                            className={`   ${profile.available ? "text-success" : "text-danger"
                              }`}
                          >
                            {profile.available ? "Available" : "Not available"}
                          </span>
                        </div>

                        <div className=" text-start  py-1">
                          Response time :  <span className="text-secondary">
                          30 min
                          </span>
                        </div>
                        <div className=" text-start  py-1">
                          <span>
                            Starting From: ₹{profile.other || "N/A"} <span className="font-12px text-secondary">per room</span>
                          </span>
                        </div>
                      </div>


                    </div>
                    <div>
                      <h2 className="text-center pt-2 font-20px"> About Me</h2>
                      <p className="text-secondary font-12px">{profile.description}</p>
                    </div>

                    <div className="">
                      {/* <p>{profile.description}</p> */}
                      {profile.skills && (
                        <div className="mb-3">
                          Skills:{" "}
                          {profile.skills.split(",").map((skill, index) => (
                            <span
                              key={index}
                              className="badge bg-warning text-dark me-2" >
                              {skill.trim()}
                            </span>
                          ))}
                        </div>
                      )}
                      {profile.expertise && (
                        <div className="mb-3">
                          Styles:{" "}
                          {profile.expertise.split(",").map((skill, index) => (
                            <span
                              key={index}
                              className="btn py-0 my-1 text-dark border me-1"
                              style={{ fontSize: "12px" }}
                            >
                              {skill.trim()}
                            </span>
                          ))}
                        </div>

                      )}
                      <button className="btn border bg-gray px-5 py-1 mb-2 ">Hire</button>
                      <button className="btn border bg-gray px-5 py-1 mb-2 mx-4">chat</button>
                    </div>
                  </div>
                </div>
              
              </div>

             
            </div>
    </div>
  );
};

export default Profile;
