import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const ApplyForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    profilePhoto: null,
    mobileNo: '',
    description: '',
    gender: '',
    verification: 'Pending',
    dob: '',
    joiningdate: new Date().toISOString().split('T')[0],
    address: '',
    city: '',
    qualification: '',
    experience: '',
    statement: '',
    freelancingExperience: '',
    walkthroughLink: '',
    LinkedIn: '',
    strength: '',
    employmentstatus: '',
    jobCommitment: '',
    skills: [],
    styles: [],
    language: [],
    software: [],
    proof3DDesign: null,
    proofWalkthrough: null,
    proofLandscape: null,
    proof2DDrawing: null,
    aadhaarCardFront: null,
    portfolio: null,
    portfolioImages: null,
    profilePortfolio: [],
    profilePortfolioVr: [],
    panCardPhoto: null,
    collegeCertificate: null,
    email: user ? user.email : ''
  });

  useEffect(() => {
    if (user) {
      setFormData(prevData => ({ ...prevData, email: user.email }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else if (type === 'checkbox') {
      const updatedArray = formData[name].includes(value)
        ? formData[name].filter(item => item !== value)
        : [...formData[name], value];
      setFormData({ ...formData, [name]: updatedArray });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (Array.isArray(formData[key])) {
        formData[key].forEach(item => data.append(key, item));
      } else if (formData[key] instanceof File) {
        data.append(key, formData[key]);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.post('https://connectmydesigner.com/api/users', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      alert('Application submitted successfully!');
      navigate('/user/profile');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="apply-form">
      <h2>Designer Application Form</h2>
      
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="profilePhoto">Profile Photo</label>
        <input type="file" id="profilePhoto" name="profilePhoto" onChange={handleChange} accept="image/*" />
      </div>

      <div className="form-group">
        <label htmlFor="mobileNo">Mobile Number</label>
        <input type="tel" id="mobileNo" name="mobileNo" value={formData.mobileNo} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea id="description" name="description" value={formData.description} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="gender">Gender</label>
        <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="dob">Date of Birth</label>
        <input type="date" id="dob" name="dob" value={formData.dob} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="address">Address</label>
        <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="city">City</label>
        <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="qualification">Qualification</label>
        <input type="text" id="qualification" name="qualification" value={formData.qualification} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="experience">Experience (in years)</label>
        <input type="number" id="experience" name="experience" value={formData.experience} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="statement">Professional Statement</label>
        <textarea id="statement" name="statement" value={formData.statement} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="freelancingExperience">Freelancing Experience</label>
        <input type="text" id="freelancingExperience" name="freelancingExperience" value={formData.freelancingExperience} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="walkthroughLink">Walkthrough Link</label>
        <input type="url" id="walkthroughLink" name="walkthroughLink" value={formData.walkthroughLink} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="LinkedIn">LinkedIn Profile</label>
        <input type="url" id="LinkedIn" name="LinkedIn" value={formData.LinkedIn} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="strength">Strength</label>
        <input type="text" id="strength" name="strength" value={formData.strength} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="employmentstatus">Employment Status</label>
        <select id="employmentstatus" name="employmentstatus" value={formData.employmentstatus} onChange={handleChange} required>
          <option value="">Select Status</option>
          <option value="fulltime">Full-time</option>
          <option value="parttime">Part-time</option>
          <option value="freelance">Freelance</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="jobCommitment">Job Commitment</label>
        <input type="text" id="jobCommitment" name="jobCommitment" value={formData.jobCommitment} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label>Skills</label>
        {['3D Modeling', 'Interior Design', 'Landscape Design', '2D Drawing'].map(skill => (
          <div key={skill}>
            <input
              type="checkbox"
              id={skill}
              name="skills"
              value={skill}
              checked={formData.skills.includes(skill)}
              onChange={handleChange}
            />
            <label htmlFor={skill}>{skill}</label>
          </div>
        ))}
      </div>

      <div className="form-group">
        <label>Design Styles</label>
        {['Modern', 'Contemporary', 'Traditional', 'Minimalist'].map(style => (
          <div key={style}>
            <input
              type="checkbox"
              id={style}
              name="styles"
              value={style}
              checked={formData.styles.includes(style)}
              onChange={handleChange}
            />
            <label htmlFor={style}>{style}</label>
          </div>
        ))}
      </div>

      <div className="form-group">
        <label>Languages</label>
        {['English', 'Hindi', 'Spanish', 'French'].map(lang => (
          <div key={lang}>
            <input
              type="checkbox"
              id={lang}
              name="language"
              value={lang}
              checked={formData.language.includes(lang)}
              onChange={handleChange}
            />
            <label htmlFor={lang}>{lang}</label>
          </div>
        ))}
      </div>

      <div className="form-group">
        <label>Software</label>
        {['AutoCAD', 'SketchUp', '3ds Max', 'Revit'].map(sw => (
          <div key={sw}>
            <input
              type="checkbox"
              id={sw}
              name="software"
              value={sw}
              checked={formData.software.includes(sw)}
              onChange={handleChange}
            />
            <label htmlFor={sw}>{sw}</label>
          </div>
        ))}
      </div>

      <div className="form-group">
        <label htmlFor="proof3DDesign">Proof of 3D Design</label>
        <input type="file" id="proof3DDesign" name="proof3DDesign" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="proofWalkthrough">Proof of Walkthrough</label>
        <input type="file" id="proofWalkthrough" name="proofWalkthrough" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="proofLandscape">Proof of Landscape Design</label>
        <input type="file" id="proofLandscape" name="proofLandscape" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="proof2DDrawing">Proof of 2D Drawing</label>
        <input type="file" id="proof2DDrawing" name="proof2DDrawing" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="aadhaarCardFront">Aadhaar Card Front</label>
        <input type="file" id="aadhaarCardFront" name="aadhaarCardFront" onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="portfolio">Portfolio</label>
        <input type="file" id="portfolio" name="portfolio" onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="portfolioImages">Portfolio Images</label>
        <input type="file" id="portfolioImages" name="portfolioImages" onChange={handleChange} multiple />
      </div>

      <div className="form-group">
        <label htmlFor="panCardPhoto">PAN Card Photo</label>
        <input type="file" id="panCardPhoto" name="panCardPhoto" onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="collegeCertificate">College Certificate</label>
        <input type="file" id="collegeCertificate" name="collegeCertificate" onChange={handleChange} required />
      </div>

      <button type="submit">Submit Application</button>
    </form>
  );
};

export default ApplyForm;
