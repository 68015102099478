// PortfolioGroups.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PortfolioGroups = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [images, setImages] = useState([]);

  // Fetch portfolio groups from the backend
  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://connectmydesigner.com/portfolioapi/groups'); // Adjust the API endpoint as needed
      setGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  // Fetch images of the selected group
  const fetchGroupImages = async (groupId) => {
    try {
      const response = await axios.get(`https://connectmydesigner.com/portfolioapi/groups/${groupId}`); // Adjust the API endpoint as needed
      setImages(response.data.images);
      setSelectedGroup(response.data.groupName);
    } catch (error) {
      console.error('Error fetching group images:', error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ width: '200px', borderRight: '1px solid #ccc', padding: '10px' }}>
        <h3>Portfolio Groups</h3>
        <ul>
          {groups.map(group => (
            <li key={group._id} onClick={() => fetchGroupImages(group._id)} style={{ cursor: 'pointer', padding: '5px 0' }}>
              {group.groupName}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ flexGrow: 1, padding: '20px' }}>
        {selectedGroup ? (
          <>
            <h2>{selectedGroup}</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {images.map((image, index) => (
                <img key={index} src={`https://connectmydesigner.com${image}`} alt={`Group ${selectedGroup} - ${index}`} style={{ width: '200px', margin: '10px' }} />
              ))}
            </div>
          </>
        ) : (
          <h2>Select a group to view images</h2>
        )}
      </div>
    </div>
  );
};

export default PortfolioGroups;
