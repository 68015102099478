import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import heic2any from 'heic2any';

const TotalDataComponent = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [convertedImages, setConvertedImages] = useState({});
  const [pricingData, setPricingData] = useState({ exterior: null, commercial: null, Residential: null });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://connectmydesigner.com/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error.response || error.message);
    }
  };




  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };



  const handleViewFile = (url) => {
    window.open(url, '_blank');
  };

  const convertHeicToJpeg = async (filePath) => {
    if (convertedImages[filePath]) {
      return convertedImages[filePath];
    }

    try {
      const response = await axios.get(`https://connectmydesigner.com/uploads/${filePath}`, { responseType: 'blob' });
      const blob = response.data;
      const convertedBlob = await heic2any({ blob, toType: 'image/jpeg' });
      const objectUrl = URL.createObjectURL(convertedBlob);
      setConvertedImages((prev) => ({ ...prev, [filePath]: objectUrl }));
      return objectUrl;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      return null;
    }
  };

  const renderFilePreview = (filePath) => {
    if (!filePath) return null;

    const fileExtension = filePath.split('.').pop().toLowerCase();
    const fileUrl = `https://connectmydesigner.com/uploads/${filePath}`;

    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <img
            src={fileUrl}
            alt="Preview"
            className="img-thumbnail"
            style={{ width: '180px', maxHeight: '200px' }}
            onError={(e) => console.error('Error loading image:', e)}
          />
        );
      case 'heic':
        convertHeicToJpeg(filePath); // Trigger the conversion
        return (
          <img
            src={convertedImages[filePath] || ''}
            alt="Preview"
            className="img-thumbnail"
            style={{ width: '180px', maxHeight: '200px' }}
            onError={(e) => console.error('Error loading image:', e)}
          />
        );
      case 'pdf':
        return (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="100%"
            height="500px"
            className="img-thumbnail"
          />
        );
      case 'mp4':
      case 'webm':
      case 'ogg':
        return (
          <video width="100%" height="auto" controls>
            <source src={fileUrl} type={`video/${fileExtension}`} />
            Your browser does not support the video tag.
          </video>
        );
      case 'doc':
      case 'docx':
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`}
            width="100%"
            height="500px"
            className="img-thumbnail"
          />
        );
      default:
        return (
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            View File
          </a>
        );
    }
  };



  return (
    <div className="mt-5">
      <div className="row">
        <div className="col-md-2">
          <ul className="list-group">
            {users.map((user) => (
              <li
                key={user._id}
                className="list-group-item"
                onClick={() => handleUserSelect(user)}
              >
                {user.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-7">
          {selectedUser && (
            <div>
              <h3>{selectedUser.name}</h3>
              <div>
                {renderFilePreview(selectedUser.profilePhoto)}
                <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.profilePhoto}`)}>View</button>
              </div>
              <li>Email: {selectedUser.email}</li>
              <li>Mobile No: {selectedUser.mobileNo}</li>
              <li>Description: {selectedUser.description}</li>
              <li>Gender: {selectedUser.gender}</li>
              <li>Date of Birth: {selectedUser.dob}</li>
              <li>Address: {selectedUser.address}</li>
              <li>City: {selectedUser.city}</li>
              <li>Qualification: {selectedUser.qualification}</li>
              <li>Experience: {selectedUser.experience}</li>
              <li>Walkthrough Link: {selectedUser.walkthroughLink}</li>
              <li>Other Data: {selectedUser.otherData}</li>
              <li>Strength: {selectedUser.strength}</li>
              <li>Have you worked on online/digital projects?: {selectedUser.onlineProjects}</li>
              <li>Which type of job commitment do you prefer?: {selectedUser.jobCommitment}</li>
              <li>Skills: {selectedUser.skills ? selectedUser.skills.join(', ') : 'N/A'}</li>
              <li>Styles: {selectedUser.styles ? selectedUser.styles.join(', ') : 'N/A'}</li>
              <li>Language: {selectedUser.language ? selectedUser.language.join(', ') : 'N/A'}</li>
              <strong>Joining Date:</strong> {new Date(selectedUser.joiningdate).toLocaleDateString()} <br />
              <li>Software: {selectedUser.software ? selectedUser.software.join(', ') : 'N/A'}</li>
              {selectedUser.proof3DDesign && (
                <div>
                  <label>Proof for 3D Design :</label>
                  {renderFilePreview(selectedUser.proof3DDesign)}
                  <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.proof3DDesign}`)}>View</button>
                </div>
              )}
              {selectedUser.proofWalkthrough && (
                <div>
                  <label>Proof for Walkthrough:</label>
                  {renderFilePreview(selectedUser.proofWalkthrough)}
                  <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.proofWalkthrough}`)}>View</button>
                </div>
              )}
              {selectedUser.proofLandscape && (
                <div>
                  <label>Proof for Landscape:</label>
                  {renderFilePreview(selectedUser.proofLandscape)}
                  <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.proofLandscape}`)}>View</button>
                </div>
              )}
              {selectedUser.proof2DDrawing && (
                <div>
                  <label>Proof for 2D Drawing:</label>
                  {renderFilePreview(selectedUser.proof2DDrawing)}
                  <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.proof2DDrawing}`)}>View</button>
                </div>
              )}
              <div>
                <label>Aadhaar Card Front:</label>
                {renderFilePreview(selectedUser.aadhaarCardFront)}
                <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.aadhaarCardFront}`)}>View</button>
              </div>
              <div>
                <label>Aadhaar Card Back:</label>
                {renderFilePreview(selectedUser.aadhaarCardBack)}
                <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.aadhaarCardBack}`)}>View</button>
              </div>
              <div>
                <label>PAN Card:</label>
                {renderFilePreview(selectedUser.panCardPhoto)}
                <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.panCardPhoto}`)}>View</button>
              </div>
              <div>
                <label>College Certificate:</label>
                {renderFilePreview(selectedUser.collegeCertificate)}
                <button className="btn btn-primary ml-2" onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${selectedUser.collegeCertificate}`)}>View</button>
              </div>

            </div>
          )}
        </div>



      </div>
    </div>
  );
};

export default TotalDataComponent;
