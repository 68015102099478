import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import DesignerPricingTab from './DesignerPricingTab';
import axios from 'axios';
import heic2any from 'heic2any';
const SelectedDesignerComponent = ({ designer, goBack }) => {
    const [priceData, setPriceData] = useState(null);
    const [showMore, setShowMore] = useState(false); // State to track visibility

    useEffect(() => {
        const fetchPriceData = async () => {
            try {
                const response = await axios.get(`https://connectmydesigner.com/pricing3d?email=${designer.email}`);
                const data = response.data;

                if (data.length > 0) {
                    setPriceData(data[0]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPriceData();
    }, [designer.email]);
    const [convertedImages, setConvertedImages] = useState({});
    const convertHeicToJpeg = async (filePath) => {
        if (convertedImages[filePath]) {
            return convertedImages[filePath];
        }

        try {
            const response = await axios.get(`https://connectmydesigner.com/uploads/${filePath}`, { responseType: 'blob' });
            const blob = response.data;
            const convertedBlob = await heic2any({ blob, toType: 'image/jpeg' });
            const objectUrl = URL.createObjectURL(convertedBlob);
            setConvertedImages((prev) => ({ ...prev, [filePath]: objectUrl }));
            return objectUrl;
        } catch (error) {
            console.error('Error converting HEIC file:', error);
            return null;
        }
    };


    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        if (currentStep < 5) setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const renderFilePreview = (filePath) => {
        if (!filePath) return null;

        const fileExtension = filePath.split('.').pop().toLowerCase();
        const fileUrl = `https://connectmydesigner.com/uploads/${filePath}`;

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
                return (
                    <img
                        src={fileUrl}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: '220px', maxHeight: '260px' }}
                        onError={(e) => console.error('Error loading image:', e)}
                    />
                );
            case 'heic':
                convertHeicToJpeg(filePath); // Trigger the conversion
                return (
                    <img
                        src={convertedImages[filePath] || ''}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ width: '220px', maxHeight: '260px' }}
                        onError={(e) => console.error('Error loading image:', e)}
                    />
                );
            case 'pdf':
                return (
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                        className="img-thumbnail"
                    />
                );
            case 'mp4':
            case 'webm':
            case 'ogg':
                return (
                    <video width="220px" height="260px" controls>
                        <source src={fileUrl} type={`video/${fileExtension}`} />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'doc':
            case 'docx':
                return (
                    <iframe
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`}
                        width="100%"
                        height="500px"
                        className="img-thumbnail"
                    />
                );
            default:
                return (
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                        View File
                    </a>
                );
        }
    };


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const handleViewFile = (url) => {
        window.open(url, '_blank');
    };


    return (
        <div className="">
            <Button className='btn btn-secondary mb-3' onClick={goBack}>
                Back
            </Button>
            
            <div className="d-flex">
                <div className="col-md-4">
                    <div className=''>
                        <div className='card shadow p-2'>
                            <div>
                                <button className='mb-ve-20 end-0 me-2 btn border py-1 px-1' onClick={toggleShowMore}>
                                    {showMore ? 'View Less' : 'View More'}
                                </button>
                            </div>
                            <div className="text-center">
                                <img
                                    src={`https://connectmydesigner.com/uploads/${designer.profilePhoto}`}
                                    alt=" " className="rounded-circle shadow"
                                    style={{ minWidth: "130px", minHeight: "130px", maxWidth: "130px", maxHeight: "130px" }} />
                            </div>
                            <span className='font-12px text-center'><span className='bg-yellow-400 px-1 rounded'>Online/last seen</span></span>
                            <div className="text-center div-center px-1">
                                <div>
                                    <span className='fw-bold font-20px'>Designer {designer.name}</span>
                                    <div className='div-center mx-4 px-1 rounded'>
                                        <span>
                                            {designer.verification === 'Approved' && 'Approved Designer'}
                                            {designer.verification === 'Pending' && 'Under Verification'}
                                            {designer.verification === 'On hold' && 'On Hold Designer'}
                                            {designer.verification === 'Rejected' && 'Rejected Designer'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <span className="p-1"><span className='fw-semibold'>City:</span> {designer.city}</span>
                            <span className="p-1"><span className='fw-semibold'>Experience:</span> {designer.experience}</span>



                            <span className="p-1"><span className='fw-semibold'>Response time:</span> 30 min</span>
                            <span className="p-1"><span className='fw-semibold'>Starting price:</span>
                                {priceData && (
                                    <span>
                                        ₹ {priceData.pricePerRoom || priceData.pricePerSqFeet} / {priceData.pricePerRoom ? 'Per Room' : 'Per Sq Ft'}
                                    </span>
                                )}
                            </span>
                            <div className='border'></div>

                            <p className='text-center p-0 m-0 fw-bold'>About me</p>


                            <div className=' bg-white rounded p-1 card-subtitle' style={{ width: '100%', height: '100px' }} >
                                <span>{designer.description?.split(' ').slice(0, 23).join(' ')}...</span>
                            </div>
                            <span className='py-1 d-flex flex-wrap'>
                                <strong className='px-1'>Skills:</strong>  {designer.skills?.map((skill, index) => (
                                    <span key={index} className="me-1">   {skill},   </span>))}   </span>
                            <span className='py-1 d-flex flex-wrap'>
                                <strong className='px-1'>Style:</strong>   {designer.styles?.map((style, index) => (
                                    <span key={index} className="me-1">     {style},  </span>))}  </span>
                            <span className='py-1 d-flex flex-wrap'>
                                <strong className='px-1'>Software:</strong>    {designer.software?.map((software, index) => (<span key={index} className="me-1">
                                    {software},    </span>))}  </span>
                        </div>
                    </div>
                </div>
                <div className='col-8'>
                    {showMore ? (
                        <div>
                            {currentStep === 1 && (
                                <ul>
                                    <div className="p-1">
                                        <div className="d-flex">
                                            <div className="col-4 text-center">
                                                <img
                                                    src={`https://connectmydesigner.com/uploads/${designer.profilePhoto}`}
                                                    alt="Profile Photo"
                                                    style={{ width: "200px", height: "200px" }}
                                                />
                                            </div>
                                            <div className="col-8">
                                                <ul>
                                                    <p><strong>Name:</strong> {designer.name}</p>
                                                    <p> <strong>Mobile no:</strong> {designer.mobileNo}</p>
                                                    <p> <strong>Gender:</strong> {designer.gender}</p>
                                                    <p> <strong>Description:</strong> {designer.description}</p>
                                                </ul>
                                            </div>

                                        </div>
                                        <div>
                                            <div className='ps-2'>
                                                <p> <strong>Address:</strong> {designer.address}</p>
                                                <p> <strong>City:</strong> {designer.city}</p>
                                                <p> <strong>Date of Birth:</strong> {designer.dob}</p>
                                                <p> <strong>Qualification:</strong> {designer.qualification}</p>
                                                <p> <strong>Experience:</strong> {designer.experience}</p>

                                                <p> <strong>Language:</strong> {designer.language ? designer.language.join(', ') : 'N/A'}</p>
                                                <p> <strong>Skills:</strong> {designer.skills ? designer.skills.join(', ') : 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            )}

                            {currentStep === 2 && (
                                <ul>

                                    <div className='d-flex flex-wrap'>
                                        {designer.proof3DDesign && (
                                            <div className="file-preview-container col-lg-6">
                                                <strong>Proof for 3D Design:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.proof3DDesign}`)}>
                                                    {renderFilePreview(designer.proof3DDesign)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>
                                        )}
                                        {designer.proofWalkthrough && (
                                            <div className="file-preview-container col-lg-6">
                                                <strong>Proof for Walkthrough:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.proofWalkthrough}`)}>
                                                    {renderFilePreview(designer.proofWalkthrough)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>
                                        )}
                                        {designer.proofLandscape && (
                                            <div className="file-preview-container col-lg-6 ">
                                                <strong>Proof for Landscape:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.proofLandscape}`)}>
                                                    {renderFilePreview(designer.proofLandscape)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>
                                        )}
                                        {designer.proof2DDrawing && (
                                            <div className="file-preview-container col-lg-6 ">
                                                <strong>Proof for 2D Drawing:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.proof2DDrawing}`)}>
                                                    {renderFilePreview(designer.proof2DDrawing)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>
                                        )}
                                    </div>


                                </ul>
                            )}

                            {currentStep === 3 && (
                                <div>
                                    <ul>
                                        <p> <strong>Walkthrough Link:</strong> {designer.walkthroughLink}</p>
                                        <p> <strong>Strength:</strong> {designer.strength}</p>
                                        <p> <strong>Styles:</strong> {designer.styles ? designer.styles.join(', ') : 'N/A'}</p>
                                        <p> <strong>Software:</strong> {designer.software ? designer.software.join(', ') : 'N/A'}</p>
                                        <p> <strong>Employment Status:</strong> {designer.employmentstatus}</p>
                                        <p> <strong>Freelancing Experience:</strong> {designer.freelancingExperience}</p>
                                        <p> <strong>Job Commitment:</strong> {designer.jobCommitment}</p>
                                        <p> <strong>Statement Describe You:</strong> {designer.statement}</p>
                                        <p> <strong>LinkedIn:</strong> {designer.LinkedIn}</p>
                                        <p><strong>Joining Date:</strong> {new Date(designer.joiningdate).toLocaleDateString()}</p>
                                        <p> <strong>Email:</strong> {designer.email}</p>
                                    </ul>

                                </div>
                            )}

                            {currentStep === 4 && (
                                <div>
                                    <ul>

                                        <div className='d-flex flex-wrap'>
                                            <div className=' file-preview-container col-lg-6'>
                                                <strong>Identity Proof:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.aadhaarCardFront}`)}>
                                                    {renderFilePreview(designer.aadhaarCardFront)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>

                                            <div className='file-preview-container col-lg-6'>
                                                <strong>Portfolio:</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.portfolio}`)}>
                                                    {renderFilePreview(designer.portfolio)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>

                                            <div className='file-preview-container col-lg-6'>
                                                <strong>Degree / Diploma / Course Certificate :</strong>
                                                <a onClick={() => handleViewFile(`https://connectmydesigner.com/uploads/${designer.collegeCertificate}`)}>
                                                    {renderFilePreview(designer.collegeCertificate)}
                                                    <div className="hover-text">View</div>
                                                </a>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            )}





                            <div className="d-flex justify-content-between mt-3">
                                {currentStep > 1 && (
                                    <button className="btn mx-4 btn-primary" onClick={handleBack}>Back</button>
                                )}
                                {currentStep < 4 && (
                                    <button className="btn ms-auto btn-primary" onClick={handleNext}>Next</button>
                                )}
                            </div>


                        </div>

                    ) : (
                        <DesignerPricingTab designerEmail={designer.email} />
                    )}
                </div>

            </div>
        </div>
    );
};

export default SelectedDesignerComponent;
