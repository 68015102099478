import React, { useEffect, useState, useRef } from 'react';
import './Ticker.css';

const Ticker = () => {
  const [news] = useState([
    "Welcome to CMD! Our platform is launching soon—get ready to connect with clients and showcase your skills!",
    "Welcome to CMD! Our platform is launching soon—get ready to connect with clients and showcase your skills!",
    "Welcome to CMD! Our platform is launching soon—get ready to connect with clients and showcase your skills!",
    "Welcome to CMD! Our platform is launching soon—get ready to connect with clients and showcase your skills!",
    "Welcome to CMD! Our platform is launching soon—get ready to connect with clients and showcase your skills!"
    
  ]);

  const tickerContentRef = useRef(null);

  useEffect(() => {
    const tickerContent = tickerContentRef.current;

    const animate = () => {
      tickerContent.scrollLeft += 1;

      if (tickerContent.scrollLeft >= tickerContent.scrollWidth / 3) {
        tickerContent.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(animate, 30);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="ticker-container py-1">
      <div className="ticker-content " ref={tickerContentRef}>
        {news.map((item, index) => (
          <span key={index} className="ticker-item">{item}</span>
        ))}
        {news.map((item, index) => (
          <span key={`repeat-${index}`} className="ticker-item">{item}</span>
        ))}
      </div>
    </div>
  );
};

export default Ticker;
