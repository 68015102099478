import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const PricingTable = () => {
  const [pricingData, setPricingData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);

  useEffect(() => {
    fetchPricingData();
  }, []);

  const fetchPricingData = async () => {
    try {
      const response = await axios.get('https://connectmydesigner.com/pricing3dExterior');
      setPricingData(response.data);
    } catch (error) {
      console.error('Error fetching pricing data', error);
    }
  };

  const handleEdit = (id) => {
    setEditRowId(id);
  };

  const handleInputChange = (e, id, field) => {
    const newData = pricingData.map((item) => {
      if (item._id === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setPricingData(newData);
  };

  const handleDelete = (id) => {
    setConfirmDelete(id);
  };

  const confirmDeleteAction = async (id) => {
    try {
      await axios.delete(`https://connectmydesigner.com/pricing3dExterior/${id}`);
      setConfirmDelete(null);
      fetchPricingData();
    } catch (error) {
      console.error('Error deleting pricing data', error);
    }
  };

  const handleSave = async (id) => {
    const editedData = pricingData.find((data) => data._id === id);
    try {
      if (id) {
        await axios.put(`https://connectmydesigner.com/pricing3dExterior/${id}`, editedData);
      } else {
        await axios.post('https://connectmydesigner.com/pricing3dExterior', editedData);
      }
      setEditRowId(null);
      fetchPricingData();
    } catch (error) {
      console.error('Error saving pricing data', error);
    }
  };

  const formatExclusions = (exclusions) => {
    if (!exclusions) return '';
    return Object.entries(exclusions)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  };

  return (
    <div className="container mt-5">
      <h2>3D Exterior Design Pricing Data</h2>
      {confirmDelete && (
        <div className="alert alert-danger">
          <p>Are you sure you want to delete this entry?</p>
          <button className="btn btn-danger" onClick={() => confirmDeleteAction(confirmDelete)}>Confirm Delete</button>
          <button className="btn btn-secondary" onClick={() => setConfirmDelete(null)}>Cancel</button>
        </div>
      )}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>User Email</th>
            <th>Charge Type</th>
            <th>Price Per View</th>
            <th>Price Per Sq Feet</th>
            <th>Days For Design</th>
            <th>Revisions</th>
            <th>Views</th>
            <th>Extra Revision</th>
            <th>Extra Revision Price</th>
            <th>Extra Views</th>
            <th>Extra Views Price</th>
            <th>Urgent Design</th>
            <th>Urgent Design Price</th>
            <th>Inclusion Extras</th>
            <th>Exclusions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pricingData.map((data) => (
            <tr key={data._id}>
              <td>{data.userEmail}</td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={data.chargeType}
                    onChange={(e) => handleInputChange(e, data._id, 'chargeType')}
                  />
                ) : (
                  data.chargeType
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.pricePerView}
                    onChange={(e) => handleInputChange(e, data._id, 'pricePerView')}
                  />
                ) : (
                  data.pricePerView
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.pricePerSqFeet}
                    onChange={(e) => handleInputChange(e, data._id, 'pricePerSqFeet')}
                  />
                ) : (
                  data.pricePerSqFeet
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.daysForDesign}
                    onChange={(e) => handleInputChange(e, data._id, 'daysForDesign')}
                  />
                ) : (
                  data.daysForDesign
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.revisions}
                    onChange={(e) => handleInputChange(e, data._id, 'revisions')}
                  />
                ) : (
                  data.revisions
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.views}
                    onChange={(e) => handleInputChange(e, data._id, 'views')}
                  />
                ) : (
                  data.views
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={data.extraRevision}
                    onChange={(e) => handleInputChange(e, data._id, 'extraRevision')}
                  />
                ) : (
                  data.extraRevision
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.extraRevisionPrice}
                    onChange={(e) => handleInputChange(e, data._id, 'extraRevisionPrice')}
                  />
                ) : (
                  data.extraRevisionPrice
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.extraViews}
                    onChange={(e) => handleInputChange(e, data._id, 'extraViews')}
                  />
                ) : (
                  data.extraViews
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.extraViewsPrice}
                    onChange={(e) => handleInputChange(e, data._id, 'extraViewsPrice')}
                  />
                ) : (
                  data.extraViewsPrice
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={data.urgentDesign}
                    onChange={(e) => handleInputChange(e, data._id, 'urgentDesign')}
                  />
                ) : (
                  data.urgentDesign
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="number"
                    className="form-control"
                    value={data.urgentDesignPrice}
                    onChange={(e) => handleInputChange(e, data._id, 'urgentDesignPrice')}
                  />
                ) : (
                  data.urgentDesignPrice
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={data.inclusionExtras.join(', ')}
                    onChange={(e) => handleInputChange(e, data._id, 'inclusionExtras')}
                  />
                ) : (
                  data.inclusionExtras.join(', ')
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <input
                    type="text"
                    className="form-control"
                    value={formatExclusions(data.exclusions)}
                    onChange={(e) => handleInputChange(e, data._id, 'exclusions')}
                  />
                ) : (
                  formatExclusions(data.exclusions)
                )}
              </td>
              <td>
                {editRowId === data._id ? (
                  <>
                    <button className="btn btn-success" onClick={() => handleSave(data._id)}>Save</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setEditRowId(null)}>Cancel</button>
                  </>
                ) : (
                  <>
                    <button className="btn btn-primary" onClick={() => handleEdit(data._id)}>Edit</button>
                    <button className="btn btn-danger ml-2" onClick={() => handleDelete(data._id)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricingTable;
