

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase'; // Ensure the path to firebase.js is correct
import { Card, Button, ListGroup, span } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Pricing3DComponent from '../designerDashbord/residential3d';
import Residential3DEditForm from '../designer/Residential3DEdit';

const MyData = () => {
  const { user, loading } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editing, setEditing] = useState(false); // State to manage edit mode
  const [selectedItem, setSelectedItem] = useState(null); // State for selected item to edit
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://connectmydesigner.in/pricing3d?email=${user.email}`);
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user]);

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setEditing(true);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const handleCancelClick = () => {
    const confirmCancel = window.confirm('Are you sure you want to cancel editing? Any unsaved changes will be lost.');
    if (confirmCancel) {
      setEditing(false);
    }
  };



  if (data.length === 0) {
    return (
      <div className="w-100 div-center">
        {showComponent ? (
          <Pricing3DComponent />
        ) : (
          <button className="col-7 border-dotted-1px pt-lg-5 px-lg-5 px-2 pt-4 pb-1 pb-lg-4 rounded bg-white mt-5" onClick={() => setShowComponent(true)}>
            <i className="fa fa-plus p-3 border-dotted-1px rounded-circle" aria-hidden="true"></i>
            <p className="py-3">Fill the price form to create the 3d design price section</p>
          </button>
        )}
      </div>
    );
  }


  if (editing) {
    return (
      <div>
        <Residential3DEditForm item={selectedItem} onClose={() => setEditing(false)} />
        <div className="px-5 py-4">
          <button className="btn btn-secondary" onClick={handleCancelClick}>Cancel</button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {data.map((item) => (
        <div className='d-flex' key={item.id}>
          <Card.Body>
            {/* <h2 className='fw-bold'>
              ₹ {item.pricePerRoom || item.pricePerSqFeet} /{item.chargeType}  
              <i
                className="fa-solid fa-circle-info fw-semibold font-12px"
                data-tooltip-id="info1"
              ></i>
            </h2> */}
            <h2 className='fw-bold'>
              ₹ {item.pricePerRoom || item.pricePerSqFeet} /
              {item.chargeType === 'perSqFeet' ? (
                <span className='font-16px'>SqFeet</span>
              ) : item.chargeType === 'perRoom' ? (
                <span className='font-16px'>Room</span>
              ) : (
                item.chargeType
              )}
              <i
                className="fa-solid fa-circle-info fw-semibold font-12px"
                data-tooltip-id="info1"
              ></i>
            </h2>

            <ReactTooltip id="info1" place="top" effect="solid">
              <span className="font-12px fw-lighter ">
                GST applied will be extra
              </span>
            </ReactTooltip>
            <span className='fw-bold'>
              <i className="fa-regular fa-clock "></i> <strong>{item.daysForDesign}</strong> days &nbsp; | &nbsp; <strong>
                <i className="fa-solid fa-rotate"></i> {item.revisions}</strong> Revision
            </span>
            <div className="d-flex col-11 justify-content gap-3">
              <div className='d-block col-4 no-bullets p-3 shadow rounded carrd-bg-priceing'>
                <p className='fw-semibold'>Includes:</p>
                {item.inclusionExtras.map((inclusion, index) => (
                  <li key={index}>{capitalizeFirstLetter(inclusion)}</li>
                ))}
              </div>
              {/* <div className=' col-4 no-bullets p-3 shadow rounded carrd-bg-priceing'>
                <p className='fw-semibold'>Extras:</p> 
                {item.exclusions && Object.entries(item.exclusions).map(([key, value]) => (
                  <div>
                    <li key={key}>{key}: ₹ {value}</li>
                  </div>
                ))}
              </div> */}
              <div className=' col-4 no-bullets p-3 shadow rounded carrd-bg-priceing'>
                <p className='fw-semibold'>Extras:</p>
                {item.exclusions && Object.entries(item.exclusions).map(([key, value]) => (
                  <div>
                    <li key={key}>{capitalizeFirstLetter(key)}: ₹ {value}</li>
                  </div>
                ))}
              </div>
              {/* {item.pricePerRoom && (
                <div className='d-block no-bullets col-4 p-3 shadow rounded carrd-bg-priceing'>
                  <p className='fw-semibold'>Addons:</p> 
                  {item.addons && Object.entries(item.addons).map(([key, value]) => (
                    <li key={key}>{key}: ₹ {value}</li>
                  ))} 
                </div>
              )} */}

              {item.pricePerRoom && (
                <div className='d-block no-bullets col-4 p-3 shadow rounded carrd-bg-priceing'>
                  <p className='fw-semibold'>Addons:</p>
                  {item.addons && Object.entries(item.addons).map(([key, value]) => (
                    <li key={key}>{capitalizeFirstLetter(key)}: ₹ {value}</li>
                  ))}
                </div>
              )}
            </div>
            <div className='px-5 py-4 div-end'>
              <button className="btn px-4 bg-yellow" onClick={() => handleEditClick(item)}>Edit price</button>
            </div>
          </Card.Body>
        </div>
      ))}
    </div>
  );
};

export default MyData;