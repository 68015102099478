import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PaymentPolicy.css';

const PaymentGuidelines = () => {
    return (
        <div className="container mt-5 Policies">
            <h1 className="gradient-text mb-4 text-center">CMD Payment Policy for Designers</h1>
            <ol className="list-group list-group-numbered" list-style-type="number">
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 1. Commission Structure:</strong>
                    <ul>
                        <li>CMD charges a commission of up to 25% on each project or transaction facilitated through our platform.</li>
                        <li>The remaining 75% or more of the project value is credited to your CMD account.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 2. Payment Flows:</strong>
                    <ul>
                        <li>
                            <span className="fw-bold text-gray-400">Secured Escrow System:</span> CMD uses a secured escrow system to ensure safe
                            transactions. Payments from clients are held in escrow and are only released to you, the
                            designer, once the client has approved the completed project. This system ensures that
                            both parties are satisfied with the transaction</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 3. Payment Methods:</strong>
                    <ul>
                        <li><span className="fw-bold text-gray-400">India:</span>  Payments can be made directly to your Indian bank account via NEFT, IMPS, or UPI.</li>
                        <li><span className="fw-bold text-gray-400">International:</span>  For designers outside of India, payments can be made via PayPal or wire
                            transfer. Please ensure your account details are correctly provided to avoid delays.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 4. Bank Transfer Fees:</strong>
                    <ul>
                        <li> <span className="fw-bold text-gray-400">India:</span> No bank transfer fees for Indian transactions.</li>
                        <li> <span className="fw-bold text-gray-400">International:</span> A fee of $25 will be deducted for international wire transfers. PayPal
                            transactions will incur a fee of 5% of the withdrawal amount, capped at $20..</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 5. Earnings and Withdrawals:</strong>
                    <ul>
                        <li><span className="fw-bold text-gray-400">Earnings Dashboard:</span>  As soon as a client approves your project, the earnings are
                            credited to your CMD dashboard. You can monitor your earnings in real-time.</li>
                        <li><span className="fw-bold text-gray-400">Withdrawal Requests: </span>You can request a withdrawal from your CMD dashboard. The
                            amount will be credited to your registered bank account within 3-5 working days.</li>
                        <li className=" ms-4"><span className="fw-bold text-gray-400 "> Minimum Withdrawal Limit: </span>The minimum withdrawal amount is ₹500 (or
                            equivalent in other currencies). For example, if you earn ₹490 from a project, you
                            will need to wait until your total earnings exceed ₹500 before you can request a
                            withdrawal.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 6. Currency Conversion:</strong>
                    <ul>
                        <li>All payments will be processed in INR (Indian Rupees) for Indian designers and in USD
                            (United States Dollars) for international designers. Currency conversion fees may apply
                            and will be borne by the designer.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 7. Taxes:</strong>
                    <ul>
                        <li>All taxes, including GST, VAT, or any other applicable taxes, are the sole responsibility of
                            the designer. CMD is not liable for any tax obligations that may arise from the payments
                            you receive.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 8. Disputes and Refunds:</strong>
                    <ul>
                        <li>In case of any disputes or refund requests from the client, CMD will act as a mediator to
                            resolve the issue. Any payments held in escrow will not be released until the dispute is
                            resolved</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 9. Account Information:</strong>
                    <ul>
                        <li>Please ensure that your bank account details, including IBAN/SWIFT codes (for
                            international transfers), are correctly provided in your CMD profile to avoid any payment
                            delays.</li>
                    </ul>
                </li>
                <li className="list-group-item-pay mb-3">
                    <strong className="strong-text"> 10. Support:</strong>
                    <ul>
                        <li>If you have any questions regarding payments or need assistance, feel free to contact
                            our support team at <span className='text-warning fw-bold'>hello@connectmydesigner.com</span>.</li>
                    </ul>
                </li>
            </ol>
        </div>
    );
};

export default PaymentGuidelines;