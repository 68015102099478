
import React from 'react';
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';

const Benefits = () => {
  return (
    <div className="benefits-page pt-5 mt-lg-5 ">
      <HeaderUser />
<div className=' bg-Benifits '
style={{
  backgroundImage: "url('/assets/images/bg-Benifits-small.png')", backgroundSize: "cover", backgroundPosition: "center", width: "100%", height: "50%",
}}  >
<div className='container px-lg-5  py-lg-4 py-5 text-center  '>
  <div className='pb-4'><span className="gradient-text display-5   " > Benefits of joining Connect My Designer   </span></div>
  <div className='col-12   d-lg-flex gap-3 py-lg-3 justify-content-start'>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits1.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>Earn Independently & Handsomely</span> </div>
    </div>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits2.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>Skill Enhancement Courses</span> </div>
    </div>
  </div>
  <div className='col-12 d-lg-flex gap-3 py-lg-3 justify-content-end'>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits3.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>Top Designer Community</span> </div>
    </div>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits4.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>More Projects, More Earnings</span> </div>
    </div>
  </div>
  <div className='col-12 d-lg-flex gap-3 py-lg-3 justify-content-center'>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits5.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>Build Your Personal Brand</span> </div>
    </div>
    <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
      <div>
        <img className='width-50px pe-3' src='/assets/images/Benifits6.gif' alt='p' />
      </div>
      <div className='d-flex my-auto font-18px'>
        <span>No Payment Chasing with clients</span> </div>
    </div>
  </div>


  </div></div>
<FooterDesigner />
    </div>
  );
};

export default Benefits;
