import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';



function HowToStart() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div>
    <HeaderUser />
    <div className=" container py-lg-5 " id='Watch'
      style={{
        backgroundImage: "url('/assets/images/HowtoRegister.png')", backgroundSize: "50%", backgroundPosition: "right", backgroundRepeat: "no-repeat", width: "100%", height: "50%",
      }}   >

      <div className="col-12  p-lg-5 p-3  text-center ">

        <video className="object-fit-vid rounded-5 p-1 hero-section-gradient" ref={videoRef} src="/assets/videos/howto.mp4" alt="loding..." autoPlay muted style={{ width: "92%", height: "92%" }} />
        <button
          onClick={handlePlayPause} className="play-button "   >
          <img src={isPlaying ? "/assets/images/pause.png" : "/assets/images/play.png"
          } alt={isPlaying ? "Pause" : "Play"} />
        </button>

      </div>
      <div className="col-12 pt-lg-5 pb-lg-5 pb-3 ps-lg-4 my-auto">
        <span className=" text-center display-4 fw-600  " >
          <span className="text-center">Manage Your Design  
            Career with <span className="gradient-text" > CMD</span>
          </span>
        </span>

        <p className=" py-lg-3 py-0 px-lg-0 px-2 text-lg-start text-center font-14px-mob text-gray-400" >
          Streamline your projects, showcase your portfolio, and connect with clients effortlessly.
        </p>
        <div className="mb-lg-4 mt-lg-4 mb-2 d-lg-flex  d-block  ">
          <div className="  "> <Button as={Link} to="/apply-as-designer" className="rounded   col-lg-12 col-6 mx-auto   Display-6 shadow border-gradient-btn-how mr-lg-2">Apply as a designer</Button></div>


        </div>
      </div>

    </div>
    <FooterDesigner/>
    </div>
  );
}

export default HowToStart;