import React, { useState } from 'react';
import { Collapse, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import './Dashboard.css';

const DashboardSidebar = () => {
  const [isOpen, setIsOpen] = useState(true);  // Initially open
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const [dropdowns, setDropdowns] = useState({
    Dashboard: false,
    Profile: false,
    Messages: false,
    FindWork: false,
    Transaction: false,
    Quotation: false,
    Calendar: false,
    Wallet: false,
    ReferEarn: false,
    HelpDispute: false,
    Setting: false,
    Logout: false
  });

  const toggle = () => setIsOpen(!isOpen);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'Dashboard' || item === 'Logout') {
      setDropdowns({
        Dashboard: false,
        Profile: false,
        Messages: false,
        FindWork: false,
        Transaction: false,
        Quotation: false,
        Calendar: false,
        Wallet: false,
        ReferEarn: false,
        HelpDispute: false,
        Setting: false,
        Logout: false
      });
    }
  };

  const toggleDropdown = (item) => {
    setDropdowns(prevState => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === item ? !prevState[item] : false;
        return acc;
      }, {})
    }));
  };

  return (
    <div className=''>
      <div className='d-flex'>
        <div className={`bg-light  p-0 h-100vh col-2 `}>
          <div onClick={toggle} className="p-3 div-end toggle-hide ">
            <p>{isOpen ? <i className="fa-solid fa-angles-left"></i> : <i className="fa-solid fa-angles-right"></i>}</p>
          </div>
          <Navbar className=' '>
            <nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClicks={() => handleItemClick('Dashboard')}
                    className={selectedItem === 'Dashboard' ? 'active' : ''}
                  >
                    <div className=''>
                      <img className='img-nav' src='/assets/icons/dashboard.png' />
                      {isOpen && 'Dashboard'}
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink 
                    onClick={() => toggleDropdown('Profile')}
                    className={selectedItem.startsWith('Profile') ? 'active' : 'nana' }
                  >
                    <img className='img-nav' src='/assets/icons/Profile.png' />
                    {isOpen && 'Profile'}
                    <i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Profile}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('ProfileSub1')}
                          className={selectedItem === 'ProfileSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('ProfileSub2')}
                          className={selectedItem === 'ProfileSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Messages')}
                    className={selectedItem.startsWith('Messages') ? 'active' : 'nana'}
                  >
                  <img className='img-nav' src='/assets/icons/chat.png' />
                    {isOpen && 'Messages'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Messages}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('MessagesSub1')}
                          className={selectedItem === 'MessagesSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('MessagesSub2')}
                          className={selectedItem === 'MessagesSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('FindWork')}
                    className={selectedItem.startsWith('FindWork') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/findwork.png' />
                    {isOpen && 'Find Work'}<i class="fa-solid fa-chevron-down text-end"></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.FindWork}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('FindWorkSub1')}
                          className={selectedItem === 'FindWorkSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('FindWorkSub2')}
                          className={selectedItem === 'FindWorkSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Transaction')}
                    className={selectedItem.startsWith('Transaction') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/Transaction.png' />
                    {isOpen && 'Transaction'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Transaction}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('TransactionSub1')}
                          className={selectedItem === 'TransactionSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('TransactionSub2')}
                          className={selectedItem === 'TransactionSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Quotation')}
                    className={selectedItem.startsWith('Quotation') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/Quotation.png' />
                    {isOpen && 'Quotation'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Quotation}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('QuotationSub1')}
                          className={selectedItem === 'QuotationSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('QuotationSub2')}
                          className={selectedItem === 'QuotationSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Calendar')}
                    className={selectedItem.startsWith('Calendar') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/calender.png' />
                    {isOpen && 'Calendar'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Calendar}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('CalendarSub1')}
                          className={selectedItem === 'CalendarSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('CalendarSub2')}
                          className={selectedItem === 'CalendarSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Wallet')}
                    className={selectedItem.startsWith('Wallet') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/Wallet.png' />
                    {isOpen && 'Wallet'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Wallet}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('WalletSub1')}
                          className={selectedItem === 'WalletSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('WalletSub2')}
                          className={selectedItem === 'WalletSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('ReferEarn')}
                    className={selectedItem.startsWith('ReferEarn') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/refer.png' />
                    {isOpen && 'Refer & Earn'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.ReferEarn}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('ReferEarnSub1')}
                          className={selectedItem === 'ReferEarnSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('ReferEarnSub2')}
                          className={selectedItem === 'ReferEarnSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('HelpDispute')}
                    className={selectedItem.startsWith('HelpDispute') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/help.png' />
                    {isOpen && 'Help & Dispute'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.HelpDispute}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('HelpDisputeSub1')}
                          className={  selectedItem === 'HelpDisputeSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('HelpDisputeSub2')}
                          className={selectedItem === 'HelpDisputeSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => toggleDropdown('Setting')}
                    className={selectedItem.startsWith('Setting') ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/setting.png' />
                    {isOpen && 'Setting'}<i class="fa-solid fa-chevron-down text-end text-right w-auto "></i>
                  </NavLink>
                  <Collapse isOpen={dropdowns.Setting}>
                    <Nav className="ps-4 ms-2 d-block">
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('SettingSub1')}
                          className={selectedItem === 'SettingSub1' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => handleItemClick('SettingSub2')}
                          className={selectedItem === 'SettingSub2' ? 'active-sub' : 'notactiv'}
                        >
                          Sub Item 2
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>
                <NavItem className='float'>
                  <NavLink
                    onClick={() => handleItemClick('Logout')}
                    className={selectedItem === 'Logout' ? 'active' : 'nana'}
                  >
                    <img className='img-nav' src='/assets/icons/logout.png' />
                    {isOpen && 'Logout'}
                  </NavLink>
                </NavItem>
              </Nav>
            </nav>
          </Navbar>
        </div>
        <div className="col-9 pl-dashbord">
          {selectedItem === 'Dashboard' && (
            <div>
              <h1>Dashboard</h1>
              <p>This is the Dashboard content.</p>
            </div>
          )}
          {selectedItem === 'ProfileSub1' && (
            <div>
              <p>This is the Profile Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'ProfileSub2' && (
            <div> 
              <p>This is the Profile Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'MessagesSub1' && (
            <div> 
              <p>This is the Messages Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'MessagesSub2' && (
            <div> 
              <p>This is the Messages Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'FindWorkSub1' && (
            <div> 
              <p>This is the Find Work Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'FindWorkSub2' && (
            <div> 
              <p>This is the Find Work Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'TransactionSub1' && (
            <div> 
              <p>This is the Transaction Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'TransactionSub2' && (
            <div> 
              <p>This is the Transaction Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'QuotationSub1' && (
            <div> 
              <p>This is the Quotation Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'QuotationSub2' && (
            <div> 
              <p>This is the Quotation Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'CalendarSub1' && (
            <div> 
              <p>This is the Calendar Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'CalendarSub2' && (
            <div> 
              <p>This is the Calendar Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'WalletSub1' && (
            <div> 
              <p>This is the Wallet Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'WalletSub2' && (
            <div> 
              <p>This is the Wallet Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'ReferEarnSub1' && (
            <div> 
              <p>This is the Refer & Earn Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'ReferEarnSub2' && (
            <div> 
              <p>This is the Refer & Earn Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'HelpDisputeSub1' && (
            <div> 
              <p>This is the Help & Dispute Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'HelpDisputeSub2' && (
            <div> 
              <p>This is the Help & Dispute Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'SettingSub1' && (
            <div> 
              <p>This is the Setting Sub Item 1 content.</p>
            </div>
          )}
          {selectedItem === 'SettingSub2' && (
            <div> 
              <p>This is the Setting Sub Item 2 content.</p>
            </div>
          )}
          {selectedItem === 'Logout' && (
            <div>
              <h1>Logout</h1>
              <p>This is the Logout content.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
