import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase'; // Adjust the path as needed

const PricingDisplay = () => {
    const [priceData, setPriceData] = useState(null);
    const { user } = useAuth(); // Get the authenticated user's info

    useEffect(() => {
        const fetchPricingData = async () => {
            try {
                const response = await axios.get(`https://connectmydesigner.in/pricing3d?email=${user.email}`);
                if (response.data && response.data.length > 0) {
                    setPriceData(response.data[0]); // Assuming you only want the first matching record
                }
            } catch (error) {
                console.error('Error fetching pricing data:', error);
            }
        };

        if (user?.email) {
            fetchPricingData();
        }
    }, [user]);

    return (
        <div>
            {priceData ? (
                <span className="p-1">
                    <span className='fw-semibold'></span>
                    <span>
                        ₹ {priceData.pricePerRoom || priceData.pricePerSqFeet} / {priceData.pricePerRoom ? 'Per Room' : 'Per Sq Ft'}
                    </span>
                </span>
            ) : (
                <p>..</p>
            )}
        </div>
    );
};

export default PricingDisplay;
