import React, { useState } from 'react';
import axios from 'axios';

const OfferCard = () => {
  const [cards, setCards] = useState([{
    offerName: '',
    price: '',
    revisions: '',
    views: '',
    thumbnail: null,
  }]);
  const [alertMessage, setAlertMessage] = useState('');

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newCards = [...cards];
    newCards[index][name] = value;
    setCards(newCards);
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    const newCards = [...cards];
    newCards[index].thumbnail = file;
    setCards(newCards);
  };

  const addMoreCards = () => {
    setCards([...cards, { offerName: '', price: '', revisions: '', views: '', thumbnail: null }]);
  };

  const saveOffer = async (index) => {
    const card = cards[index];
    const formData = new FormData();
    formData.append('offerName', card.offerName);
    formData.append('price', card.price);
    formData.append('revisions', card.revisions);
    formData.append('views', card.views);
    if (card.thumbnail) {
      formData.append('thumbnail', card.thumbnail);
    }

    try {
      await axios.post('http://localhost:2022/api/offers', formData);
      setAlertMessage('Offer saved successfully!');
    } catch (error) {
      setAlertMessage('Failed to save offer.');
    }
  };

  return (
    <div>
      <span className='font-18px fw-bold d-flex pt-2 px-2 justify-content-between'>
        <div className='my-auto'>My Offers :</div>
        <div>
          <button className="btn fw-bold" onClick={addMoreCards}>
            Add more <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </span>

      {cards.map((card, index) => (
        <div key={index} className="w-100 py-lg-4 px-lg-4">
          <div className='shadow d-flex flex-column flex-md-row' style={{ borderRadius: "10px" }}>
            <div className='col-md-4'>
              <label className='text-center btn border py-5 h-100 px-lg-4 rounded-l bg-gray-200' style={{ borderRadius: "10px 0 0 10px", cursor: "pointer" }}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(index, e)}
                />
                {card.thumbnail ? (
                  <img src={URL.createObjectURL(card.thumbnail)} alt="Thumbnail Preview" className="img-fluid" style={{ borderRadius: "10px", maxHeight: "200px" }} />
                ) : (
                  <div>
                    <div>
                      <i className="fa p-3 border-dotted-1px rounded-circle" aria-hidden="true">+</i>
                    </div>
                    <div className='py-4 px-1'>
                      Add attractive thumbnail image for your offer card
                    </div>
                  </div>
                )}
              </label>
            </div>
            <div className='col-md-8' style={{ borderRadius: "0 10px 10px 0" }}>
              <div className='p-4'>
                <div>
                  <h5 className='fw-bold py-2'>
                    Fill the detail to make offer card
                  </h5>
                  <div>
                    <div className='mb-3 d-lg-flex'>
                      <h6 className='mb-2 col-lg-4'>Add Your offer name</h6>
                      <input
                        className='form-control py-1'
                        name="offerName"
                        value={card.offerName}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div className='mb-3 d-lg-flex'>
                      <h6 className='mb-2 col-lg-4'>Your price for offer</h6>
                      <input
                        className='form-control py-1'
                        name="price"
                        value={card.price}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div className='mb-3 d-lg-flex'>
                      <h6 className='mb-2 col-lg-4'>Revision included in offer</h6>
                      <input
                        className='form-control py-1'
                        name="revisions"
                        value={card.revisions}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div className='mb-3 d-lg-flex'>
                      <h6 className='mb-2 col-lg-4'>Views included in offer</h6>
                      <input
                        className='form-control py-1'
                        name="views"
                        value={card.views}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div>
                      <button className='btn btn-primary float-end mb-2' onClick={() => saveOffer(index)}>
                        Save offer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {alertMessage && (
        <div className="alert alert-info mt-4" role="alert">
          {alertMessage}
        </div>
      )}
    </div>
  );
};

export default OfferCard;
