import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './DesignersCarousel.css'; // Make sure to import your CSS file

const DesignersCarousel = () => {
  const images = [
    '/assets/images/d-1.png',
    '/assets/images/d-2.png',
    '/assets/images/d-3.png',
    '/assets/images/d-4.png',
    '/assets/images/d-1.png',
    '/assets/images/d-4.png',
    '/assets/images/d-3.png',
    '/assets/images/d-2.png'
  ];

  const profiles = [
    {
      name: 'Designer Nikita',
      image: '/assets/images/d-1.png',
      description: `Nikita is an award-winning director of design. For over 18 years, she has partnered with clients such as Microsoft, Jaguar, the UK government, GSK, Jack Daniels, and more. Jenna's unique hybrid of skills—notably UI, UX, brand, and product design—has allowed her to exceed business and user expectations time and again. Her process for success is to be always user-focused, experience-driven, and design-led.`,
      skills: ['3D design', '2D design', 'Walkthrough'],
    },
    {
      name: 'Designer Alex',
      image: '/assets/images/d-2.png',
      description: `Alex is a renowned interior designer known for his innovative approach to modern spaces. With over 10 years in the industry, he has worked with major brands to bring contemporary designs to life.`,
      skills: ['Interior Design', 'Lighting Design', 'Space Planning'],
    },
    {
      name: 'Designer Sam',
      image: '/assets/images/d-3.png',
      description: `Sam has a passion for minimalist and sustainable design. Her projects focus on creating harmonious living spaces that are both functional and aesthetically pleasing.`,
      skills: ['Sustainable Design', 'Minimalism', 'Project Management'],
    },
    {
      name: 'Designer Jamie',
      image: '/assets/images/d-4.png',
      description: `Jamie is celebrated for his eclectic style and attention to detail. His work spans residential and commercial spaces, always pushing the boundaries of conventional design.`,
      skills: ['Eclectic Style', 'Detail-Oriented', 'Residential Design'],
    },
    {
      name: 'Designer Morgan',
      image: '/assets/images/d-1.png',
      description: `Morgan excels in blending classical and contemporary elements in her designs. Her portfolio includes high-profile projects for hospitality and luxury residential clients.`,
      skills: ['Classical Design', 'Contemporary Style', 'Luxury Spaces'],
    },
    {
      name: 'Designer Taylor',
      image: '/assets/images/d-2.png',
      description: `Taylor is a dynamic designer known for her use of bold colors and unique patterns. Her creative vision has transformed numerous commercial and retail spaces.`,
      skills: ['Bold Colors', 'Pattern Design', 'Retail Spaces'],
    },
    {
      name: 'Designer Jordan',
      image: '/assets/images/d-3.png',
      description: `Jordan specializes in ergonomic and user-friendly design solutions. His expertise lies in creating functional workspaces that enhance productivity and comfort.`,
      skills: ['Ergonomic Design', 'Workspaces', 'Productivity Solutions'],
    },
    {
      name: 'Designer Casey',
      image: '/assets/images/d-4.png',
      description: `Casey is an innovative designer with a focus on smart home technology integration. His designs seamlessly incorporate the latest tech for modern living.`,
      skills: ['Smart Home Tech', 'Modern Living', 'Tech Integration'],
    }
  ];

  const [activeProfileIndex, setActiveProfileIndex] = useState(0);

  // Split images into chunks of 4 for carousel items
  const chunkedImages = [];
  for (let i = 0; i < images.length; i += 4) {
    chunkedImages.push(images.slice(i, i + 4));
  }

  return (
    <section>
      <div className="container Designers my-5">
        <div className="pb-3">
          <h2 className="text-center">Best Designers at your fingertips</h2>
          <h5 className="text-center">
            "Bring Your Vision to Life with Our Expert Interior Designers.
            Experience, Creativity and Quality."
          </h5>
        </div>
        <Carousel className="w-50 mx-auto py-2">
          {chunkedImages.map((chunk, chunkIndex) => (
            <Carousel.Item key={chunkIndex}>
              <div className="d-flex">
                {chunk.map((image, idx) => (
                  <div
                    key={idx}
                    className={`col-lg-3 col-sm-6 col-6 ${chunkIndex * 4 + idx === activeProfileIndex ? 'active-image' : ''}`} // Apply active class
                    onClick={() => setActiveProfileIndex(chunkIndex * 4 + idx)} // Calculate correct index
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={image}
                      alt={`Designer ${chunkIndex * 4 + idx + 1}`}
                      className="d-block w-100"
                    />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="container my-lg-5 col-sm-12 my-1 short-profile">
        <div className="d-lg-flex d-block align-items-center">
          <div className="w-25 d-none d-lg-block   z-100">
            <img
              src={profiles[activeProfileIndex].image}
              alt={profiles[activeProfileIndex].name}
              className="img-designer"
              
            />
          </div>
          <div className="col-lg-9  col-12  bg-gray bg-gray-home ">
            <div className="pl-1 pl-lg-5">
              <h2>{profiles[activeProfileIndex].name}</h2>
              <p>
                {profiles[activeProfileIndex].description}
              </p>
              <div className="mb-3">
                {profiles[activeProfileIndex].skills.map((skill, index) => (
                  <span key={index} className="badge bg-light text-dark me-2">
                    {skill}
                  </span>
                ))}
              </div>
              <div className='d-flexs'>
              <button className="btn btn-secondary my-2">
                  Chat With {profiles[activeProfileIndex].name}
                </button>
                <button className="btn bg-yellow my-2 mx-lg-3">
                  View Portfolio
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesignersCarousel;
