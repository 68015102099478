import React from 'react';

const AiSection = () => {
  return (
    <div
      className="d-lg-flex d-block"
      style={{
        backgroundImage: "url('/assets/images/footer-bg.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="col-lg-9 text-white text-lg-start text-center" style={{ paddingLeft:'15px' }}>
        <h2 className="py-3 fw-semibold">
          If you are confused which designer to hire
          <span className="text-warning">?</span>
        </h2>
        <p style={{ fontSize: "26px" }}>
          You can find the best designer with advanced
          <span
            className="text-warning fw-bold m-2"
            style={{ fontSize: "34px" }}
          >
            AI
          </span>
        </p>
      </div>
     <div className='p-2 col-lg-3  d-flex justify-content-center align-items-center'>
     <div className=" gradient-border-wrapper-ai m-auto">
        <button className="btn w-100  border-gradient-ai bg-yellow"style={{  borderRadius: "12px" }}>
          Find a designer with AI
        </button>
      </div>
     </div>
    </div>
  );
};

export default AiSection;
