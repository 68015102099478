import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { useEffect, useState } from 'react';

// Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDI1LRWF6oY9CTvG1NmtmhM82INIyR9HdU",
//   authDomain: "cmd1-6c204.firebaseapp.com",
//   projectId: "cmd1-6c204",
//   storageBucket: "cmd1-6c204.appspot.com",
//   messagingSenderId: "599655842637",
//   appId: "1:599655842637:web:cb1f77a0b45d5247d8ed04",
//   measurementId: "G-9EKM1RXVZL"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBYP6l8qP75KLPD8hUoyOvofEDtx1_v4VE",
  authDomain: "connectmydesigner-com.firebaseapp.com",
  projectId: "connectmydesigner-com",
  storageBucket: "connectmydesigner-com.appspot.com",
  messagingSenderId: "65809328677",
  appId: "1:65809328677:web:4b1baf06f83fa1570d6dcc",
  measurementId: "G-6REL8WT8FC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Function to sign in with Google
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    await syncUserProfile(user);
  } catch (error) {
    console.error('Error signing in with Google:', error);
  }
};

// Function to sign in with email and password
export const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result; // Return the userCredential object for further processing
  } catch (error) {
    console.error('Error signing in with email and password:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

// Function to sync user profile with backend
export const syncUserProfile = async (user) => {
  try {
    const response = await fetch('https://connectmydesigner.com/api/syncUserProfile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: user.email, displayName: user.displayName, photoURL: user.photoURL })
    });
    const data = await response.json();
    console.log('User profile synced:', data);
  } catch (error) {
    console.error('Error syncing user profile:', error);
  }
};

// Custom hook for authentication state
const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, loading };
};

export { auth, provider, useAuth, db }; 
