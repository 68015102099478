
import './contact.css';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase'; // Adjust the import path as needed
import HeaderUser from "./header-user";
const ContactCard = () => {
  const [formData, setFormData] = useState({
    helpmobileNo: '',
    helpname: '',
    helpcategory: '',
    helpMessage: '',
    helpEmail: '',
  });
  const [isFormOpen, setIsFormOpen] = useState(false); // State to control form visibility
  const { user } = useAuth(); // Assuming useAuth returns an object with user information
  const modalRef = useRef(null); // Reference to the modal box

  useEffect(() => {
    if (user) {
      setFormData(prevState => ({ ...prevState, helpEmail: user.email }));
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsFormOpen(false); // Close the form if clicked outside
      }
    };

    if (isFormOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFormOpen]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the event from propagating to parent forms
    try {
      await axios.post('https://connectmydesigner.in/api/help', formData);
      alert('Help form submitted successfully');
      setFormData({
        helpmobileNo: '',
        helpname: '',
        helpcategory: '',
        helpMessage: '',
        helpEmail: user.email || '',
      });
      setIsFormOpen(false);
    } catch (error) {
      console.error('Error submitting the form', error);
      alert('Error submitting the form');
    }
  };


  const openForm = (e) => {
    e.preventDefault(); // Prevent default behavior (e.g., form submission)
    setIsFormOpen(true); // Open the form
  };

  const closeForm = () => {
    setIsFormOpen(false); // Close the form
  };

  return (
    <div >
      <HeaderUser />
      <div className="mt-5 h-10vh header-bg-dark ">

      </div>
      <div className=' container'>

        <div className='row'>
          <div>

          </div>
          <div>
            <div className=" col-lg-4 h-contact-card  px-4" >
              <div className="contact-header">
                <h2>Contact Us</h2>

              </div>
              <p className="subheading">We’re here to help! How can we help?</p>

              <form >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="helpname"
                    value={formData.helpname}
                    onChange={handleChange}
                    placeholder="Your full name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobile" className="form-label">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="mobile"
                    name="helpmobileNo"
                    value={formData.helpmobileNo}
                    onChange={handleChange}
                    placeholder="Your mobile number"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="category" className="form-label">Category</label>
                  <select
                    className="form-select"
                    id="category"
                    name="helpcategory"
                    value={formData.helpcategory}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select category</option>
                    <option value="3D Designer">3D Designer</option>
                    <option value="Walkthrough Expert">Walkthrough Expert</option>
                    <option value="2D Drawing Designer">2D Drawing Designer</option>
                    <option value="Landscape Designer">Landscape Designer</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="helpMessage"
                    value={formData.helpMessage}
                    onChange={handleChange}
                    rows="3"
                    placeholder="Please explain your problem so that our team gets back to you."
                    required
                  />
                </div>

                {/* <button type="submit" className="help-submit-btn btn">Submit</button> */}
                <button
                  type="submit"
                  className="help-submit-btn btn"
                  onClick={handleSubmit} // Directly call handleSubmit on button click
                >
                  Submit
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;