import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../firebase';

const FormComponent = () => {
  
  const { user, loading } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '', profilePhoto: null, mobileNo: '', description: '', gender: '', dob: '', address: '', city: '', qualification: '', experience: '', walkthroughLink:'', otherData: '', onlineProjects: '', jobCommitment: '', skills: [], styles: [], software: [], proof3DDesign: null, proofWalkthrough: null, proofLandscape: null,
    proof2DDrawing: null, aadhaarCardFront: null, aadhaarCardBack: null, panCardPhoto: null, collegeCertificate: null, email: ''
  });
  const [selectedGender, setSelectedGender] = useState('');
  const [imagePreviews, setImagePreviews] = useState({
    profilePhoto: null, proof3DDesign: null, proofWalkthrough: null, proofLandscape: null, proof2DDrawing: null, aadhaarCardFront: null,
    aadhaarCardBack: null, panCardPhoto: null, collegeCertificate: null
  });

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({ ...prevData, email: user.email }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      setFormData({ ...formData, [name]: file });
      setImagePreviews({ ...imagePreviews, [name]: URL.createObjectURL(file) });
    } else if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked
          ? [...formData[name], value]
          : formData[name].filter((item) => item !== value)
      });
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === 'gender') {
        setSelectedGender(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let key in formData) {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item) => data.append(key, item));
      } else {
        data.append(key, formData[key]);
      }
    }
    try {
      const response = await axios.post('https://connectmydesigner.com/api/users', data);
      if (response.data.exists) {
        alert('You have already submitted the form.');
      } else {
        alert('Form submitted successfully');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const divStyle = {
    backgroundImage: "url('/assets/images/cmdlogo.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100px',
    height: '100px', // Adjust this as needed
  };

  const renderFileInput = (label, name, width, height) => (
    <div className="form-group p-2">
      <label className='div-center'>{label}</label>
      <div className="file-input-box" style={{ width: `${width}px`, height: `${height}px` }}>
        <input type="file" name={name} className="file-input"
          onChange={handleChange} style={{ display: imagePreviews[name] ? 'none' : 'block' }} />
        {imagePreviews[name] && (
          <img src={imagePreviews[name]} alt={`${label} Preview`} width={width} height={height} onClick={() => {
            setFormData({ ...formData, [name]: null }); setImagePreviews({ ...imagePreviews, [name]: null });
          }}
            style={{ cursor: 'pointer' }} />)}
        {!imagePreviews[name] && (
          <div className="file-placeholder border-0" style={{ width: `${width}px`, height: `${height}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc' }}>
            <div>    <span className="material-symbols-outlined font-36 ">publish </span>  <span>Max size: 5GB</span>   </div> </div>
        )}
      </div>  </div>
  );

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const saveAndNext = () => nextStep();

  const renderStep = () => {
    if (loading) { return <div>Loading...</div>; }

    return (
      <div>   <div >
        <div className='  pt-2 px-2'>
          <h2 className='fw-bold'>Welcome to CMD</h2>

        </div>
        <div className=' col-10 px-2   text-secondary'>
          <p>Your application should only take a few minutes. Based on the information you provide, our screening team will determine the best path for you going forward.</p>
        </div>
      </div>
        <div className=' d-md-flex' style={{ borderBottom: "2px solid gray" }}>
          <div className='btn py-0 col-4'>
            <span className='font-20px fw-semibold'>Personal Details</span>
          </div>
          <div className='btn py-0 col-4'>
            <span className='font-20px fw-semibold'>Documentation</span>
          </div>
          <div className='btn py-0 col-4'>
            <span className='font-20px fw-semibold'>Other Details</span>
          </div>
        </div>
        <div className='container d-lg-flex'>


          <div className='col-lg-6'>
            <form className="py-4">
              {step === 1 && (
                <div>
                  <div className='d-flex col-10'>
                    <div>{renderFileInput('Profile Photo', 'profilePhoto', 180, 180)}</div>
                    <div className="form-group col-9 py-4 mx-5">
                      <label>Full Name:</label>
                      <input type="text" name="name" className="form-control" onChange={handleChange} />
                      <label className='pt-2' >Mobile No:</label>
                      <input type="number" name="mobileNo" className="form-control" onChange={handleChange} />
                      <label className='pt-2'>Gender:</label>
                      <label className={`btn  border mx-2 my-1 py-1 px-3 ${selectedGender === 'male' ? 'active btn-warning bg-yellow' : ''}`}>
                        <input type="radio" name="gender" value="male" checked={selectedGender === 'male'} onChange={handleChange} hidden /> Male
                      </label>
                      <label className={`btn  border mx-2 my-1 py-1 px-2 ${selectedGender === 'female' ? 'active btn-warning bg-yellow' : ''}`}>
                        <input type="radio" name="gender" value="female" checked={selectedGender === 'female'} onChange={handleChange} hidden /> Female
                      </label>
                      <label className={`btn  border mx-2 my-1 py-1 px-3 ${selectedGender === 'other' ? 'active btn-warning bg-yellow' : ''}`}>
                        <input type="radio" name="gender" value="other" checked={selectedGender === 'other'} onChange={handleChange} hidden /> Other
                      </label>
                    </div>
                  </div>
                  <div className='col-8'>
                    <label>Description:</label> <textarea name="description" className="form-control" onChange={handleChange} />
                    <label className='pt-3'>Date of Birth:</label> <input type="date" name="dob" className="form-control" onChange={handleChange} />
                    <label className='pt-3'>Address:</label> <input type="text" name="address" className="form-control" onChange={handleChange} />
                    <label className='pt-3'>City:</label> <input type="text" name="city" className="form-control" onChange={handleChange} />
                    <label className='pt-3'>Qualification:</label> <input type="text" name="qualification" className="form-control" onChange={handleChange} />
                    <label className='pt-3'>Experience:</label> <input type="text" name="experience" className="form-control" onChange={handleChange} />

                  </div>
                </div>
              )}
              {step === 2 && (
                <div>
                  <div className='d-flex div-center  gap-3'>
                    {renderFileInput('Aadhaar Card Front', 'aadhaarCardFront', 280, 200)}
                    {renderFileInput('Aadhaar Card Back', 'aadhaarCardBack', 280, 200)}
                  </div>
                  <div className='d-flex div-center gap-3'>
                    {renderFileInput('PAN Card Photo', 'panCardPhoto', 280, 200)}
                    {renderFileInput('College Certificate', 'collegeCertificate', 280, 200)}
                  </div>
                </div>
              )}
              {step === 3 && (
                <div>
                  <label className='pt-3'>Have you worked on online/digital projects?</label>
                  <input type="radio" name="onlineProjects" value="yes" onChange={handleChange} /> Yes
                  <input type="radio" name="onlineProjects" value="no" onChange={handleChange} /> No
                  <label className='pt-3'>Which type of job commitment do you prefer?</label>
                  <div>
                    <input type="radio" name="jobCommitment" value="fullTime" onChange={handleChange} /> Full Time (40 hours/week)
                    <input type="radio" name="jobCommitment" value="partTime" onChange={handleChange} /> Part Time (20 hours/week)
                    <input type="radio" name="jobCommitment" value="hourly" onChange={handleChange} /> Hourly (10 hours/week)
                  </div>
                  <label className='pt-3'>Skills:</label>
                  <div>
                    <div className='d-flex div-center'>
                      <div className='col-5 p-2'>

                        <input type="checkbox" name="skills" value="3D Design " onChange={handleChange} /> 3D Design
                        {formData.skills.includes('3D Design ') && (
                          <div className="form-group mt-2">
                            <label>Upload Proof for 3D Design </label>
                            <input type="file" name="proof3DDesign" className="form-control" onChange={handleChange} />
                          </div>
                        )}
                      </div>
                      <div className='col-5 p-2'>
                        <input type="checkbox" name="skills" value="Walkthrough" onChange={handleChange} /> Walkthrough
                        {formData.skills.includes('Walkthrough') && (
                          <div className="form-group mt-2">
                            <label>Upload Proof for  Walkthrough:</label>
                            <input type="file" name="proofWalkthrough" className="form-control" onChange={handleChange} />
                            <label className='pt-3'>Walkthrough Link</label> <input type="text" name="walkthroughLink" className="form-control" onChange={handleChange} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='d-flex div-center'>
                      <div className='col-5 p-2'>

                        <input type="checkbox" name="skills" value="2D Drawing" onChange={handleChange} /> 2D Drawing
                        {formData.skills.includes('2D Drawing') && (
                          <div className="form-group mt-2">
                            <label>Upload Proof for 2D Drawing:</label>
                            <input type="file" name="proof2DDrawing" className="form-control" onChange={handleChange} />
                          </div>
                        )}
                      </div>

                      <div className='col-5 p-2'>

                        <input type="checkbox" name="skills" value="Landscape" onChange={handleChange} />Landscape
                        {formData.skills.includes('Landscape') && (
                          <div className="form-group mt-2">
                            <label>Upload Proof for 2D Drawing:</label>
                            <input type="file" name="proofLandscape" className="form-control" onChange={handleChange} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <label className='pt-3'>Styles:</label>
                  <div>
                    <input type="checkbox" name="styles" value="Simple" onChange={handleChange} /> Simple
                    <input type="checkbox" name="styles" value="Modern 3D" onChange={handleChange} /> Modern 3D
                    <input type="checkbox" name="styles" value="Contemporary" onChange={handleChange} /> Contemporary
                  </div>
                  <label className='pt-3'>Software:</label>
                  <div>
                    <input type="checkbox" name="software" value="Photoshop" onChange={handleChange} /> Photoshop
                    <input type="checkbox" name="software" value="SketchUp" onChange={handleChange} /> SketchUp
                  </div>
                  <label className='pt-3'>Other Data:</label>
                  <textarea name="otherData" className="form-control" onChange={handleChange} />
                </div>
              )}
            </form>
          </div>
          <div className='col-lg-6 p-5 card div-center'>
            <div className='d-flex card shadow p-4 col-10 justify-content-center'>
              <div className='div-center'>
                <div className='border rounded-circle z-10 '  style={{
      backgroundImage: "url('/assets/images/bgimg.png')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100vh' // Adjust height as needed
    }}>
                  <img
                    className=' rounded-circle z-10 profilephoto' src={imagePreviews.profilePhoto || ''} alt='Profile Preview'
                  />
                  
                </div> 
              </div>
              <span className='div-center name'>name: </span>
              <p>dob: </p>
              <p>Qualification: </p>
              <p>Experience: </p>
              <p>description: </p>
              <p>Skills: </p>
              <p>Style: </p>
              <p>Software: </p>

            </div>
          </div>
        </div>
      </div>
    );
  }; 
  return (
    <div className='container'>
      <form>
        {renderStep()}
        <div className='row'>
          <div className='col'>
            {step > 1 && (
              <button type="button" className="btn btn-secondary" onClick={prevStep}>
                Back
              </button>
            )}
          </div>
          <div className='col'>
            {step < 3 && (
              <button type="button" className="btn btn-primary" onClick={saveAndNext}>   Next   </button>
            )}
            {step === 3 && (
              <button type="button" className="btn btn-success" onClick={handleSubmit}>
                Submit
              </button>  )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormComponent;
