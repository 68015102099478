import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';

const EditUserForm = () => {
  // const [selectedGender, setSelectedGender] = useState('');
  // const [selectedStrength, setSelectedStrength] = useState('');
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [newSoftware, setNewSoftware] = useState('');
  const [newStyle, setNewStyle] = useState('');
  // const [newLanguage, setNewLanguage] = useState('');
  const [userData, setUserData] = useState({
    name: '', mobileNo: '', description: '', gender: '', dob: '', address: '', city: '', qualification: '', experience: '', walkthroughLink: '', otherData: '', strength: '', onlineProjects: '', jobCommitment: '', skills: '', styles: '', language: '', software: '', email: '', profilePhoto: null, profilePhotoPreview: null, proof3DDesign: null, proofWalkthrough: null, proofLandscape: null, proof2DDrawing: null, aadhaarCardFront: null, aadhaarCardBack: null, panCardPhoto: null, collegeCertificate: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    profilePhoto: null, proof3DDesign: null, proofWalkthrough: null, proofLandscape: null, proof2DDrawing: null, aadhaarCardFront: null, aadhaarCardBack: null, panCardPhoto: null, collegeCertificate: null,
  });
  const { user, loading } = useAuth();
  const [formData, setFormData] = useState({
    name: '', profilePhoto: null, mobileNo: '', description: '', gender: '', strength: '', dob: '', address: '', city: '', qualification: '', experience: '', walkthroughLink: '', otherData: '', onlineProjects: '', jobCommitment: '', skills: [], styles: [], Languages: [], software: [], proof3DDesign: null, proofWalkthrough: null, proofLandscape: null,
    proof2DDrawing: null, aadhaarCardFront: null, aadhaarCardBack: null, panCardPhoto: null, collegeCertificate: null, email: ''
  });
  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const response = await axios.get(`https://connectmydesigner.com/api/users/email/${user.email}`);
          const data = response.data;
          setUserData(data);
          const newImagePreviews = {};
          for (const key in imagePreviews) {
            if (data[key]) {
              newImagePreviews[key] = `https://connectmydesigner.com/uploads/${data[key]}`;
            }
          }
          setImagePreviews(newImagePreviews);
          setSelectedSoftware(data.software || []);
          setSelectedStyles(data.styles || []);
          setSelectedLanguage(data.language || []);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };
    fetchProfile();
  }, [user]);

  const handleCheckboxChangeForStyles = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedStyles([...selectedStyles, value]);
    } else {
      setSelectedStyles(selectedStyles.filter((style) => style !== value));
    }
    setUserData({ ...userData, styles: selectedStyles });
  };

  const handleAddStyle = (e) => {
    e.preventDefault();
    if (newStyle.trim() !== '' && !selectedStyles.includes(newStyle) && selectedStyles.length < 3) {
      setSelectedStyles([...selectedStyles, newStyle]);
      setFormData({
        ...formData,
        styles: [...formData.styles, newStyle]
      });
      setNewStyle('');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      setUserData({ ...userData, [name]: file });
      setImagePreviews({ ...imagePreviews, [name]: URL.createObjectURL(file) });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleCheckboxChangeForLanguage = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedLanguage([...selectedLanguage, value]);
    } else {
      setSelectedLanguage(selectedLanguage.filter((language) => language !== value));
    }
    setUserData({ ...userData, language: selectedLanguage });
  };

  const handleAddSoftware = (e) => {
    e.preventDefault();
    if (newSoftware.trim() !== '' && !selectedSoftware.includes(newSoftware)) {
      setSelectedSoftware([...selectedSoftware, newSoftware]);
      setFormData({
        ...formData,
        software: [...formData.software, newSoftware]
      });
      setNewSoftware('');
    }
  }

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSoftware([...selectedSoftware, value]);
    } else {
      setSelectedSoftware(selectedSoftware.filter((software) => software !== value));
    }
    setUserData({ ...userData, software: selectedSoftware });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in userData) {
      formData.append(key, userData[key]);
    }
    axios.put(`https://connectmydesigner.com/api/users/${userData._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        alert('User data updated successfully');
      })
      .catch(error => {
        console.error('Error updating user data:', error);
      });
  };
  const renderFileInput = (label, name, width, height) => (
    <div className="form-group p-2">
      <label className='div-center'>{label}</label>
      <div className="file-input-box" style={{ width: `${width}px`, height: `${height}px` }}>
        <input type="file" name={name} className="file-input"
          onChange={handleChange} style={{ display: imagePreviews[name] ? 'none' : 'block' }} />
        {imagePreviews[name] && (
          <img src={imagePreviews[name]} alt={`${label} Preview`} width={width} height={height} onClick={() => {
            setUserData({ ...userData, [name]: null });
            setImagePreviews({ ...imagePreviews, [name]: null });
          }}
            style={{ cursor: 'pointer' }} />
        )}
        {!imagePreviews[name] && (
          <div className="file-placeholder border-0" style={{ width: `${width}px`, height: `${height}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc' }}>
            <div>
              <span className="material-symbols-outlined font-36">publish</span>
              <span>Max size: 5GB</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  const selectedGender = userData.gender;
  const selectedStrength = userData.strength;
  const [newLanguage, setNewLanguage] = useState('');
  const handleAddLanguage = (e) => {
    e.preventDefault();
    if (newLanguage.trim() !== '' && !selectedLanguage.includes(newLanguage)) {
      setSelectedLanguage([...selectedLanguage, newLanguage]);
      setFormData({
        ...formData,
        Languages: [...(formData.Languages || []), newLanguage] // Ensure Languages is always an array
      });
      setNewLanguage('');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className='d-flex col-10 px-5'>
          <div>{renderFileInput('Profile Photo', 'profilePhoto', 160, 160)}</div>
          <div className="form-group col-9 pt-4 mx-2">
            <label>Full Name *</label>
            <input type="text" name="name" className="form-control" value={userData.name} onChange={handleChange} />
            <label className='pt-2' >Mobile No *</label>
            <input type="number" name="mobileNo" className="form-control" value={userData.mobileNo} onChange={handleChange} />
            <label className='pt-2'>Gender *</label>
            <label className={`btn border mx-2 my-1 py-1 px-3 ${selectedGender === 'male' ? 'active btn-warning bg-yellow' : ''}`}>
              <input type="radio" name="gender" value="male" checked={selectedGender === 'male'} onChange={handleChange} hidden /> Male
            </label>
            <label className={`btn border mx-2 my-1 py-1 px-2 ${selectedGender === 'female' ? 'active btn-warning bg-yellow' : ''}`}>
              <input type="radio" name="gender" value="female" checked={selectedGender === 'female'} onChange={handleChange} hidden /> Female
            </label>
            <label className={`btn border mx-2 my-1 py-1 px-3 ${selectedGender === 'other' ? 'active btn-warning bg-yellow' : ''}`}>
              <input type="radio" name="gender" value="other" checked={selectedGender === 'other'} onChange={handleChange} hidden /> Other
            </label>
          </div>
        </div>
        <div className='col-10 ps-4 ms-4'>
          <label className='p-0 ms-1' >Description *</label>  <textarea rows="2" name="description" className="form-control ms-1" value={userData.description} onChange={handleChange} />
          <label className='pt-2 ms-1'>Address *</label>  <input type="text" name="address" className="form-control ms-1" value={userData.address} onChange={handleChange} />
          <label className='pt-2 ms-1'>Location (City) *</label>  <input type="text" name="city" className="form-control ms-1" value={userData.city} onChange={handleChange} />
          <label className='pt-2 ms-1'>Date of Birth *</label>  <input type="date" name="dob" className="form-control ms-1" value={userData.dob ? new Date(userData.dob).toISOString().split('T')[0] : ''} onChange={handleChange} />
          <label className='pt-2 ms-1'>Qualification *</label>  <input type="text" name="qualification" className="form-control ms-1" value={userData.qualification} onChange={handleChange} />
          <label className='pt-2 ms-1'>Experience *</label>  <input type="text" name="experience" className="form-control ms-1" value={userData.experience} onChange={handleChange} />
          <div className="form-group pt-2">
            <label>Other Data</label>
            <textarea rows="2" name="otherData" className="form-control" value={userData.otherData} onChange={handleChange} />
          </div>
        </div>
      </div>
      <div>
        <div className='d-flex div-center gap-3'>
          {renderFileInput('Aadhaar Card Front', 'aadhaarCardFront', 280, 200)}
          {renderFileInput('Aadhaar Card Back', 'aadhaarCardBack', 280, 200)}
        </div>
        <div className='d-flex div-center gap-3'>
          {renderFileInput('PAN Card Photo', 'panCardPhoto', 280, 200)}
          {renderFileInput('College Certificate', 'collegeCertificate', 280, 200)}
        </div>
      </div>


      <div>
        <div>1. Skills *</div>
        <div className='badge-green col-10 font-12px'>
          <i className="fa-solid fa-circle-info"></i>
          <span>( "Carefully add your skills as your profile will be listed under these categories on CMD platform. Ensure you have sufficient evidence to validate the skills you include.")</span>
        </div>
        <label className='p-1 my-2 rounded col-10 border' style={{ minHeight: "32px" }}>
          {formData.skills.join(', ')}
        </label>
        <div>
          {[
            { name: '3D Design', proof: 'proof3DDesign' },
            { name: 'Walkthrough', proof: 'proofWalkthrough', link: 'walkthroughLink' },
            { name: '2D Drawing', proof: 'proof2DDrawing' },
            { name: 'Landscape', proof: 'proofLandscape' }
          ].map((skill) => (
            <div key={skill.name} className='d-flex gap-3'>
              <div className='d-flex'>
                <div>
                  <input type="checkbox" name="skills" value={skill.name} checked={formData.skills.includes(skill.name)} onChange={handleCheckboxChange} />
                </div>
                <div className='px-2'>{skill.name}</div>
              </div>
              {formData.skills.includes(skill.name) && (
                <div className="form-group mt-1">
                  <input type="file" name={skill.proof} className="form-control p-1" onChange={handleChange} />
                  {skill.link && <input type="text" name={skill.link} placeholder={`${skill.name} Link`} className="form-control p-1 mt-1" onChange={handleChange} />}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="pt-2">
        <label className="py-2 mt-2">2. Which room do you consider your strength? * <span className='text-gray'>(Select any one)</span></label>
        <div className='gap-1 d-flex flex-wrap'>
          {['Living room', 'Master bedroom', 'Kitchen', 'Kids bedroom', 'Pooja room', 'Entertainment room', 'Parents bedrooom', 'Drawing room', 'other'].map((room) => (
            <label key={room} className={`btn border py-1 px-2 ${selectedStrength === room ? 'active bg-yellow-lite border-warning' : ''}`}>
              <input type="radio" name="strength" value={room} checked={selectedStrength === room} onChange={handleChange} hidden /> {room}
            </label>
          ))}
        </div>
      </div>
      <div>
        <div>
          <label className='py-1 mt-2'>Styles *</label>
        </div>
        <label className='p-1 rounded col-10 border' style={{ minHeight: "30px" }}>
          {selectedStyles.join(', ')}
        </label>
        <div className='gap-1 d-flex flex-wrap '>
          {['Simple', 'Contemporary', 'Indian Traditional', 'Bohemian', 'Minimalist', 'Modern Luxury', 'Industrial Rustic', 'Scandinavian', 'Indowestern', 'Modern Simplicity'].map((style, index) => (
            <label key={index} className={`btn border py-1 px-2 ${selectedStyles.includes(style) ? 'active mt-1 bg-yellow-lite border-warning' : ' mt-1'}`}>
              <input type="checkbox" name="styles" value={style} checked={selectedStyles.includes(style)} onChange={handleCheckboxChangeForStyles} disabled={selectedStyles.length === 6 && !selectedStyles.includes(style)} /> {style}
            </label>
          ))}
          <div className='d-flex'>
            <input type="text" className='new-field-input ' style={{ minWidth: "100px", maxWidth: "160px" }} value={newStyle} onChange={(e) => setNewStyle(e.target.value)} placeholder="Add new style" />
            <button className='new-field-btn bg-yellow' onClick={handleAddStyle}>Add</button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <label className='pt-3'>Software *</label>
        </div>
        <label className='p-1 my-2 rounded col-10 border' style={{ minHeight: "32px" }}>
          {selectedSoftware.join(', ')}
        </label>
        <div className='gap-1 d-flex flex-wrap'>
          {['Photoshops', 'SketchUps', '3ds max', 'Revit', 'Lumion', 'V Ray', 'Blender', 'Enscape', 'Vectorworks', 'Room Styler'].map((software) => (
            <label key={software} className={`btn border ${selectedSoftware.includes(software) ? 'active bg-yellow-lite border-warning' : ''}`}>
              <input type="checkbox" name="software" value={software} checked={selectedSoftware.includes(software)} onChange={handleCheckboxChange} /> {software}
            </label>
          ))}
          <div className='d-flex'>
            <input type="text" className='new-field-input' style={{ minWidth: "100px", maxWidth: "160px" }} value={newSoftware}
              onChange={(e) => setNewSoftware(e.target.value)} placeholder="Other Software" />
            <button className='new-field-btn' onClick={handleAddSoftware}>Add</button>
          </div>
        </div>
      </div>
      <div className=''>
        <div>
          <label className='py-1 '> 1. Language *</label>
        </div>
        <label className='p-1 rounded col-10 border' style={{ minHeight: "30px" }}>
          {selectedLanguage.join(', ')}
        </label>
        <div className='gap-1 d-flex flex-wrap parent-class'>
          {['English', 'Hindi', 'Marathi', 'Panjabi'].map((Language, index) => (
            <label key={index} className={`btn border py-1 px-2 ${selectedLanguage.includes(Language) ? 'active mt-1 bg-yellow-lite border-warning' : ' mt-1'}`}>
              <input type="checkbox" name="language" value={Language} checked={selectedLanguage.includes(Language)}onChange={handleCheckboxChangeForLanguage} hidden disabled={selectedLanguage.length === 10 && !selectedLanguage.includes(Language)} /> {Language}
            </label>
          ))}
          <div className='d-flex pt-1'>
            <input type="text" className='new-field-input p-1' style={{ minWidth: "100px", maxWidth: "160px" }} value={newLanguage} onChange={(e) => setNewLanguage(e.target.value)} placeholder="Add new Language" />
            <button className='new-field-btn bg-yellow' onClick={handleAddLanguage}>Add</button>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <button type="submit" className="btn btn-primary mt-3">Save</button>
      </div>
    </form>
  );
};

export default EditUserForm;

