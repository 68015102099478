import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase'; // Adjust the import path as needed
import HeaderUser from './header-user';
import FooterDesigner from '../designer/footer-designer.js';

const HelpForm = () => {
    const [formData, setFormData] = useState({
        helpmobileNo: '',
        helpname: '',
        helpcategory: '',
        helpMessage: '',
        helpEmail: '',
    });
    const [isFormOpen, setIsFormOpen] = useState(false); // State to control form visibility
    const { user } = useAuth(); // Assuming useAuth returns an object with user information
    const modalRef = useRef(null); // Reference to the modal box

    useEffect(() => {
        if (user) {
            setFormData(prevState => ({ ...prevState, helpEmail: user.email }));
        }
    }, [user]);



    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the event from propagating to parent forms
        try {
            await axios.post('https://connectmydesigner.in/api/help', formData);
            alert('Help form submitted successfully');
            setFormData({
                helpmobileNo: '',
                helpname: '',
                helpcategory: '',
                helpMessage: '',
                helpEmail: user.email || '',
            });
            setIsFormOpen(false);
        } catch (error) {
            console.error('Error submitting the form', error);
            alert('Error submitting the form');
        }
    };


    return (
        <div className=''>
            <HeaderUser />
            <div className='py-lg-5  py-3 bg-dark'> 
                <div className='py-lg-5 '>   </div>
   </div>
            <div className='container d-lg-flex col-lg-9  '>
               
                <div className='col-lg-4 mx-lg-3 mb-4 mt-negetiv-60'>
                    <div className=' shadow rounded help-submit-btn  p-4 '>
                        <h1>Contact Us</h1>
                        <div className='d-flex'>
                            <div className=" d-block     ">
                                <div className='d-flex gap-2'>
                                    <img className='my-auto' src="/assets/icons/gmail.png" alt="@" style={{ width: '20px', height: '20px' }} /> <p className=''> hello@connectmydesigner.com</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    <img className=' my-auto' src="/assets/icons/maps.png" alt="map" style={{ width: '20px', height: '20px' }} /> <p className=''> Swarajya building 101, opposite Dot fit Gym, near Jupiter Hospital, Prathamesh Park, Baner, Pune, Maharashtra 411045</p>

                                </div>

                                <p>
                                    <a href="https://www.facebook.com/profile.php?id=61564915407064&mibextid=ZbWKwL" className="text-info" target="_blank">
                                        <img src="/assets/icons/facebook.png" alt="Facebook" style={{ width: '20px', height: '20px' }} />
                                        <span className='mx-2 text-white border-0'>@Connect My Designer</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="https://twitter.com/CMD_2024" className="text-warning" target="_blank">
                                        <img src="/assets/icons/twitter.png" alt="Twitter" style={{ width: '24px', height: '24px' }}
                                            className="border rounded-circle p-1 bg-gray" />
                                        <span className='mx-2 text-white border-0'>@Connect My Designer</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.instagram.com/connect_my_designer?igsh=bHFyM3gzazF0Y2gw" className="text-denger" target="_blank">
                                        <img src="/assets/icons/instagram.png" alt="Instagram" style={{ width: '20px', height: '20px' }} />
                                        <span className='mx-2 text-white border-0'>@Connect My Designer</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.linkedin.com/company/connect-my-designer/" className="text-warning" target="_blank">
                                        <img src="/assets/icons/linkedin.png" alt="LinkedIn" style={{ width: '20px', height: '20px' }} />
                                        <span className='mx-2 text-white border-0'>@Connect My Designer</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.youtube.com/@Connectmydesigner" className="text-warning" target="_blank">
                                        <img src="/assets/icons/youtube.png" alt="Youtube" style={{ width: '20px', height: '20px' }} />
                                        <span className='mx-2 text-white border-0'>@Connect My Designer</span>
                                    </a>
                                </p>
                            </div>     </div>

                    </div>
                </div>


                <div className=" col-lg-6  rounded my-5 p-4 " ref={modalRef}>
                    <div className="contact-header">
                        <h2>Contact Us</h2>

                    </div>
                    <p className="subheading">We’re here to help! How can we help?</p>

                    <form >
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="helpname"
                                value={formData.helpname}
                                onChange={handleChange}
                                placeholder="Your full name"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">Mobile Number</label>
                            <input
                                type="number"
                                className="form-control"
                                id="mobile"
                                name="helpmobileNo"
                                value={formData.helpmobileNo}
                                onChange={handleChange}
                                placeholder="Your mobile number"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="category" className="form-label">Category</label>
                            <select
                                className="form-select"
                                id="category"
                                name="helpcategory"
                                value={formData.helpcategory}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select category</option>
                                <option value="3D Designer">3D Designer</option>
                                <option value="Walkthrough Expert">Walkthrough Expert</option>
                                <option value="2D Drawing Designer">2D Drawing Designer</option>
                                <option value="Landscape Designer">Landscape Designer</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea
                                className="form-control"
                                id="message"
                                name="helpMessage"
                                value={formData.helpMessage}
                                onChange={handleChange}
                                rows="3"
                                placeholder="Please explain your problem so that our team gets back to you."
                                required
                            />
                        </div>

                        {/* <button type="submit" className="help-submit-btn btn">Submit</button> */}
                        <button
                            type="submit"
                            className="help-submit-btn btn"
                            onClick={handleSubmit} // Directly call handleSubmit on button click
                        >
                            Submit
                        </button>

                    </form>
                </div>

            </div>
            <FooterDesigner />
        </div>
    );
};

export default HelpForm;
