import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { useAuth } from '../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

function PortfolioForm() {
  const [groups, setGroups] = useState([{ groupName: '', images: [null, null, null] }]);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { user, loading: authLoading } = useAuth();

  const handleFileChange = (groupIndex, imageIndex, event) => {
    const file = event.target.files[0];
    const newGroups = [...groups];
    if (file) {
      newGroups[groupIndex].images[imageIndex] = file; // Store the file itself
    }
    setGroups(newGroups);
  };

  const handleGroupNameChange = (index, event) => {
    const newGroups = [...groups];
    newGroups[index].groupName = event.target.value;
    setGroups(newGroups);
  };

  const handleAddMoreImages = (groupIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex].images.push(null); // Add a new null entry for an image
    setGroups(newGroups);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!user) {
      setAlertMessage('User not authenticated.');
      return;
    }
  
    const formData = new FormData();
    formData.append('userEmail', user.email);
  
    groups.forEach((group, groupIndex) => {
      formData.append(`groupName[${groupIndex}]`, group.groupName); // Append group name
      group.images.forEach((image, imageIndex) => {
        if (image) {
          formData.append(`images`, image); // Append the file directly (remove [] notation)
        }
      });
    });
  
    try {
      setLoading(true);
      const response = await axios.post('https://connectmydesigner.com/portfolioapi', formData, {
        
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlertMessage(response.data.message);
  
      // Reset the form after successful submission
      setGroups([{ groupName: '', images: [null, null, null] }]);
  
    } catch (error) {
      setAlertMessage('Error uploading portfolio. Please try again.');
      console.error(error.response.data); // Log the error response for debugging
    } finally {
      setLoading(false);
      // Optional: Clear the alert message after a few seconds
      setTimeout(() => {
        setAlertMessage('');
      }, 5000); // Adjust the timeout duration as needed
    }
  };
  

  if (authLoading) {
    return <div>Loading user info...</div>;
  }

  return (
    <div className="container mt-5  z-max">
      <h3>Create Portfolio Groups</h3>
      {alertMessage && <Alert variant="info">{alertMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        {groups.map((group, groupIndex) => (
          <div key={groupIndex} className="mb-5">
            <Form.Group controlId={`groupName-${groupIndex}`} className='d-flex'>
              <Form.Label className='my-auto '>Group Name</Form.Label>
              <input className=' mx-2 px-2 rounded border py-1'  type="text"  placeholder="Enter group name"     value={group.groupName}
                onChange={(e) => handleGroupNameChange(groupIndex, e)}  required      />
            </Form.Group>

            <div className='d-flex flex-wrap'>
              {group.images.map((image, imageIndex) => (
                <Col key={imageIndex} className='mx-2 '>
                  <div style={{ minWidth: '300px', height: '250px'}}>
                    {image ? (
                        
                     
                         <img
                        src={URL.createObjectURL(image)} // Use URL.createObjectURL to show preview
                        alt="Selected"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    
                    ) : (
                    
                         <Form.Control
                        filetype="image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileChange(groupIndex, imageIndex, e)}
                        style={{ height: '100%', cursor: 'pointer' }} 
                        className='cursor-pointer '
                      />
                    
                    )}
                  </div>
                </Col>
              ))}
            </div>

            <Button variant="secondary" onClick={() => handleAddMoreImages(groupIndex)} className="mt-2">   Add More Images   </Button>
          </div>
        ))}

      

        <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
        </Button>
      </Form>
    </div>
  );
}

export default PortfolioForm;
