import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useAuth } from '../firebase'; // Ensure to import the useAuth hook
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
const Pricing3DExteriorComponent = () => {
    const [step, setStep] = useState(1);
    const { user } = useAuth();
    const [formData, setFormData] = useState({
        chargeType: '',
        pricePerView: '',
        pricePerSqFeet: '',
        daysForDesign: 1,
        revisions: 1,
        views: 1,
        extraRevision: '',
        extraRevisionPrice: '',
        extraViews: '',
        extraViewsPrice: '',
        urgentDesign: '',
        urgentDesignPrice: '',
        inclusionExtras: [],
        additionalInclusions: [],
        exclusions: {}
    });
    const [newInclusion, setNewInclusion] = useState('');
    const [newExclusion, setNewExclusion] = useState({ name: '', price: '' });
    const [exclusionSuggestions, setExclusionSuggestions] = useState([]);
    const initialItems = [
        'material chart',
        // '2d Layout',
        '2d furniture Layout',
        'Source file',
        // 'color chart',
        'Night view',
        'HD render'
    ];

    const [suggestions, setSuggestions] = useState([]);
    const predefinedInclusions = [
        'Material Chart',
        '3D Floor Plans',
        'HD Renders',
        'Night View Render',
        'Day View Render',
        '360° Panorama',
        'Photorealistic Renders',
        'Real-time Renders',
        '3D Model Source File (e.g., .dwg, .3ds, .skp)',
        '2D Furniture Layout',
        'Space Planning',
        'Lighting Design',
        'HD RENDERS',
        'CAD FILE',
        'MEASUREMENT FILE',
        'PPT OF REFERENCE IMAGES',
        'REFERENCE IMAGES',
        'MOODBOARDS',
        '3D MODELS',
        'dwg File Format',
        '.dxf File Format',
        'Editable CAD Drawings',
        'AutoCAD Source Files',
        'CAD Layouts',
        'Vector-Based CAD Files',
        'CAD for Furniture & Fixtures',
        'Site Measurement Plans',
        'Dimensioned Drawings',
        'Measurement Layouts',
        'Precise Scaling',
        'Detailed Room Measurements',
        'Dimension Plans for Furniture',
        'Measurement Documentation',
        'Design Presentation in PPT',
        'Moodboard Presentation',
        'Visual Concepts Presentation',
        'Slide Deck with Reference Imagery',
        'Inspiration Slides',
        'Client Reference Images',
        'Design Ideas Presentation',
        'Material Reference Images',
        'Furniture Reference Photos',

        'Architectural Reference Imagery',
        'Lighting Fixture References',
        'Color Palette References',
        'Visual Style Guides',
        'Interior Design Moodboards',
        'Color Scheme Boards',
        'Furniture & Fixtures Moodboard',
        'Theme-Based Moodboards',
        'Material and Texture Moodboards',
        'Visual Concept Boards',
        'Style Moodboards',
        'Furniture Layout Plans',
        'Lighting Layouts',
        'Elevation Drawings',
        'Sectional Views',
        'Reflected Ceiling Plans (RCP)',
        'Architectural Visualizations',
        'Interactive 3D Tours',
        'Floor Plan Rendering',
        'Custom Object Design',
        'Rendered Perspectives',
        'Walkthrough Animation',
        'Virtual Reality (VR) Ready',
        '360° Walkthrough',
        'Interactive 3D Tours',
        'Flythrough Animation',
        'Cinematic Rendering',
        'Architectural Walkthrough',
        'Interior Walkthrough',
        'Exterior Walkthrough',
        'Time-lapse Animation',
        'Coohom walkthrough',
        'Foyer walkthrough',
        'Ai walkthrough',
        'Commercial walkthrough',
        'Day view walkthrough',
        'Night view walkthrough',

        '2D Floor Plans',
        'Furniture Layout Plans',
        'Electrical Layout',
        'Plumbing Layout',
        'Lighting Layout',
        'Sectional Drawings',
        'Elevation Drawings',
        'Construction Drawings',
        'Reflected Ceiling Plans (RCP)',
        'HVAC Layouts',
        'Permit Drawings',
        'Millwork Drawings',
        'CAD Detailing',
        'Technical Drawings',
        'Switchboard layout',
        'Looping layout',

        'Landscape Layout',
        'Garden Design',
        'Irrigation Plan',
        'Hardscape Design',
        'Softscape Design',
        'Pool Design',
        'Outdoor Lighting Design',
        'Pergola/Outdoor Structure Design',
        'Landscape Elevations',
        'Planting Plans',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    const saveAndNext = () => nextStep();


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setFormData({ ...formData, inclusionExtras: [...formData.inclusionExtras, name] });
        } else {
            setFormData({ ...formData, inclusionExtras: formData.inclusionExtras.filter((item) => item !== name) });
        }
    };
    useEffect(() => {
        if (newInclusion.length > 0) {
            const filteredSuggestions = predefinedInclusions.filter(
                item => item.toLowerCase().includes(newInclusion.toLowerCase())
            ).slice(0, 10); // Limit to 10 suggestions
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    
        if (newExclusion.name.length > 0) {
            const filteredExclusionSuggestions = predefinedInclusions.filter(
                item => item.toLowerCase().includes(newExclusion.name.toLowerCase())
            ).slice(0, 10); // Limit to 10 suggestions
            setExclusionSuggestions(filteredExclusionSuggestions);
        } else {
            setExclusionSuggestions([]);
        }
    }, [newInclusion, newExclusion.name]);



    const handleExclusionChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, exclusions: { ...formData.exclusions, [name]: value } });
    };

    const handleOtherAddonChange = (index, field, value) => {
        const updatedOtherAddons = [...formData.otherAddons];
        updatedOtherAddons[index][field] = value;
        setFormData({ ...formData, otherAddons: updatedOtherAddons });
    };

    const addOtherAddon = () => {
        setFormData({ ...formData, otherAddons: [...formData.otherAddons, { name: '', price: '' }] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = { ...formData, userEmail: user?.email, type: 'exterior' }; // Include the user's email and type as exterior
            await axios.post('https://connectmydesigner.in/pricing3dExterior', dataToSubmit);
            alert('Form submitted successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error submitting the form', error);
        }
    };
    const handleInclusionClick = (item) => {
        if (formData.inclusionExtras.includes(item)) {
            // Remove the item
            setFormData({
                ...formData,
                inclusionExtras: formData.inclusionExtras.filter((inclusion) => inclusion !== item)
            });
        } else {
            // Add the item
            setFormData({
                ...formData,
                inclusionExtras: [...formData.inclusionExtras, item]
            });
        }
    };

    const handleExclusionClick = (item) => {
        setFormData({
            ...formData,
            exclusions: {
                ...formData.exclusions,
                [item]: ''
            }
        });
    };

    const handleRemoveInclusion = (item) => {
        setFormData({ ...formData, inclusionExtras: formData.inclusionExtras.filter((inclusion) => inclusion !== item) });
    };

    const handleRemoveExclusion = (item) => {
        const updatedExclusions = { ...formData.exclusions };
        delete updatedExclusions[item];
        setFormData({ ...formData, exclusions: updatedExclusions });
    };
    const decrementValue = (name) => {
        setFormData((prev) => ({ ...prev, [name]: Math.max(0, Number(prev[name]) - 1) }));
    };

    const incrementValue = (name) => {
        setFormData((prev) => ({ ...prev, [name]: Number(prev[name]) + 1 }));
    };

    const handlePriceChange = (item, event) => {
        const { value } = event.target;
        setFormData({ ...formData, exclusions: { ...formData.exclusions, [item]: value } });
    };

    const handleAddInclusion = () => {
        if (newInclusion && !formData.inclusionExtras.includes(newInclusion) && !initialItems.includes(newInclusion)) {
            initialItems.push(newInclusion);
            setFormData({ ...formData, inclusionExtras: [...formData.inclusionExtras, newInclusion] });
            setNewInclusion('');
        }
    };

    const handleAddExclusion = () => {
        if (newExclusion.name && !Object.keys(formData.exclusions).includes(newExclusion.name) && !initialItems.includes(newExclusion.name)) {
            initialItems.push(newExclusion.name);
            setFormData({ ...formData, exclusions: { [newExclusion.name]: newExclusion.price } });
            setNewExclusion({ name: '', price: '' });
        }
    };

    const availableExclusions = initialItems.filter(item => !formData.inclusionExtras.includes(item));
    const renderStep = () => {
        return (
            <div className="container mt-5">

                <form onSubmit={handleSubmit}>
                    {step === 1 && (
                        <div >

                            <div className='Q1'>
                                <div className="form-group">
                                    <label> ●  How do you like to charge your client for 3D exterior design? *</label>
                                    <div className='badge-gray badge text-gray fw-normal font-13px'> <i class="fa-solid fa-circle-info"></i>
                                        <span>(select any one carefully because this unit will be applicable for all exterior category) </span></div>
                                    <div>
                                        <label className="px-2">
                                            <input type="radio" name="chargeType" value="perView" checked={formData.chargeType === 'perView'} onChange={(e) => { setFormData({ ...formData, chargeType: e.target.value, pricePerSqFeet: '' }); }} /> Per View
                                        </label>
                                        <label className="px-2">
                                            <input type="radio" name="chargeType" value="perSqFeet" checked={formData.chargeType === 'perSqFeet'} onChange={(e) => { setFormData({ ...formData, chargeType: e.target.value, pricePerView: '' }); }} /> Per Sq Feet
                                        </label>
                                    </div>
                                </div>
                                {formData.chargeType === 'perView' && (
                                    <div className="form-group py-2">
                                        <label> ● Please input your 3D exterior design price per view.* </label>
                                        <div className='border d-flex form-control w-30'>
                                            <span>INR:</span>
                                            <input type="number" placeholder=' Price per view' className=" input-border-bottom ms-2 w-100" name="pricePerView" value={formData.pricePerView} onChange={handleChange} />
                                        </div>
                                        {/* <input type="number" className="form-control w-50" name="pricePerView" value={formData.pricePerView} onChange={handleChange}  /> */}
                                    </div>)}
                                {formData.chargeType === 'perSqFeet' && (
                                    <div className="form-group py-2">
                                        <label className="">2. Please input your 3D exterior design price per sq feet.* </label>
                                        {/* <input type="number" className="form-control w-50" name="pricePerSqFeet" value={formData.pricePerSqFeet} onChange={handleChange}  /> */}
                                        <div className='border d-flex form-control w-30'>
                                            <span>INR:</span>
                                            <input type="number" placeholder=' Price per sq feet' className=" input-border-bottom ms-2 w-100" name="pricePerSqFeet" value={formData.pricePerSqFeet} onChange={handleChange} />
                                        </div>
                                    </div>)}
                            </div>

                            <div className="my-3">
                                <label className="pb-2">●  In the given price, INR.<strong> {formData.pricePerSqFeet}{formData.pricePerView} </strong> how many revisions and views will you provide?</label>
                                <div className='d-flex  gap-3'>
                                    <div className="d-flex  ">
                                        <span className="text-secondry p-1">Revision</span>
                                        <div className='border rounded p-1'>
                                            <button className=" border-0 bg-white py-0 text-yellow fw-bold " type="button" onClick={() => decrementValue('revisions')}><i class="fa-solid fa-minus"></i></button>
                                            <input type="number" className="w-30px my-auto text-center border-0" name="revisions" value={formData.revisions} onChange={handleChange} />
                                            <button className=" border-0 bg-white py-0 text-yellow fw-bold " type="button" onClick={() => incrementValue('revisions')}><i class="fa-solid fa-plus"></i></button>
                                        </div>

                                    </div>
                                    <div className="d-flex   ">
                                        <span className="text-secondry p-1">Views</span>
                                        <div className='border rounded p-1'>
                                            <button className=" border-0 bg-white  py-0 text-yellow fw-bold " type="button" onClick={() => decrementValue('views')}><i class="fa-solid fa-minus"></i></button>
                                            <input type="number" className=" w-30px text-center border-0" name="views" value={formData.views} onChange={handleChange} />
                                            <button className=" border-0 bg-white  py-0 text-yellow fw-bold " type="button" onClick={() => incrementValue('views')}><i class="fa-solid fa-plus"></i></button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='my-3'>
                                <label className="pb-2">●  How many days you will take for residential 3D design</label>
                                <div className='d-flex  '>
                                    <div className='border p-1 rounded'>
                                        <button className=" border-0 bg-white py-0 text-yellow fw-bold " type="button" onClick={() => decrementValue('daysForDesign')}><i class="fa-solid fa-minus"></i></button>
                                        <input type="number" className="w-30px text-center border-0" name="daysForDesign" value={formData.daysForDesign} onChange={handleChange} />
                                        <button className=" border-0 bg-white py-0 text-yellow fw-bold " type="button" onClick={() => incrementValue('daysForDesign')}><i class="fa-solid fa-plus"></i></button>
                                    </div>
                                    {formData.chargeType === 'perView' && (<span className=" px-2  my-auto">/per View</span>)}
                                    {formData.chargeType === 'perSqFeet' && (<span className=" px-2  my-auto">Days</span>)}
                                </div>
                            </div>

                           
                        </div>)}
                    {step === 2 && (
                        <div>


                            <div className="form-group">
                                <label>●  Do you charge extra in case of extra revision.*</label>
                                <div>
                                    <label className=" p-2">
                                        <input type="radio" name="extraRevision" checked={formData.extraRevision === 'yes'} value="yes" onChange={handleChange} /> Yes
                                    </label>
                                    <label className=" p-2">
                                        <input type="radio" name="extraRevision" checked={formData.extraRevision === 'no'} value="no" onChange={handleChange} /> No
                                    </label>
                                </div>
                                {formData.extraRevision === 'yes' && (
                                    <div className='border d-flex form-control w-30'>
                                        <span>INR:</span>
                                        <input type="number" placeholder=' Price per revision' className=" input-border-bottom ms-2 w-100" name="extraRevisionPrice" value={formData.extraRevisionPrice} onChange={handleChange} />
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <label className=" py-2">●  Do you charge extra in case of extra views?*</label>
                                <div>
                                    <label className=" p-2">
                                        <input type="radio" name="extraViews" checked={formData.extraViews === 'yes'} value="yes" onChange={handleChange} /> Yes
                                    </label>
                                    <label className=" p-2">
                                        <input type="radio" name="extraViews" checked={formData.extraViews === 'no'} value="no" onChange={handleChange} /> No
                                    </label>
                                </div>
                                {formData.extraViews === 'yes' && (
                                    // <input type="number" className="form-control w-50" name="extraViewsPrice" value={formData.extraViewsPrice} onChange={handleChange} placeholder="Price for extra views"  />
                                    <div className='border d-flex form-control w-30'>
                                        <span>INR:</span>
                                        <input type="number" placeholder=' Price per view' className=" input-border-bottom ms-2 w-100" name="pricePerView" value={formData.pricePerView} onChange={handleChange} />
                                    </div>
                                )}
                            </div>
                        </div>)}
                    {step === 3 && (

                        <div>
                            <div className="form-group">
                                {/* <label>Please select what you will include in 3D exterior design (click to add):</label> */}
                                <label className='pb-'>●  What is included in the price of INR  {formData.pricePerView} {formData.pricePerSqFeet}  {formData.chargeType} ?</label>
                                <p className='text-muted p-0 font-13px'>(eg. 2d furniture layout, source file, hd renders , panorama views)</p>
                                <div>
                                    <div className='d-flex gap-1 flex-wrap  px-1   rounded col-9 '>
                                        {formData.inclusionExtras.map((item, index) => (
                                            <div key={index} className="d-flex border bg-gray-200 rounded   gap">
                                                <span className='px-1'>{item}</span>
                                                <button type="redio" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveInclusion(item);
                                                }} className="border-0 bg- rounded text-danger "> <i class="fa-solid fa-circle-xmark"></i>
                                                </button>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="form-group">
                                        <div className="d-flex my-2">
                                            <div className="position-relative w-25">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={newInclusion}
                                                    onChange={(e) => setNewInclusion(e.target.value)}
                                                    placeholder="Add Your inclusion"
                                                />
                                                {suggestions.length > 0 && (
                                                    <ul className="list-group position-absolute w-100 bg-white">
                                                        {suggestions.map((suggestion, index) => (
                                                            <span
                                                                key={index}
                                                                className="list-group-item list-group-item-action   bg-white "
                                                                onClick={() => {
                                                                    setNewInclusion(suggestion);
                                                                    setSuggestions([]);
                                                                }}     >
                                                                {suggestion}
                                                            </span>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <button type="button" onClick={handleAddInclusion} className="btn bg-yellow-lite ml-2">
                                                Add
                                            </button>
                                        </div>
                                    </div>

                                    {/* {initialItems.map((item) => (
                                        <button
                                            type="button" key={item} onClick={() => handleInclusionClick(item)} className={`btn border me-2 my-1 p-1 ${formData.inclusionExtras.includes(item) ? 'border-warning text-dark bg-yellow-200' : 'border-lite text-dark'}`}
                                        >
                                            {item}  <i className={`fa-regular mx-1 ${formData.inclusionExtras.includes(item) ? 'fa-circle-xmark' : 'fa-circle-check'}`}></i>
                                        </button>
                                    ))} */}
                                </div>
                            </div>




                            <div className="form-group pb-lg- pt-4">

                                {/* <label> 8.Add what you will exclude from the design .</label> */}
                                <label className='pb-'>●  What is excluded in the price of INR  {formData.pricePerView} {formData.pricePerSqFeet}  {formData.chargeType} ?</label>

                                <p className='text-muted p-0 font-13px'>(eg. 2d furniture layout, source file, hd renders , panorama views)</p>
                                {/* <div> */}

                                {/* {availableExclusions.map((item) => (
                                        <button
                                            type="button"
                                            key={item}
                                            onClick={() => handleExclusionClick(item)}
                                            disabled={Object.keys(formData.exclusions).includes(item)}
                                            className={`btn border m-1 ${Object.keys(formData.exclusions).includes(item) ? 'border-warning text-dark bg-yellow-200' : 'border-lite text-dark'}`}
                                        >
                                            {item}<i class="fa-regular mx-1 fa-circle-check"></i>
                                        </button>
                                    ))} */}
                                {/* </div> */}
                            </div>
                            <div className='d-flex gap-2 flex-wrap '>
                                {Object.entries(formData.exclusions).map(([item, price], index) => (
                                    <div key={index} className="d-flex border rounded bg-gray-200 align-items-center">
                                        {/* <span className='px-3  '>{item} -</span> */}
                                        {/* {formData.chargeType === 'perSqFeet' && (
                                            <select className="form-control w-25 ml-2">
                                                <option value="">Select </option>
                                                <option value="Fixed">Fixed</option>
                                                <option value="perSqFeet">Per Sq. Feet</option>
                                            </select>
                                        )} */}
                                        <span className='pe-3 ps-1  '>{item} -</span> <p className='my-auto'>{price}</p>
                                        {/* <input type="number" className="form-control w-25 m-1" value={price} onChange={(e) => handlePriceChange(item, e)} placeholder="Price" /> */}
                                        <button type="redio" onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveExclusion(item);
                                        }} className="border-0 bg- rounded text-danger "> <i class="fa-solid fa-circle-xmark"></i>
                                        </button>
                                    </div>

                                ))}
                            </div>
                            <div className="form-group d-flex gap-2 py-lg-2">
                                <div className="  ">
                                    <div className="position-relative ">
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            value={newExclusion.name}
                                            onChange={(e) => setNewExclusion({ ...newExclusion, name: e.target.value })}
                                            placeholder="Exclusion Name"
                                        />
                                        {exclusionSuggestions.length > 0 && (
                                            <ul className="list-group position-absolute w-100 bg-white">
                                                {exclusionSuggestions.map((suggestion, index) => (
                                                    <span
                                                        key={index}
                                                        className="list-group-item list-group-item-action bg-white z-100"
                                                        onClick={() => {
                                                            setNewExclusion({ ...newExclusion, name: suggestion });
                                                            setExclusionSuggestions([]);
                                                        }}
                                                    >
                                                        {suggestion}
                                                    </span>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                {formData.chargeType === 'perSqFeet' && (<select required className="form-control w-25 ml-2" value={newExclusion.chargeType} onChange={(e) => setNewExclusion({ ...newExclusion, chargeType: e.target.value })}>
                                    <option >Select Charge Type</option>
                                    <option value="Fixed">Fixed Ammount</option>
                                    <option value="perSqFeet">Per Sq. Feet</option>
                                </select>)}
                                <input type="number" required className="form-control w-25 ml-2" value={newExclusion.price} onChange={(e) => setNewExclusion({ ...newExclusion, price: e.target.value })} placeholder="Price" />


                                <button
                                    type="button"
                                    onClick={() => {
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            exclusions: { ...prevState.exclusions, [newExclusion.name]: newExclusion.price },
                                        })); setNewExclusion({ name: '', price: '' });
                                    }} className="btn bg-yellow-lite ml-2"   > Add
                                </button>
                            </div>
                        </div>
                    )
                    }
                </form >
                <style jsx>{`  select {    appearance: none;
                       background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
                      background-repeat: no-repeat;   background-position: right 0.7em top 50%;
                      background-size: 0.65em auto;    padding-right: 1.4em;   }   `}</style>
            </div >
        );
    }
    return (
        <Container>
            <Form>
                {renderStep()}
                <Row>
                    <Col className='p-4'>
                        {step > 1 && (
                            <button type='button' className='px-5 btn border-gradient-btn-back' onClick={(e) => prevStep(e)}>
                                Back
                            </button>
                        )}
                    </Col>
                    <Col className='p-4'>
                        {step < 3 && (
                            <Button className='px-5 btn border-gradient-btn-next' onClick={saveAndNext}>
                                Next
                            </Button>
                        )}
                        {step === 3 && (
                            <button type='submit' className='px-4 shadow btn bg-yellow' border-0 onClick={handleSubmit}>
                                Submit
                            </button>
                        )}
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default Pricing3DExteriorComponent;
