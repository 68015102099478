import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AuthProvider, useAuth } from './AuthContext';
import axios from 'axios';


import UserList from './user/UserList';
import Dos from './user/dos';
import MyFaq from './user/MyFaq';
import TermsAndConditions from './user/cmd-tnc';
import PrivacyPolicy from './user/PrivacyPolicy';
import DesignCatagoryCarousel from './designer/DesignCatagoryCarousel';
import BlogSidebar from './user/blogs';
import ChatApp from './user/ChatApp';

import Ticker from './user/Ticker';
import HelpNSupport from './user/helpnsupport';
import ContactCard from './user/contactus';
import Benefits from './user/Benefits.js';
import PaymentGuidelines from './user/PaymentGuidelines';
import Dont from './user/dont';
import StepData from './user/StepData';
import HowToStart from './user/how-to-start';
import Files from './user/files';
import DashboardUser from './user/home';
import FooterUser from './user/footer-user';
import ProCard from './user/proCard';
import HeaderUser from './user/header-user';
import ProfileUser from './user/profile-user';
import Profiles from './user/profile';
import SuccessStory from './user/SuccessStory';
import Why from './user/SkillsComponent';
import About from './user/about';
import CompleteProfile from './user/completep';
import TabComponent from './designerDashbord/TabComponent';
import FAQ from './user/faq';
import DesignersCarousel from './user/DesignersCarousel';
import AiSection from './user/aisec';
import ProfileForm from './user/ProfileForm';
import StepForm from './user/StepForm';
import TotalDataComponent from './user/totalApplied';
import HelpForm from './user/help';
import PricingDisplay from './user/myPricingDisplay';
import M from './user/Apply-designer';
import JoinCommunity from './user/JoinCommunity';
import CommunityGuidelines from './user/CommunityGuidelines.js';
import OfferCard from './user/offerCards';


import PortfolioFormVid from './user/PortfolioFormVid';
import MyPortfolioGroupsVid from './user/MyPortfolioGroupsVid';
import MyAllPortfolioGroupsVid from './user/MyAllPortfolioGroupsVid';
import ManagePortfolioVid from './user/ManagePortfolioVid';

import PortfolioForm from './user/ProjectPortfolioForm.js';
import ManagePortfolio from './user/portfolioManage';
import PortfolioGroups from './user/PortfolioGroups';
import MyPortfolio from './user/MyPortfolio';
import MyAllPortfolioGroups from './user/myAllPortfolios';




import DesignerPricingTab from './admin/DesignerPricingTab';
import DashboardSuperSidebar from './admin/superSidebar';


import Login from './auth/login';
import Register from './auth/register';


import EditPricing3DComponent from './designer/Residential3DEdit';
import DashboardDesigner from './designer/dashbord-designer';
import MyData from './designer/MyResidentialData';
import CommercialData from './designer/myCommercialData';
import MyExteriorData from './designer/myExteriorData';
import ApplyForm from './designer/myprofileform';
import FooterDesigner from './designer/footer-designer';
import Header from './designer/header';
import HomeDesigner from './designer/home-designer';
import Commercial3DEditForm from './designer/Commercial3DEdit';
import Residential3DEditForm from './designer/Residential3DEdit';
import Exterior3DEdit from './designer/Exterior3DEdit';


import DashboardSidebar from './designerDashbord/DashboardSidebar';
import ViewExterior3d from './designerDashbord/ViewExterior3d';
import ViewPricingData from './designerDashbord/ViewPricingData';
import Commercial3DComponent from './designerDashbord/Commercial3d';
import Pricing3DComponent from './designerDashbord/residential3d';
import ViewCommercial3D from './designerDashbord/ViewCommercial3D';
import Exterior3d from './designerDashbord/Exterior3D';
import FormTable from './designerDashbord/mainFormTable';
import Design3dForm from './designerDashbord/Design3dForm';

import ThreeDPortfolioForm from './3dPortfolio/3dPortfolioForm';
const NotFound = () => {
  return (
    <div>
      <h2>404 - Page Not Found</h2>
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/" /> : children;
};
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'https://connectmydesigner.com/';
function App() {
  return (
    <AuthProvider>

      <Routes>

        <Route path="/" element={<HomeDesigner />} />

        <Route path="/3dPortfolioForm" element={<ThreeDPortfolioForm />} />




        <Route path="/all" element={<UserList />} />
        <Route path="/myallportfoliovid" element={<MyAllPortfolioGroupsVid />} />
        <Route path="/manageportfoliovid" element={<ManagePortfolioVid />} />

        <Route path="/myportfoliovid" element={<MyPortfolioGroupsVid />} />
        <Route path="/portfoliovid" element={<PortfolioFormVid />} />
        <Route path="/myallportfolio" element={<MyAllPortfolioGroups />} />
        <Route path="/manage-portfolio" element={<ManagePortfolio />} />
        <Route path="/myportfolio" element={<MyPortfolio />} />
        <Route path="/chat" element={<ChatApp />} />
        <Route path="/portfolio" element={<PortfolioForm />} />
        <Route path="/portfolio-groups" element={<PortfolioGroups />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/blog" element={<BlogSidebar />} />
        <Route path="/DesignCatagoryCarousel" element={<DesignCatagoryCarousel />} />
        <Route path="/helpnsupport" element={<HelpNSupport />} />
        <Route path="/howtostart" element={<HowToStart />} />

        

        <Route path="/PaymentGuidelines" element={<PaymentGuidelines />} />

        <Route path="/user/MyFaq" element={<MyFaq />} />
        <Route path="/user/offerCards" element={<OfferCard />} />
        <Route path="/user/edit" element={<StepData />} />
        <Route path="/user/files" element={<Files />} />
        <Route path="/user/proCard" element={<ProCard />} />
        <Route path="/user/successStory" element={<SuccessStory />} />
        <Route path="/user/home" element={<DashboardUser />} />
        <Route path="/user/footer" element={<FooterUser />} />
        <Route path="/user/header" element={<HeaderUser />} />
        <Route path="/user/why" element={<Why />} />
        <Route path="/about" element={<About />} />
        <Route path="/user/profile" element={<ProfileUser />} />
        <Route path="/user/pro" element={<Profiles />} />
        <Route path="/user/completep" element={<CompleteProfile />} />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/user/designersCarousel" element={<DesignersCarousel />} />
        <Route path="/user/aisec" element={<AiSection />} />
        <Route path="/user/form" element={<ProfileForm />} />
        <Route path="/user/StepForm" element={<StepForm />} />
        <Route path="/apply-as-designer" element={<M />} />
        <Route path="/user/JoinCommunity" element={<JoinCommunity />} />
        <Route path="/user/CommunityGuidelines" element={<CommunityGuidelines />} />
        <Route path="/user/dos" element={<Dos />} />
        <Route path="/user/dont" element={<Dont />} />
        <Route path="/user/help" element={<HelpForm />} />

        <Route path="/user/myPricingDisplay" element={<PricingDisplay />} />
        <Route path="/user/contactus" element={<ContactCard />} />
        <Route path="/user/Ticker" element={<Ticker />} />



        <Route path="/admin/superSidebar" element={<DashboardSuperSidebar />} />
        <Route path="/admin/DesignerPricingTab" element={<DesignerPricingTab />} />


        <Route path="/cmd-t&c" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/auth/login" element={   <ProtectedRoute>   <Login />    </ProtectedRoute>   } />
        <Route path="/auth/register" element={<Register />} />


        <Route path="/designer/dashboard" element={<DashboardDesigner />} />
        <Route path="/designer/footer" element={<FooterDesigner />} />
        <Route path="/designer/header" element={<Header />} />
        <Route path="/designer/total" element={<TotalDataComponent />} />
        <Route path="/designer/Residential3DEdit" element={<EditPricing3DComponent />} />
        <Route path="/designer/tab" element={<TabComponent />} />
        <Route path="/designer/myprofileform" element={<ApplyForm />} />
        <Route path="/designer/MyData" element={<MyData />} />
        <Route path="/designer/CommercialData" element={<CommercialData />} />
        <Route path="/designer/MyExteriorData" element={<MyExteriorData />} />
        <Route path="/designerDashbord/DashboardSidebar" element={<DashboardSidebar />} />
        <Route path="/designer/residential" element={<Pricing3DComponent />} />
        <Route path="/designer/ViewExt" element={<ViewExterior3d />} />
        <Route path="/designer/view" element={<ViewPricingData />} />
        <Route path="/designer/commercial" element={<Commercial3DComponent />} />
        <Route path="/designer/commercialEdit" element={<Commercial3DEditForm />} />
        <Route path="/designer/ResidentialEdit" element={<Residential3DEditForm />} />
        <Route path="/designer/Exterior3DEdit" element={<Exterior3DEdit />} />
        <Route path="/designer/viewC" element={<ViewCommercial3D />} />
        <Route path="/designer/3dForm" element={<Design3dForm />} />
        <Route path="/designer/formTable" element={<FormTable />} />
        <Route path="/designer/exterior" element={<Exterior3d />} />


        <Route path="*" element={<NotFound />} />
      </Routes>

    </AuthProvider>
  );
}

export default App;
