import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase';

const GoogleLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoogleLogin = async () => {
    if (isLoading) return; // Prevent multiple clicks

    setIsLoading(true); // Disable the button after the first click

    try {
      await signInWithPopup(auth, provider);

      // Redirect to the original page or default to /designer/home
      const redirectPath = location.state?.from?.pathname || '/';
      navigate(redirectPath);
    } catch (error) {
      console.error("Error during Google login:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false); // Re-enable the button after the operation completes
    }
  };

  return (
    <div className={`btn border text-white bg-558AED ps-1 pe-3 ${isLoading ? 'disabled' : ''}`} onClick={handleGoogleLogin}>
      <img
        width="39"
        height="39"
        className='ms-1 me-2 rounded bg-white border p-1'
        src="/assets/images/Google.png"
        alt="G"
      />
      {isLoading ? 'Signing in...' : 'Sign Up with Google'}
    </div>
  );
};

export default GoogleLoginButton;

