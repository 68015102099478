import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Files = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch('https://connectmydesigner.com/profiles');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched profiles:', data); // Log fetched data
        setProfiles(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mt-4">
      <h2>Files</h2>
      {profiles.map(profile => (
        <div key={profile._id} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{profile.username}</h5>
            <p className="card-text">{profile.description}</p>
            <div className="card-text">
              <strong>Aadhar:</strong>
              {profile.Aadhar ? (
                <img src={profile.Aadhar} alt="Aadhar" className="img-fluid" />
              ) : (
                <p>No Aadhar uploaded</p>
              )}
            </div>
            <div className="card-text">
              <strong>Pan:</strong>
              {profile.Pan ? (
                <img src={profile.Pan} alt="Pan" className="img-fluid" />
              ) : (
                <p>No Pan uploaded</p>
              )}
            </div>
            <div className="card-text">
              <strong>Certificate:</strong>
              {profile.Certificate ? (
                <img src={profile.Certificate} alt="Certificate" className="img-fluid" />
              ) : (
                <p>No Certificate uploaded</p>
              )}
            </div>
            <div className="card-text">
              <strong>Work Example:</strong>
              {profile.Workexample ? (
                <img src={profile.Workexample} alt="Work Example" className="img-fluid" />
              ) : (
                <p>No Work Example uploaded</p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Files;
