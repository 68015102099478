import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DesignerList from './DesignerList';
import ApprovedDesignerList from './ApprovedDesignerList';
import PendingDesignerList from './PendingDesignerList';
import OnHoldDesignerList from './OnHoldDesignerList';
import RejectedDesignerList from './RejectedDesignerList';
import HelpList from './helplist';  // Changed import to lowercase

const Dashboard = () => {
    const [totalDesigner, setTotalDesigner] = useState(0);
    const [approvedDesigner, setApprovedDesigner] = useState(0);
    const [pendingDesigner, setPendingDesigner] = useState(0);
    const [onHoldDesigner, setOnHoldDesigner] = useState(0);
    const [rejectedDesigner, setRejectedDesigner] = useState(0);
    const [showDesignerList, setShowDesignerList] = useState(false);
    const [currentList, setCurrentList] = useState('');
    const [history, setHistory] = useState([]);
    const [helpRequests, setHelpRequests] = useState([]);

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const totalResponse = await axios.get('https://connectmydesigner.com/api/users/count');
                setTotalDesigner(totalResponse.data.count);

                const approvedResponse = await axios.get('https://connectmydesigner.com/api/users/count/Approved');
                setApprovedDesigner(approvedResponse.data.count);

                const pendingResponse = await axios.get('https://connectmydesigner.com/api/users/count/Pending');
                setPendingDesigner(pendingResponse.data.count);

                const onHoldResponse = await axios.get('https://connectmydesigner.com/api/users/count/OnHold');
                setOnHoldDesigner(onHoldResponse.data.count);

                const rejectedResponse = await axios.get('https://connectmydesigner.com/api/users/count/Rejected');
                setRejectedDesigner(rejectedResponse.data.count);
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };

        fetchCounts();
    }, []);

    const handleBoxClick = (list) => {
        setHistory([...history, { showDesignerList, currentList }]);
        setCurrentList(list);
        setShowDesignerList(true);

        if (list === 'HelpRequest') {
            fetchHelpRequests();
        }
    };
    const fetchHelpRequests = async () => {
        try {
            const response = await axios.get('https://connectmydesigner.in/api/help');
            setHelpRequests(response.data);
        } catch (error) {
            console.error('Error fetching help requests:', error);
        }
    };

    const handleBackClick = () => {
        if (history.length > 0) {
            const previousState = history[history.length - 1];
            setHistory(history.slice(0, -1));
            setShowDesignerList(previousState.showDesignerList);
            setCurrentList(previousState.currentList);
        }
    };

    const renderDesignerList = () => {
        switch (currentList) {
            case 'Total':
                return <DesignerList goBack={handleBackClick} />;
            case 'Approved':
                return <ApprovedDesignerList goBack={handleBackClick} />;
            case 'Pending':
                return <PendingDesignerList goBack={handleBackClick} />;
            case 'OnHold':
                return <OnHoldDesignerList goBack={handleBackClick} />;
            case 'Rejected':
                return <RejectedDesignerList goBack={handleBackClick} />;
           
                case 'HelpRequest':
                    return <HelpList requests={helpRequests} goBack={handleBackClick} />;
                default:
                return null;
        }
    };
    

    return (
        <div>
            <div className='py-0 '>
                <h3 className='fw-bold d-flex'> <img src='/assets/icons/dashboard.png' style={{ height:"50px" }}/> <h3 className='py-2'>Dashboard</h3></h3>
            </div>

            {showDesignerList ? (
                <div className=''>
                    
                    {renderDesignerList()}
                </div>
            ) : (
                <div className='p-2 d-flex flex-wrap  gap-3  '>
                    <div className='border rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('Total')}>
                        <div className='col-9'>
                            <div>
                                <h4>Total Designers</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'>{totalDesigner}</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </div>
                    <div className='border rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('Approved')}>
                        <div className='col-9'>
                            <div>
                                <h4>Approved Designers</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'>{approvedDesigner}</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </div>
                    <div className='border rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('Pending')}>
                        <div className='col-9'>
                            <div>
                                <h4>Pending Designers</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'>{pendingDesigner}</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </div>
                    <div className='border rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('OnHold')}>
                        <div className='col-9'>
                            <div>
                                <h4>On Hold Designers</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'>{onHoldDesigner}</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </div>
                    <div className='border rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('Rejected')}>
                        <div className='col-9'>
                            <div>
                                <h4>Rejected Designers</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'>{rejectedDesigner}</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </div>
                    <button className='border btn rounded shadow-sm p-4 d-flex w-32' onClick={() => handleBoxClick('HelpRequest')}  >
                        <div className='col-9'>
                            <div>
                                <h4>Help Request</h4>
                            </div>
                            <div>
                                <h4 className='text-gray'> view all</h4>
                            </div>
                        </div>
                        <div className='col-3 my-auto'>
                            <img className='img-nav' src='/assets/icons/Dashboard-all.png' alt='icon' />
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
