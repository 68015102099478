import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeDesigner.css';
import FooterDesigner from './footer-designer';
// import HeaderUser from "../user/header-user";
import Header from './header';

import { Accordion, Container, Row, Col, Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import TypingEffect from './TypingEffect';
import { Link, useNavigate } from "react-router-dom";
const HomeDesigner = () => {
  const [fontSizes, setFontSizes] = useState({});
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeKey, setActiveKey] = useState(null);


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const calculateFontSize = (base, scale) => {
        return `${(base * (width / 1440) * scale).toFixed(2)}rem`;
      };

      setFontSizes({
        h1: calculateFontSize(2.5, 1.5),
        p: calculateFontSize(2, 0.8),

      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMute = () => {
    const video = videoRef.current;

    if (video) {
      video.muted = !video.muted;
    }
  };
  const handlePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const scrollToWatch = () => {
    const element = document.getElementById("Watch");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4, // Default value for desktop
    slidesToScroll: 0.5,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint (adjust as needed)
        settings: {
          speed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,

          arrows: false,
        },
      },
    ],
  };

  const Started = {
    dots: false,
    infinite: true,
    slidesToShow: 4, // Default value for desktop
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint (adjust as needed)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };


  return (
    <div>
      <Header />
      <div className="container-fluid  text-light p-lg-5 py-4 px-3 "
        style={{
          backgroundImage: `url('/assets/images/bg-designer-home.png')`,

          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}  >
        <div className="d-lg-flex py-lg-100px align-items-center con justify-content-around">
          <div className="col-lg-7 ps-lg-0 my-auto">
            <div className='px-lg-0'>
              <h1 className="text-lg-start   fw-bold text-center line-height-lg py-3 " >
                <span className="d-block d-lg-inline display-4 fw-bold">India's<span className='gradient-text'> #1 </span> Marketplace  </span>
                <span className="d-block pt-2 display-4 fw-semibold  ">
                  For <TypingEffect />
                </span>
              </h1>

              <h4 className="d-lg-block  d-none pb-3 text-lg-start " >
                "Elevate Your Design Career with CMD - Join Now"
              </h4>
              <span className=" d-lg-none d-block pb-3  text-center font-14px" >
                "Elevate Your Design Career with CMD - Join Now"
              </span>
            </div>
            <div className="my-md-4 mb-lg-0 mb-2 gap-md-4 gap-1 col-lg-8  d-flex px-lg-0  px-3 ">
              <Button as={Link} to="/apply-as-designer" className="rounded    w-50 px-0 py-lg-2  Display-6 shadow border-gradient-btn-2 font-14px-mob mr-lg-2">Apply as a designer</Button>
              <button onClick={scrollToWatch} className="rounded  Display-6 py-lg-2 font-14px-mob py-2  w-50 bg-transprent border-2-btn bg-yellow-lite-btn border-warning px-lg-5">
                <i className="fa-solid  fa-circle-play"></i> Watch
              </button>
            </div>
          </div>
          <div className="col-lg-5 px-4 pt-5 mt-4  text-center">
            <div className="d-lg-flex d-none justify-content-around">

              <img className="w-100 " src="/assets/images/dright1.png" alt="3D Designer" />
              <div className=" hereo-r1-vid  rounded">

                <video className="p-0 m-0 r1-vid rounded" ref={videoRef} src="/assets/videos/vr-test1.mp4" alt="loading..." autoPlay muted loop style={{ width: "100%", height: "100%" }} />


              </div>
            </div>
            <div className="d-flex d-lg-none justify-content-around">
              <img className="w-100" src="/assets/images/dr1-mobile.png" alt="3D Designer" />
            </div>

          </div>




        </div>
      </div>
      <div className="d-lg-flex container py-lg-5 " id='Watch'
        style={{
          backgroundImage: "url('/assets/images/HowtoRegister.png')", backgroundSize: "50%", backgroundPosition: "right", backgroundRepeat: "no-repeat", width: "100%", height: "50%",
        }}   >

        <div className="col-lg-6  p-lg-5 p-3  text-center ">

          <video className="object-fit-vid rounded-5 p-1 hero-section-gradient" ref={videoRef} src="/assets/videos/howto.mp4" alt="loding..." autoPlay muted style={{ width: "100%", height: "100%" }} />
          {/* <button
            onClick={handlePlayPause} className="play-button "   >
            <img src={isPlaying ? "/assets/images/pause.png" : "/assets/images/play.png"
            } alt={isPlaying ? "Pause" : "Play"} />
          </button> */}

        </div>
        <div className="col-lg-6 pt-lg-5 pb-lg-5 pb-3 ps-lg-4 my-auto">
          <span className="text-lg-start text-center display-4 fw-600  " >
            <span className="d-block d-lg-inline">Manage Your Design  </span>
            <span className="d-block">
              Career with <span className="gradient-text" > CMD</span>
            </span>
          </span>

          <p className=" py-lg-3 py-0 px-lg-0 px-2 text-lg-start text-center font-14px-mob text-gray-400" >
            Streamline your projects, showcase your portfolio, and connect with clients effortlessly.
          </p>
          <div className="mb-lg-4 mt-lg-4 mb-2 d-lg-flex  d-block  ">
            <div className="  "> <Button as={Link} to="/apply-as-designer" className="rounded   col-lg-12 col-6 mx-auto   Display-6 shadow border-gradient-btn-how mr-lg-2">Apply as a designer</Button></div>


          </div>
        </div>

      </div>
      <div>

      </div>


      <div className=' bg-Benifits '
        style={{
          backgroundImage: "url('/assets/images/bg-Benifits-small.png')", backgroundSize: "cover", backgroundPosition: "center", width: "100%", height: "50%",
        }}  >
        <div className='container px-lg-5  py-lg-4 py-5 text-center  '>
          <div className='pb-4'><span className="gradient-text display-5   " > Benefits of joining Connect My Designer   </span></div>
          <div className='col-12   d-lg-flex gap-3 py-lg-3 justify-content-start'>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits1.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>Earn Independently & Handsomely</span> </div>
            </div>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits2.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>Skill Enhancement Courses</span> </div>
            </div>
          </div>
          <div className='col-12 d-lg-flex gap-3 py-lg-3 justify-content-end'>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits3.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>Top Designer Community</span> </div>
            </div>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits4.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>More Projects, More Earnings</span> </div>
            </div>
          </div>
          <div className='col-12 d-lg-flex gap-3 py-lg-3 justify-content-center'>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits5.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>Build Your Personal Brand</span> </div>
            </div>
            <div className='d-flex w-33 border rounded box-shadow-Benifits my-lg-0 my-2'>
              <div>
                <img className='width-50px pe-3' src='/assets/images/Benifits6.gif' alt='p' />
              </div>
              <div className='d-flex my-auto font-18px'>
                <span>No Payment Chasing with clients</span> </div>
            </div>
          </div>


        </div>
      </div>







      <div className='container p-lg-3 p-1 text-center '>
        <span><span className="gradient-text display-5  " > Your Design Journey Begins Here   </span></span>
        <p className=" py-md-3 py-0 px-lg-0 px-2  text-center font-14px-mob text-gray-400" >
          Unlock your potential, showcase your talents, and
          <br />
          start earning in diverse categories on CMD.
        </p>
      </div>

      <div className=' w-lg-100 mx-auto  '>
        <Slider {...settings} className='py-0 my-0  '>

          <div className=' px-3 px-lg-0' >
            <div className='border px-2 py-2 bg-journey-Commercial rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card '>
              <h5 className="fw-bold shadow py-1 text-white"> Commercial Design </h5>
              <div>
                <img className='h-100-w-100   rounded' src="/assets/images/Commercial.jpeg" />
              </div>
            </div>
          </div>
          <div className=' px-3 px-lg-0' >
            <div className='border px-2 py-2 bg-journey-Exterior rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card '>
              <h5 className="fw-bold shadow py-1 text-white"> Exterior 3D Design </h5>
              <div>
                <img className='h-100-w-100 rounded' src="/assets/images/Exterior.png" />
              </div>
            </div>
          </div>
          <div className=' px-3 px-lg-0' >
            <div className='border px-2 py-2 bg-journey-Landscape rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card '>
              <h5 className="fw-bold shadow py-1 text-white"> Landscape Design </h5>
              <div>
                <img className='h-100-w-100 rounded' src="/assets/images/Landscape.png" />
              </div>
            </div>
          </div>
          <div className=' px-3 px-lg-0' >
            <div className='border px-2 py-2 bg-journey-Interior rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card '>
              <h5 className="fw-bold shadow py-1 text-white"> 3D  Design</h5>
              <div>
                <img className='h-100-w-100 rounded' src="/assets/images/Interior.png" />
              </div>
            </div>
          </div>
          <div className=' px-3 px-lg-0' >
            <div className='border px-2 py-2 bg-journey-Walkthroughs objet-fit-cover rounded shadow text-center mx-lg-3 mx-0 mb-lg-5 mb-2 auto-carousel-card '>
              <h5 className="fw-bold shadow py-1 text-white"> VR Walkthroughs </h5>
              <div>
                <video className="p-0 m-0 objet-fit-cover border h-100-w-100 rounded " ref={videoRef} src="/assets/videos/vr-test1.mp4" alt="loading..." autoPlay muted loop />
              </div>
            </div>
          </div>
        </Slider>
      </div>


      <div className='container ps-0 '>
        <div className='pb-4 pt-5 text-center '>
          <span className="gradient-text display-5   pt-lg-0 pt-2" >Your entire work in one place at CMD  </span>
        </div>
        <div className='d-lg-flex d-none  mx-auto ps-0 px-lg-5  pe-3'>
          <div className='d-flex p-lg-5 col-lg-4  ps-0 '>
            <div className='div-center'>
              <span className='my-auto ps-0 text-center font-200 gradient-text'>1</span>
            </div>
            <div className=' rounded-circle p-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw1 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-2' src='/assets/images/Benifits44.gif' alt='p' />
                </div>
                <div className='pt-0 div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Raise invoices to customers</h5></div>
              </div>
            </div>

          </div>


          <div className='d-flex p-lg-5 col-lg-4 justify-content-between'>
            <div className='div-center '>
              <span className='my-auto font-200 gradient-text'>2</span>
            </div>
            <div className=' rounded-circle p-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw2 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-2' src='/assets/images/Benifits7.gif' alt='p' />
                </div>
                <div className='pt-2 div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Custom offer cards</h5></div>
              </div>
            </div>

          </div>
          <div className='d-flex p-lg-5 col-lg-4  '>
            <div className='div-center'>
              <span className='my-auto font-200 gradient-text'>3</span>
            </div>
            <div className=' rounded-circle p-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw3 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-2' src='/assets/images/Benifits11.gif' alt='p' />
                </div>
                <div className='pt-2 div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Manage multiple clients </h5></div>
              </div>
            </div>

          </div>




        </div>
        <div className='d-lg-none   mx-auto justify-content-center px-lg-1 px-sm-5 px-3  '>

          <div className='d-flex justify-content-center px-sm-5 p-lg-5 col-lg-4 my-lg-0 my-4 '>
            <div className='div-center'>
              <span className='my-auto font-200 gradient-text'>1</span>
            </div>
            <div className=' rounded-circle p-4 p-lg-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw4 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-4' src='/assets/images/Benifits44.gif' alt='p' />
                </div>
                <div className='pt- div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Raise invoices to customers </h5>
                </div>

              </div>
            </div>

          </div>

          <div className='d-flex p-lg-5 justify-content-center px-sm-5 col-lg-4 my-lg-0 my-4 '>
            <div className='div-center'>
              <span className='my-auto font-200 gradient-text'>2</span>
            </div>
            <div className=' rounded-circle p-4 p-lg-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw4 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-4' src='/assets/images/Benifits7.gif' alt='p' />
                </div>
                <div className='pt- div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Custom offer cards </h5>
                </div>

              </div>
            </div>

          </div>

          <div className='d-flex p-lg-5 justify-content-center px-sm-5 col-lg-4 my-lg-0 my-4   '>
            <div className='div-center'>
              <span className='my-auto font-200 gradient-text'>3</span>
            </div>
            <div className=' rounded-circle p-4 p-lg-5  circle-hnw-parent'>
              <div className='shadow rounded-circle p-2 circle-hnw4 bg-white'>
                <div className=' div-center'>
                  <img className='w-50 div-center p-4' src='/assets/images/Benifits11.gif' alt='p' />
                </div>
                <div className='pt- div-center'>
                  <h5 className="text-center w-75  fw-semibold " >Manage multiple clients </h5>
                </div>

              </div>
            </div>

          </div>


        </div>







      </div>



      <div className='container  p-4 text-center '>
        <div className='pb-4'><span className="gradient-text display-5   pt-lg-0 pt-2" >How to Get Started on Connect My Designer
        </span></div>
        <div className='p-0 rpw gap-4  flex-wrap div-center  d-lg-flex'>

          <div className=' p-2  rounded  Started-cards col-lg-3'>

            <div
              className="Started-card-img rounded-4 border "
              style={{
                backgroundImage: "url('/assets/images/Submit.png')", backgroundSize: "cover", backgroundPosition: "center", width: "100%", height: "200px",
              }}   >
              <div className='div-center h-100 my-auto'>
                <h4 className=' text-white '>  Submit Your Application</h4>
              </div>
            </div>
            <div className='px-2 py-3'>
              <h4>Submit Your Application</h4>
              <p>
                Click on "Apply as a Designer" and  fill out the necessary information to join our platform.
              </p>
              <div className="div-center  " >
                <Button as={Link} to="/apply-as-designer" className="rounded    w-75   Display-6 shadow border-gradient-btn-how mr-lg-2">Apply as a designer</Button>
              </div>
            </div>

          </div>

          <div className=' p-2  rounded  Started-cards col-lg-3'>


            <div
              className="Started-card-img rounded-4 border "
              style={{
                backgroundImage: "url('/assets/images/Complete.png')",
                backgroundSize: "cover", backgroundPosition: "center", width: "100%", height: "200px",
              }}   >
              <div className='div-center h-100 my-auto'>
                <h4 className=' text-white '>  Set Up Your Profile</h4>
              </div>
            </div>
            <div className='px-2 py-3'>
              <h4> Complete Your Profile</h4>
              <p>
                Access the dashboard and fill out your 3D pricing details and other essential information.
              </p>
              <div className="div-center  "> <Button as={Link} to="/apply-as-designer" className="rounded    w-75   Display-6 shadow border-gradient-btn-how mr-lg-2">Complete Your Profile</Button></div>
            </div>

          </div>
          <div className=' p-2  rounded  Started-cards col-lg-3'>
            <div
              className="Started-card-img rounded-4 border "
              style={{
                backgroundImage: "url('/assets/images/Clients.png')",
                backgroundSize: "cover", backgroundPosition: "center", width: "100%", height: "200px",
              }}   >
              <div className='div-center h-100 my-auto'>
                <h4 className=' text-white '>  Start Receiving Projects</h4>
              </div>
            </div>

            <div className='px-2 py-3'>
              <h4>Get Clients and Earn</h4>
              <p>
                Start receiving project requests and earning by providing top-notch design services              </p>
              <div className="div-center  "> <Button as={Link} to="/apply-as-designer" className="rounded    w-75   Display-6 shadow border-gradient-btn-how mr-lg-2">Start Receiving Projects</Button></div>
            </div>

          </div>

        </div>
      </div>





      <div className='faqsection container px-lg-5  py-5 d-flex'>
        <div className='col-lg-3 d-lg-block  d-none p-3'>
          <div className='p-2'>
            <h2 className='fw-bold '>Frequently <br />  asked<br />   questions</h2>
            <img className='questions-png w-75' src='/assets/images/questions.png' />
          </div>
        </div>
        <div className='col-lg-9'>
          <Accordion activeKey={activeKey} className='p-0'>
            <Accordion.Item eventKey="0" className='border-0'>
              <Accordion.Header onClick={() => handleToggle("0")} className='border-bottom-2px'>1.What kind of projects can I find on CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                You can find a variety of projects ranging from residential and commercial 3D designs, VR walkthroughs, to 2D architectural drawings.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="1">
              <Accordion.Header onClick={() => handleToggle("1")} className='border-bottom-2px'>2. How does the payment process work on CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                CMD uses an escrow-based payment system where clients deposit payments upfront, which are released to designers upon project completion and approval.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="2">
              <Accordion.Header onClick={() => handleToggle("2")} className='border-bottom-2px'>3. Can I set my own rates on CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px  '>

                Yes, designers can set their own rates based on their expertise and the complexity of the projects they undertake.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="3">
              <Accordion.Header onClick={() => handleToggle("3")} className='border-bottom-2px'>4. How do I communicate with clients on CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                CMD provides seamless communication tools, including in-platform messaging and video calls, to facilitate smooth collaboration between clients and designers.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="4">
              <Accordion.Header onClick={() => handleToggle("4")} className='border-bottom-2px'>5. Are there any fees to join CMD as a designer?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                CMD is free for designers to join and post their profiles. We charge up to 25% on each project, which is applied when the client approves the work. For example, if a project is worth ₹10,000, ₹7,500 (75%) will be credited to your account, and the remaining ₹2,500 is CMD's fee. Designers can withdraw their 75% at any time to their bank account. Please note that international bank accounts may incur additional charges.              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion activeKey={activeKey} className=''>
            <Accordion.Item eventKey="5" className='border-0'>
              <Accordion.Header onClick={() => handleToggle("5")} className='border-bottom-2px'>6. What happens if there is a dispute with a client?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                CMD has a dedicated dispute resolution team to handle any issues between clients and designers, ensuring fair and timely resolutions.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="6">
              <Accordion.Header onClick={() => handleToggle("6")} className='border-bottom-2px'>7. How do I start a project once accepted?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                Once accepted, browse available projects, apply to those that match your skills, and start collaborating with clients after they select you for their projects.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="7">
              <Accordion.Header onClick={() => handleToggle("7")} className='border-bottom-2px'>8. What happens if a client requests changes after project completion?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                CMD allows for a review and revision process where clients can request changes within a specified period. Clear guidelines on this process will be provided.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="8">
              <Accordion.Header onClick={() => handleToggle("8")} className='border-bottom-2px'>9. Can I work on multiple projects simultaneously on CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                Yes, designers can work on multiple projects simultaneously, provided they manage their time and commitments effectively.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='border-0' eventKey="9">
              <Accordion.Header onClick={() => handleToggle("9")} className='border-bottom-2px'>10. Can international designers apply to CMD?</Accordion.Header>
              <Accordion.Body className='border-bottom-ans-2px '>
                Currently, CMD focuses on the Indian market, but we welcome applications from international designers and plan to expand our services globally.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

      </div>
      <FooterDesigner />
    </div>
  );
};

export default HomeDesigner;
