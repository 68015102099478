import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ensure you have an AuthContext providing user and logout

import DashboardOverview from './DashboardOverview';
import UserManagement from './UserManagement';
import DesignerManagement from './DesignerManagement';
import Documentation from './Documentation';
import ContentManagement from './ContentManagement';
import Chat from './Chat';
import PaymentTransaction from './PaymentTransaction';
import Helpsupport from './Helpsupport';
import Setting from './Setting';
import './superDashboard.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const navItems = [
  { name: 'Dashboard overview', icon: 'dashboard.png', subItems: [] },
  { name: 'User management', icon: 'Profile.png', subItems: [] },
  { name: 'Designer management', icon: 'chat.png', subItems: [] },
  { name: 'Documentation', icon: 'findwork.png', subItems: [] },
  { name: 'Content management', icon: 'Transaction.png', subItems: [] },
  { name: 'Chat', icon: 'Quotation.png', subItems: [] },
  { name: 'Payment & Transaction', icon: 'calender.png', subItems: [] },
  { name: 'Help and support', icon: 'Wallet.png', subItems: [] },
  { name: 'Setting', icon: 'setting.png', subItems: [] },
  { name: 'Logout', icon: 'logout.png', subItems: [] }
];

const DashboardSuperSidebar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);  // Initially open
  const [selectedItem, setSelectedItem] = useState('Dashboard overview');
  const [dropdowns, setDropdowns] = useState(
    navItems.reduce((acc, item) => {
      acc[item.name] = false;
      return acc;
    }, {})
  );
  const [modal, setModal] = useState(false);

  
  useEffect(() => {
    if (!user || (user.email !== 'maheshdarkunde2@gmail.com' && 
                  user.email !== 'sanket.khopkar89@gmail.com' && 
                  user.email !== 'pranav2121@gmail.com' && 
                  user.email !== 'rushikeshdarkunde2@gmail.com' && 
                  user.email !== 'hr@einterio.com' && 
                  user.email !== 'hello@connectmydesigner.com')) {
      navigate('/auth/login');
    }
  }, [user, navigate]);
  const toggle = () => setIsOpen(!isOpen);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'Dashboard overview') {
      setDropdowns(navItems.reduce((acc, navItem) => {
        acc[navItem.name] = false;
        return acc;
      }, {}));
    } else if (item === 'Logout') {
      toggleModal();
    }
  };

  const toggleDropdown = (item) => {
    setDropdowns(prevState => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === item ? !prevState[item] : false;
        return acc;
      }, {})
    }));
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleLogout = () => {
    logout();
    navigate("/auth/login");
  };

  const renderContent = () => {
    switch (selectedItem) {
      case 'Dashboard overview':
        return <DashboardOverview />;
      case 'User management':
        return <UserManagement />;
      case 'Designer management':
        return <DesignerManagement />;
      case 'Documentation':
        return <Documentation />;
      case 'Content management':
        return <ContentManagement />;
      case 'Chat':
        return <Chat />;
      case 'Payment & Transaction':
        return <PaymentTransaction />;
      case 'Help and support':
        return <Helpsupport />;
      case 'Setting':
        return <Setting />;
      default:
        return null;
    }
  };

  return (
    <div className='section'>
      <div className='pe-5'> 
        <Row>
          <Col md={12}>
            <div className='p-0 m-0'>
              <div className='d-flex '>
                <div className={`p-0 w-20`}>
                  <div className='position-fixed h-100vh-sup shadow-sm bg-white boeder-end'>
                    <div onClick={toggle} className="p-3 toggle-hide">
                      <p>{isOpen ? <i className="fa-solid fa-angles-left"></i> : <i className="fa-solid fa-angles-right"></i>}</p>
                    </div>
                    <Navbar className=''>
                      <nav>
                        <Nav navbar className='d-block'>
                          {navItems.map(item => (
                            <NavItem key={item.name}>
                              <NavLink
                                onClick={() => {
                                  item.subItems.length > 0 ? toggleDropdown(item.name) : handleItemClick(item.name);
                                }}
                                className={selectedItem.startsWith(item.name) ? 'active ' : 'nana'}
                              >
                                <img className='img-nav' src={`/assets/icons/${item.icon}`} />
                                {isOpen && item.name}
                                {item.subItems.length > 0 && <i className="fa-solid fa-chevron-down text-end text-right w-auto"></i>}
                              </NavLink>
                              {item.subItems.length > 0 && (
                                <Collapse isOpen={dropdowns[item.name]}>
                                  <Nav className="px-4 ms-2 d-block">
                                    {item.subItems.map((subItem, index) => (
                                      <NavItem key={index}>
                                        <NavLink
                                          onClick={() => handleItemClick(`${item.name}Sub${index + 1}`)}
                                          className={selectedItem === `${item.name}Sub${index + 1}` ? 'active-sub' : 'notactiv'}
                                        >
                                          {subItem}
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                  </Nav>
                                </Collapse>
                              )}
                            </NavItem>
                          ))}
                        </Nav>
                      </nav>
                    </Navbar>
                  </div>
                </div>
                <div className="w-80">
                  {renderContent()}
                </div>
              </div>
              <Modal isOpen={modal} toggle={toggleModal} centered>
                <ModalHeader toggle={toggleModal}>Logout Confirmation</ModalHeader>
                <ModalBody>
                  Are you sure you want to logout?
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handleLogout}>Logout</Button>{' '}
                  <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardSuperSidebar;
