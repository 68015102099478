import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import HeaderUser from "../user/header-user";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../firebase';
// import { useNavigate } from 'react-router-dom';
import Dos from './dos';
import Dont from './dont';
import HelpForm from './help';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';


const FormComponent = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '', profilePhoto: null, mobileNo: '', country: '', description: '', gender: '', strength: '', dob: '', joiningdate: new Date().toISOString().split('T')[0], address: '', city: '',
    qualification: '', experience: '', walkthroughLink: '', LinkedIn: '', employmentstatus: '', freelancingExperience: '', jobCommitment: '', statement: '', agreetodos: '', agreetodonts: '', skills: [], styles: [], Languages: [],
    software: [], proof3DDesign: null, proofWalkthrough: null, proofLandscape: null, verification: 'Pending', tagsfordesigner: [],
    proof2DDrawing: null, aadhaarCardFront: null, portfolio: null, portfolioImages: null, panCardPhoto: null, collegeCertificate: null, email: ''
  });
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedVerification, setSelectedVerification] = useState('');
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const location = useLocation();

  const [selectedStrength, setSelectedStrength] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [newSoftware, setNewSoftware] = useState('');
  const [newStyle, setNewStyle] = useState('');
  const [newLanguage, setNewLanguage] = useState('');
  const [newStrength, setNewStrength] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [photoToEdit, setPhotoToEdit] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const predefinedTags = [
    '3D Designer',
    '3D Visualization',
    '3D Modeling',
    'Architectural Visualization',
    'Interior 3D Design',
    'Exterior 3D Design',
    'Commercial 3D Design',
    'Product Rendering',
    'Furniture Design',
    'CAD Modeling',
    '3D Floor Plans',
    'Photorealistic Renders',
    'Texturing and Lighting',
    'Virtual Staging',
    '3D Walkthroughs',
    'High-Resolution Render',
    'Architectural Rendering',
    'Custom 3D Design',
    'Real-time Rendering',
    '3D Space Planning',
    '3D Architectural Drafting',
    'Sketchup Modeling',
    'Blender Artist',
    'Unreal Engine Rendering',
    'Maya 3D Artist',
    '3ds Max Artist',
    'Lumion Renders',

    'VR Walkthrough',
    'Virtual Reality (VR)',
    '3D VR Walkthrough',
    'Realistic Walkthrough',
    'Interactive VR',
    'VR Tour Creation',
    '360-degree Walkthrough',
    'Immersive Experience',
    'VR Visualization',
    'VR Interior Walkthrough',
    'VR Exterior Walkthrough',
    'Real Estate VR',
    'VR Rendering',
    'Virtual Tours',
    'Virtual Showroom',
    '3D Virtual Tour',
    'Real-time VR',
    'VR for Architecture',
    '720 Panorama Tour',
    'VR Design Presentation',
    'VR for Furniture Showrooms',
    'VR Landscaping Walkthrough',
    'Commercial VR Walkthrough',
    'Residential VR Walkthrough',
    'High-Resolution VR Tours',
    'VR with Sound Integration',
    'VR Animation Tours',

    '2D Architectural Drawings',
    '2D Furniture Layouts',
    'Architectural Drafting',
    'Floor Plans',
    'Building Layouts',
    'Residential Drawings',
    'Commercial Drawings',
    '2D Working Drawings',
    '2D CAD Drawings',
    'Construction Drawings',
    'Electrical Drawings',
    'Plumbing Drawings',
    'HVAC Drawings',
    'Structural Drawings',
    'Furniture Layout Drawings',
    'Wall Elevations',
    'Interior 2D Plans',
    'Exterior 2D Plans',
    'Space Planning',
    'Building Permit Drawings',
    'Architectural Blueprints',
    'Site Plan Drawings',
    'Detailed Section Drawings',
    'Foundation Plans',
    'Roof Plans',
    'Conceptual Drawings',
    'As-built Drawings',
    'Building Code Drawings',
    'Technical 2D Drawings',
    'Detailed Architectural Design',
    'Dimensioning & Annotations',
    'AutoCAD 2D Drawings',
    'Revit 2D Drafting',
    'Electrical Layout Plans',
    'Furniture & Fixture Plans',

    'Landscape Design',
    'Garden Design',
    'Hardscape Design',
    'Softscape Design',
    'Urban Landscape',
    'Residential Landscape',
    'Commercial Landscape',
    'Landscape Architecture',
    'Site Planning',
    'Outdoor Space Design',
    'Sustainable Landscaping',
    'Ecological Landscaping',
    'Xeriscaping',
    'Irrigation Design',
    'Plant Selection',
    'Landscape Lighting Design',
    'Landscape Renovation',
    'Landscape 2D Drawings',
    'Landscape 3D Renderings',
    'Garden Layout Plans',
    'Water Features Design',
    'Pathway & Patio Design',
    'Lawn & Turf Design',
    'Tree & Shrub Layout',
    'Deck & Pergola Design',
    'Landscape Master Plan',
    'Environmental Design',
    'Landscape Conceptual Design',
    'Planting Plans',
    'Grading & Drainage Plans',
    'Landscape Maintenance Plans',
    'Roof Garden Design',
    'Green Roof Design',
    'Outdoor Furniture Layout',
    'Pool & Spa Design',
    'Landscape CAD Drawings',
    'Landscape Visualization',
    'Urban Green Spaces',
    'Sustainable Garden Design'
  ];




  const [imagePreviews, setImagePreviews] = useState({
    profilePhoto: null, proof3DDesign: null, proofWalkthrough: null, proofLandscape: null, proof2DDrawing: null, aadhaarCardFront: null,
    portfolio: null, portfolioImages: null, panCardPhoto: null, collegeCertificate: null
  });
  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({ ...prevData, email: user.email }));

      // Check if the form has already been submitted
      const checkIfSubmitted = async () => {
        try {
          const response = await axios.get(`https://connectmydesigner.com/api/users/email/${user.email}`);
          if (response.data) {
            setAlreadySubmitted(true);
          }
        } catch (error) {
          console.error('Error checking if form is already submitted:', error);
        }
      };

      checkIfSubmitted();
    }
    if (!loading && !user) {
      navigate('/auth/login', { state: { from: location } });
    }

  }, [loading, user, navigate]);


  const handleSelectSuggestion = (suggestion) => {
    setFormData(prevData => ({
      ...prevData,
      tagsfordesigner: [...prevData.tagsfordesigner, suggestion]
    }));
    setInputValue('');
    setSuggestions([]);
  };
  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      if (inputValue && !formData.tagsfordesigner.includes(inputValue.trim())) {
        setFormData(prevData => ({
          ...prevData,
          tagsfordesigner: [...prevData.tagsfordesigner, inputValue.trim()]
        }));
      }
      setInputValue('');
      setSuggestions([]);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let key in formData) {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item) => data.append(key, item));
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      };
      const response = await axios.post('https://connectmydesigner.com/api/users', data, config);

      alert('Form submitted successfully');

      // Redirect after successful form submission
      window.location.href = '/user/profile';
    } catch (error) {
      if (error.response) {
        console.error('Server responded with:', error.response.data);
        alert(`Error: ${error.response.data.message || 'Request failed with status 400'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error in request setup:', error.message);
      }
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleSaveCrop = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = photoToEdit;

    img.onload = () => {
      const { width, height } = croppedAreaPixels; canvas.width = width;
      canvas.height = height; ctx.drawImage(img, croppedAreaPixels.x, croppedAreaPixels.y, width, height, 0, 0, width, height);

      canvas.toBlob(blob => {
        const file = new File([blob], 'profilePhoto.jpg', { type: 'image/jpeg' });
        setFormData({ ...formData, profilePhoto: file });
        setImagePreviews({ ...imagePreviews, profilePhoto: URL.createObjectURL(file) });
        setShowModal(false);
      }, 'image/jpeg');
    };
  };
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    // if (type === 'file') {
    //   const file = files[0];
    //   if (name === 'profilePhoto') {
    //     setPhotoToEdit(URL.createObjectURL(file));
    //     setShowModal(true);
    //   }
    //   setFormData({ ...formData, [name]: file });
    //   setImagePreviews({ ...imagePreviews, [name]: URL.createObjectURL(file) });



    // }

     if (type === 'file') {
    const file = files[0];
    if (name === 'profilePhoto') {
      setPhotoToEdit(URL.createObjectURL(file));
      setShowModal(true);
    }
    setFormData({ ...formData, [name]: file });
    
    // Create preview for all file types
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviews({ ...imagePreviews, [name]: reader.result });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }
    else if (type === 'checkbox') {
      if (name === 'styles') {
        setSelectedStyles(checked
          ? [...selectedStyles, value]
          : selectedStyles.filter((item) => item !== value)
        );
      }
      if (name === 'language') {
        setSelectedLanguage(checked
          ? [...selectedLanguage, value]
          : selectedLanguage.filter((item) => item !== value)
        );
      }
      if (name === 'software') {
        setSelectedSoftware(checked
          ? [...selectedSoftware, value]
          : selectedSoftware.filter((item) => item !== value)
        );
      }
      setFormData({
        ...formData,
        [name]: checked
          ? [...(formData[name] || []), value]
          : (formData[name] || []).filter((item) => item !== value)
      });
    }
    else if (name === 'tagsfordesigner') {
      const input = value;
      setInputValue(input);

      if (input) {
        const filteredSuggestions = predefinedTags.filter(tag =>
          tag.toLowerCase().includes(input.toLowerCase()) && !formData.tagsfordesigner.includes(tag)
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    }
    else {
      setFormData({ ...formData, [name]: value });
      if (name === 'gender') {
        setSelectedGender(value);
      }
      if (name === 'verification') {
        setSelectedVerification(value);
      }
      if (name === 'strength') {
        setSelectedStrength(value);
        setFormData({ ...formData, strength: value });
      }
    }
  };



  const validateStep = () => {
    let isValid = true;
    let message = '';

    if (step === 1) {
      const requiredFieldsStep1 = [

        { field: formData.country, name: 'Country' },
        { field: formData.city, name: 'Location (City)' },
        { field: formData.dob, name: 'Date of Birth' },
        { field: formData.qualification, name: 'Qualification' },
        { field: formData.experience, name: 'Experience' },
        { field: formData.description, name: 'Description' },
        { field: formData.profilePhoto, name: 'Profile Photo' },
        { field: formData.gender, name: 'Gender' },
        { field: formData.experience, name: 'Experience' },

      ];

      for (let i = 0; i < requiredFieldsStep1.length; i++) {
        if (!requiredFieldsStep1[i].field) {
          isValid = false;
          message = `Please fill out the ${requiredFieldsStep1[i].name} field.`;
          break;
        }
      }
    }


    if (step === 2) {
      if (selectedLanguage.length === 0) {
        isValid = false;
        message = 'Please select at least one language.';
      }
      else if (selectedSoftware.length === 0) {
        isValid = false;
        message = 'Please select at least one software.';
      }
      else if (selectedStrength.length === 0) {
        isValid = false;
        message = 'Please select at least one Strength.';
      }
      else if (selectedStyles.length === 0) {
        isValid = false;
        message = 'Please select at least one Style.';
      }



    }
    if (step === 4) {
      const requiredFieldsStep3 = [
        { field: formData.aadhaarCardFront, name: 'Identity Document' },
        { field: formData.collegeCertificate, name: 'collegeCertificate' },
        { field: formData.portfolio, name: 'portfolio' },
        { field: formData.portfolioImages, name: 'Thumbnail' },

      ];

      for (let i = 0; i < requiredFieldsStep3.length; i++) {
        if (!requiredFieldsStep3[i].field) {
          isValid = false;
          message = `Please fill out the ${requiredFieldsStep3[i].name} field.`;
          break;
        }
      }
    }


    if (step === 3) {
      const requiredFieldsStep4 = [
        { field: formData.employmentstatus, name: 'employmentstatus' },
        { field: formData.freelancingExperience, name: 'freelancingExperience' },
        { field: formData.jobCommitment, name: 'jobCommitment' },
        { field: formData.statement, name: 'statement' },

      ];

      for (let i = 0; i < requiredFieldsStep4.length; i++) {
        if (!requiredFieldsStep4[i].field) {
          isValid = false;
          message = `Please fill out the ${requiredFieldsStep4[i].name} field.`;
          break;
        }
      }
    }

    if (step === 5) {
      const requiredFieldsStep5 = [
        { field: formData.agreetodos, name: 'agreetodos' },


      ];

      for (let i = 0; i < requiredFieldsStep5.length; i++) {
        if (!requiredFieldsStep5[i].field) {
          isValid = false;
          message = `Please fill out the ${requiredFieldsStep5[i].name} field.`;
          break;
        }
      }
    }

    if (step === 6) {
      const requiredFieldsStep6 = [
        { field: formData.agreetodonts, name: 'agreetodonts' },


      ];

      for (let i = 0; i < requiredFieldsStep6.length; i++) {
        if (!requiredFieldsStep6[i].field) {
          isValid = false;
          message = `Please fill out the ${requiredFieldsStep6[i].name} field.`;
          break;
        }
      }
    }
    if (!isValid) {
      alert(message);
    }

    return isValid;
  };


  const handleAddSoftware = (e) => {
    e.preventDefault();
    if (newSoftware.trim() !== '' && !selectedSoftware.includes(newSoftware)) {
      setSelectedSoftware([...selectedSoftware, newSoftware]);
      setFormData({
        ...formData,
        software: [...formData.software, newSoftware]
      });
      setNewSoftware('');
    }
  }
  const handleAddStyle = (e) => {
    e.preventDefault();
    if (newStyle.trim() !== '' && !selectedStyles.includes(newStyle) && selectedStyles.length < 3) {
      setSelectedStyles([...selectedStyles, newStyle]);
      setFormData({
        ...formData,
        styles: [...formData.styles, newStyle]
      });
      setNewStyle('');
    }
  };

  const handleAddLanguage = (e) => {
    e.preventDefault();
    if (newLanguage.trim() !== '' && !selectedLanguage.includes(newLanguage)) {
      setSelectedLanguage([...selectedLanguage, newLanguage]);
      setFormData({
        ...formData,
        Languages: [...(formData.Languages || []), newLanguage] // Ensure Languages is always an array
      });
      setNewLanguage('');
    }
  };
  const handleAddStrength = (e) => {
    e.preventDefault();
    if (newStrength.trim() !== '' && !selectedStrength.includes(newStrength)) {
      setSelectedStrength([...selectedStrength, newStrength]);
      setFormData({
        ...formData,
        Strengths: [...(formData.Strengths || []), newStrength] // Ensure Strengths is always an array
      });
      setNewStrength('');
    }
  };



  // const renderFileInput = (label, name, width, height, maxSize, note, fileType) => (
  //   <div className="form-group p-2">
  //     <label className='div-center text-start pb-2'>{label}</label>
  //     <div className="file-input-box" style={{ width: `${width}px`, height: `${height}px` }}>
  //       <input type="file" name={name} className="file-input" accept={fileType} onChange={handleChange} style={{ display: imagePreviews[name] ? 'none' : 'block' }} />
  //       {imagePreviews[name] && (
  //         <img  src={imagePreviews[name]} alt={`${label} File Uploaded`} width={width} height={height} onClick={() => {
  //           setFormData({ ...formData, [name]: null }); setImagePreviews({ ...imagePreviews, [name]: null });
  //         }} style={{ cursor: 'pointer' }} />)}
  //       {!imagePreviews[name] && (
  //         <div
  //           className="file-placeholder border-0"
  //           style={{ width: `${width}px`, height: `${height}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc' }}>
  //           <div>
  //             <span className="material-symbols-outlined font-36">publish</span>
  //             <span>Max size: {maxSize}mb</span>   {note && <div className="pt-2 text-secondary">{note}</div>}
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
 
  const renderFileInput = (label, name, width, height, maxSize, note, fileType) => (
    <div className="form-group p-2">
      <label className='div-center text-start pb-2'>{label}</label>
      <div className="file-input-box" style={{ width: `${width}px`, height: `${height}px` }}>
        <input 
          type="file" 
          name={name} 
          className="file-input" 
          accept={fileType} 
          onChange={handleChange} 
          style={{ display: imagePreviews[name] ? 'none' : 'block' }} 
        />
        {imagePreviews[name] && (
          fileType === 'application/pdf' ? (
            <div className="pdf-preview" style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}>
              <embed src={imagePreviews[name]} type="application/pdf" width="100%" height="100%" />
            </div>
          ) : (
            <img 
              src={imagePreviews[name]} 
              alt={`${label} File Uploaded`} 
              width={width} 
              height={height} 
              onClick={() => {
                setFormData({ ...formData, [name]: null }); 
                setImagePreviews({ ...imagePreviews, [name]: null });
              }} 
              style={{ cursor: 'pointer', objectFit: 'cover' }} 
            />
          )
        )}
        {!imagePreviews[name] && (
          <div
            className="file-placeholder border-0"
            style={{ width: `${width}px`, height: `${height}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc' }}>
            <div>
              <span className="material-symbols-outlined font-36">publish</span>
              <span>Max size: {maxSize}mb</span>   {note && <div className="pt-2 text-secondary">{note}</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (loading) return <div>Loading...</div>;

  if (alreadySubmitted) {
    navigate('/user/profile');
    return null;
  }
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const saveAndNext = () => {
    if (validateStep()) {
      nextStep();
    }
  };

  const renderStep = () => {

    if (loading) { return <div>Loading...</div>; }
    return (
      <div className=''>
        <div className=''>
          <HeaderUser />
          <HelpForm />
        </div>

        <div className=' fixed-top d-lg-block d-none'>
          <div className='d-flex header-bg-dark '>
            <div className='col-9 ps-lg-5 py-1'>
              <h2 className='text-white'>
                Welcome to CMD
              </h2>
              <span className='gradient-text'>
                Your application should only take a few minutes. Fill in the details to complete your profile and start attracting clients.
              </span>

            </div>
            <div className='col-3 p-0'>
              <img
                src="/assets/images/cmdlogo-white.png"
                width="auto"
                height="80px"
                className=" p-0 m-0"
                alt="Connect My Designer"
              />
            </div>
          </div>
          <div className='col-8 bg-white '>
            <div className=' d-lg-flex justify-content-between  ' >
              <div className='btn text-gray-200  py-0 '>
                <span className=' text-step-heading fw-bold font-18px'> {step > 0 && (<span class="material-symbols-outlined font-16px text-yellow ">
                  <span className='mt-1 fa fa-check-circle'> </span>
                </span>)} Personal Details</span>
              </div>
              <div className='btn text-gray-200  py-0 '>
                <span className=' text-step-heading fw-bold font-18px'> {step > 1 && (<span class="material-symbols-outlined font-16px text-yellow  ">
                  <span className='mt-1 fa fa-check-circle'> </span>
                </span>)}Skills & software's</span>
              </div>
              <div className='btn text-gray-200  py-0 '>
                <span className=' text-step-heading fw-bold font-18px'>  {step > 2 && (<span class="material-symbols-outlined font-16px text-yellow  ">
                  <span className='mt-1 fa fa-check-circle'> </span>
                </span>)} Additional information</span>
              </div>
              <div className='btn text-gray-200  py-0 '>
                <span className=' text-step-heading fw-bold font-18px'>  {step > 3 && (<span class="material-symbols-outlined font-16px text-yellow  ">
                  <span className='mt-1 fa fa-check-circle'> </span>
                </span>)} Documentation </span>
              </div>
            </div>
          </div>

        </div>
        <div>



          <div className='container d-lg-flex    ' >


            <div className=' px-lg-0 px-2  '>
              <form className="pt-lg-2">
                {step === 1 && (
                  <div>
                    <span className='fw-bold font-18px d-lg-none d-block'>
                      Personal details :
                    </span>
                    <div className='d-lg-flex col-12 px-lg-5'>
                      <div>
                        <span className='px-2 py-0 text-start text-lg-center '>Profile Photo</span>

                        <div className=''>{renderFileInput('', 'profilePhoto', 160, 160, 5, 'Upload Professional Photo', 'image/jpeg,image/png')}</div>
                        {/* <div className=''>{renderFileInput('', 'profilePhoto', 160, 160,5,'Uplode Professional Photo', 'image/jpeg,image/png')}</div> */}
                      </div>
                      <div className="form-group  pt-4 ms-lg-3">
                        <label>Full Name *</label>
                        <input type="text" name="name" className="form-control" onChange={handleChange} />
                        <label className='pt-2' >Mobile No *</label>
                        <input type="number" name="mobileNo" className="form-control" onChange={handleChange} />

                        <div>
                          <label className='pt-2'>Gender *</label>
                          <label className={`btn  border mx-lg-2 mx-1 my-1 py-1 px-3 ${selectedGender === 'male' ? 'active btn-warning bg-yellow-lite' : ''}`}>
                            <input type="radio" name="gender" value="male" checked={selectedGender === 'male'} onChange={handleChange} hidden /> Male
                          </label>
                          <label className={`btn  border mx-lg-2 mx-1 my-1 py-1 px-2 ${selectedGender === 'female' ? 'active btn-warning bg-yellow-lite' : ''}`}>
                            <input type="radio" name="gender" value="female" checked={selectedGender === 'female'} onChange={handleChange} hidden /> Female
                          </label>
                          <label className={`btn  border mx-lg-2 mx-1 my-1 py-1 px-3 ${selectedGender === 'other' ? 'active btn-warning bg-yellow-lite' : ''}`}>
                            <input type="radio" name="gender" value="other" checked={selectedGender === 'other'} onChange={handleChange} hidden /> Other
                          </label>
                        </div>

                        <div className='' hidden>
                          <label className='pt-2'>verification *</label>
                          <label className={`btn border mx-lg-2 my-1 py-1 px-2`}>
                            <input type="radio" name="verification" value="Pending" checked={selectedVerification === 'Pending' || !selectedVerification} onChange={handleChange} /> Pending
                          </label>
                          <label className={`btn border mx-lg-2 my-1 py-1 px-3`}>
                            <input type="radio" name="verification" value="Approved" checked={selectedVerification === 'Approved'} onChange={handleChange} /> Approved
                          </label>
                          <label className={`btn border mx-lg-2 my-1 py-1 px-3`}>
                            <input type="radio" name="verification" value="OnHold" checked={selectedVerification === 'OnHold'} onChange={handleChange} /> On Hold
                          </label>
                          <label className={`btn border mx-lg-2 my-1 py-1 px-3`}>
                            <input type="radio" name="verification" value="Rejected" checked={selectedVerification === 'Rejected'} onChange={handleChange} /> Rejected
                          </label>
                        </div>

                        <div className='' hidden>
                          <label className='pt-2 ms-lg-1'>Joining date *</label>
                          <input type="date" name="joiningdate" className="form-control ms-lg-1 " defaultValue={new Date().toISOString().split('T')[0]} />
                        </div>


                      </div>
                    </div>
                    <div className=' col-12 px-lg-5 ms-lg-1 '>
                      <label className='p-0 ms-lg-1' >Description *</label> <textarea rows="2" name="description" placeholder='Describe yourself in 30 words' maxLength={150} className="form-control ms-lg-1" onChange={handleChange} />
                      <label className='pt-2' > Country * </label>   <input type="text" name="country" className="form-control" onChange={handleChange} />
                      {/* <label className='pt-2 ms-lg-1'>Address </label> <input type="text" name="address" className="form-control ms-lg-1" onChange={handleChange} /> */}
                      <label className='pt-2 ms-lg-1'>Location (City) *</label> <input type="text" name="city" className="form-control ms-lg-1" onChange={handleChange} />
                      <label className='pt-2 ms-lg-1'>Date of Birth *</label> <input type="date" name="dob" className="form-control ms-lg-1" onChange={handleChange} />
                      <label className='pt-2 ms-lg-1'>Qualification *</label> <input type="text" name="qualification" className="form-control ms-lg-1" onChange={handleChange} />
                      <label className='pt-2 ms-lg-1'>Experience *</label> <input type="number" name="experience" className="form-control ms-lg-1" onChange={handleChange} />
                    </div>

                  </div>
                )}

                {step === 2 && (
                  <div className='col-lg-6 ms-lg-4'>
                    <h4>Fill the details :</h4>
                    <div className=' bg-gray-200 px-2  text-secondary rounded  font-12px'> <i class="fa-solid fa-circle-info"></i>
                      <span>(This information will be used for our AI matching tool so please input according to strength and knowledge )  </span>
                    </div>
                    <div className='p-0 m-0'>
                      <div className=''>
                        <div>
                          <label className='py-1 '> 1. Select your language . *</label>
                        </div>

                        <label className='p-1 my-2 rounded col-lg-10 col-12 border font-14px-auto' style={{ minHeight: "30px", }}>
                          {selectedLanguage.join(', ')}
                        </label>

                        <div className='gap-1 d-flex flex-wrap parent-class'>
                          {['English', 'Hindi', 'Marathi', 'Punjabi'].map((Language, index) => (
                            <label key={index} className={`btn border   py-lg-1 py-0 px-lg-2 px-1  ${selectedLanguage.includes(Language) ? 'active mt-1 bg-yellow-lite border-warning' : ' mt-1'}`}>
                              <input type="checkbox" name="language" value={Language} checked={selectedLanguage.includes(Language)} onChange={handleChange} hidden disabled={selectedLanguage.length === 10 && !selectedLanguage.includes(Language)} /> {Language}
                            </label>
                          ))}
                          <div className='d-flex pt-1'>
                            <input type="text" className='new-field-input p-1' style={{ minWidth: "100px", maxWidth: "160px" }} value={newLanguage} onChange={(e) => setNewLanguage(e.target.value)} placeholder="Add Other Language" />
                            <button className='new-field-btn bg-yellow' onClick={handleAddLanguage}>Add</button>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div>
                      <div className='pt-3'> 2. Skills *</div>
                      <div className='bg-gray-200 rounded px-2 text-secondary  font-12px'> <i class="fa-solid fa-circle-info"></i>
                        <span>
                          ( "Carefully add your skills as your profile will be listed under these categories on CMD platform.  Ensure you have sufficient evidence to validate the skills you include.")                           </span>
                      </div>
                      <label className='p-1 mt-1  rounded font-14px-auto col-lg-10 col-12 border' style={{ minHeight: "32px" }}>{formData.skills.join(', ')}</label >
                      <div>
                        <div className=''>
                          <div className=' d-flex gap-3'>
                            <div className='d-flex my-2'>
                              <div>
                                <input className='' type="checkbox" name="skills" value="3D Design " onChange={handleChange} />
                              </div>
                              <div className='px-2'>3D Design</div>
                            </div>
                            {formData.skills.includes('3D Design ') && (
                              <div className="form-group w-50  ">
                                <input type="file" name="proof3DDesign" className="form-control p-1 my-1" onChange={handleChange} />


                              </div>
                            )}
                          </div>
                          <div className='d-flex my-1'>
                            <div className='d-flex py-1'>       <div>       <input type="checkbox" name="skills" value="Walkthrough" onChange={handleChange} /></div> <div className='px-2'>Walkthrough</div></div>
                            {formData.skills.includes('Walkthrough') && (
                              <div className="form-group w-50 col-   mt-1">
                                <input type="file" name="proofWalkthrough" className="form-control p-1" onChange={handleChange} />
                                <input type="text" name="walkthroughLink" placeholder='Walkthrough Link' className="form-control p-1 mt-1" onChange={handleChange} />
                              </div>
                            )}
                          </div>
                          <div className='d-flex gap-1'>
                            <div className='d-flex py-1'>       <div> <input className='' type="checkbox" name="skills" value="2D Drawing" onChange={handleChange} /> </div><div className='px-2 '>2D Drawing</div></div>
                            {formData.skills.includes('2D Drawing') && (
                              <div className="form-group w-50 mt-1">
                                <input type="file" name="proof2DDrawing" className="form-control p-1" onChange={handleChange} />
                              </div>
                            )}
                          </div>
                          <div className='d-flex gap-2 '>
                            <div className='d-flex py-1'>      <div>   <input type="checkbox" name="skills" value="Landscape" onChange={handleChange} /></div> <div className='px-2'>Landscape</div></div>
                            {formData.skills.includes('Landscape') && (
                              <div className="form-group w-50 mt-1">
                                <input type="file" name="proofLandscape" className="form-control p-1" onChange={handleChange} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className='py-4'>
                      <div>
                        <label className='py-1'>2a. Add relevant tags for your skills {formData.skills.join(', ')} *</label>
                        <div>
                          {formData.tagsfordesigner.map((tag, index) => (
                            <span key={index} className="badge badge-gray-200">#{tag}</span>
                          ))}
                        </div>
                        <input
                          type="text" name="tagsfordesigner"
                          placeholder="Add relevant tags to help users find your profile"
                          className="form-control p-1 mt-1 w-75" value={inputValue}
                          onChange={handleChange} onKeyDown={handleInputKeyDown} />
                        {suggestions.length > 0 && (
                          <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                              <button className='btn mx-2 border'
                                key={index} onClick={() => handleSelectSuggestion(suggestion)} style={{ cursor: 'pointer' }}
                              >  {suggestion}   </button>))}
                          </ul>
                        )}

                      </div>
                    </div>
                    <div className="pt-2 ">
                      <lable className="py-2 my-2">3. Which room do you consider your strength ? * <span className='text-gray-400 '>(Select any one)</span></lable>
                      <h1></h1>

                      <div className='gap-1 d-flex flex-wrap'>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Living room' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Living room" checked={selectedStrength === 'Living room'} onChange={handleChange} hidden /> Living room
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Master bedroom' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Master bedroom" checked={selectedStrength === 'Master bedroom'} onChange={handleChange} hidden /> Master bedroom
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Kitchen' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Kitchen" checked={selectedStrength === 'Kitchen'} onChange={handleChange} hidden /> Kitchen
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Kids bedroom' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Kids bedroom" checked={selectedStrength === 'Kids bedroom'} onChange={handleChange} hidden /> Kids bedroom
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Pooja room' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Pooja room" checked={selectedStrength === 'Pooja room'} onChange={handleChange} hidden /> Pooja room
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Entertainment room' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Entertainment room" checked={selectedStrength === 'Entertainment room'} onChange={handleChange} hidden /> Entertainment room
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === '   Parents bedrooom' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="   Parents bedrooom" checked={selectedStrength === '   Parents bedrooom'} onChange={handleChange} hidden /> Parents bedrooom
                        </label>
                        <label className={`btn border   py-lg-1 py-0 px-lg-2 px-1   ${selectedStrength === 'Drawing room' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="Drawing room" checked={selectedStrength === 'Drawing room'} onChange={handleChange} hidden />Drawing room
                        </label>
                        {/* <label className={`btn border   py-lg-1 py-0 px-3  ${selectedStrength === 'other' ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="radio" name="strength" value="other" checked={selectedStrength === 'other'} onChange={handleChange} hidden /> other
                        </label> */}
                        <div className='d-flex '>
                          <input type="text" className='new-field-input p-1' style={{ minWidth: "100px", maxWidth: "160px" }} value={newStrength} onChange={(e) => setNewStrength(e.target.value)} placeholder="Add Other Room" />
                          <button className='new-field-btn bg-yellow' onClick={handleAddStrength}>Add</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='py-1 mt-3'>4. Styles * <span className='text-gray-400 '>(Select upto 5  )</span></label>
                      </div>
                      <label className='p-1 rounded col-lg-10 col-12 border text-secondary font-14px-auto' style={{ minHeight: "30px" }}>
                        {selectedStyles && selectedStyles.length > 0 ? selectedStyles.join(', ') : 'Only the first three will be visible on the profile.'}
                      </label>

                     

                      <div className='gap-1 d-flex flex-wrap parent-class'>
                        {['Simple', 'Contemporary', 'Indian Traditional', 'Bohemian', 'Minimalist', 'Modern Luxury', 'Industrial Rustic', 'Scandinavian', 'Indowestern', 'Modern Simplicity'].map((style, index) => (
                          <label key={index} className={`btn border py-1 px-2 ${selectedStyles.includes(style) ? 'active mt-1 bg-yellow-lite border-warning' : ' mt-1'}`}>
                            <input type="checkbox" name="styles" value={style} checked={selectedStyles.includes(style)} onChange={handleChange} hidden disabled={selectedStyles.length === 5 && !selectedStyles.includes(style)} /> {style}
                          </label>
                        ))}
                        <div className='d-flex'>
                          <input type="text" className='new-field-input ' style={{ minWidth: "100px", maxWidth: "160px" }} value={newStyle} onChange={(e) => setNewStyle(e.target.value)} placeholder="Add new style" />
                          <button className='new-field-btn  bg-yellow' onClick={handleAddStyle}>Add</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='pt-1 mt-3'>5.Softwares  * <span className='text-gray-400 '>(Select upto 5  )</span></label>
                      </div>
                      <label className='p-1 my-2 rounded col-lg-10 col-12 border text-secondary font-14px-auto' style={{ minHeight: "29px" }}>
                        {selectedSoftware && selectedSoftware.length > 0 ? selectedSoftware.join(', ') : 'Only the first three will be visible on the profile.'}

                      </label>
                      <div className='gap-1 d-flex flex-wrap'>

                        <label className={`btn border    ${selectedSoftware.includes('SketchUps') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="SketchUps" checked={selectedSoftware.includes('SketchUps')} onChange={handleChange} hidden /> SketchUps
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('3ds max') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="3ds max" checked={selectedSoftware.includes('3ds max')} onChange={handleChange} hidden /> 3ds max
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Revit') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Revit" checked={selectedSoftware.includes('Revit')} onChange={handleChange} hidden /> Revit
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Lumion') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Lumion" checked={selectedSoftware.includes('Lumion')} onChange={handleChange} hidden /> Lumion
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('V Ray') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="V Ray" checked={selectedSoftware.includes('V Ray')} onChange={handleChange} hidden /> V Ray
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Blender') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Blender" checked={selectedSoftware.includes('Blender')} onChange={handleChange} hidden /> Blender
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Enscape') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Enscape" checked={selectedSoftware.includes('Enscape')} onChange={handleChange} hidden /> Enscape
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Vectorworks') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Vectorworks" checked={selectedSoftware.includes('Vectorworks')} onChange={handleChange} hidden /> Vectorworks
                        </label>
                        <label className={`btn border    ${selectedSoftware.includes('Room Styler') ? 'active  bg-yellow-lite border-warning' : ''}`}>
                          <input type="checkbox" name="software" value="Room Styler" checked={selectedSoftware.includes('Room Styler')} onChange={handleChange} hidden /> Room Styler
                        </label>
                        <div className='d-flex'>
                          <input type="text" className='new-field-input ' style={{ minWidth: "100px", maxWidth: "160px" }} value={newSoftware}
                            onChange={(e) => setNewSoftware(e.target.value)} placeholder="Other Software" />
                          <button className='new-field-btn bg-yellow' onClick={handleAddSoftware}>Add</button>
                        </div>
                      </div>

                    </div>



                  </div>

                )}
                {step === 3 && (
                  <div className='  ps-lg-5 ps-sm-2 pe-sm-2 ps-1  '>
                    <div>
                      <div>
                        <label className='pt-3'>1. What is your current employment status?</label>
                        <div className='d-grid form-check'>
                          <span>   <input type="radio" name="employmentstatus" value="Employed" onChange={handleChange} /> Employed</span>
                          <span>   <input type="radio" name="employmentstatus" value="Freelancing" onChange={handleChange} /> Freelancing</span>
                          <span>    <input type="radio" name="employmentstatus" value="Unemployed" onChange={handleChange} /> Unemployed</span>
                          <span>   <input type="radio" name="employmentstatus" value="Business" onChange={handleChange} /> Business</span>
                          <div className='d-flex'>
                            <span className='my-auto pe-2'>Other </span>  <input type="text" className='form-control w-50' name="employmentstatus" onChange={handleChange} />
                          </div>

                        </div>
                      </div>
                      <div>
                        <label className='pt-3'>
                          2. Do you have previous freelancing experience? *</label>
                        <div className='d-grid form-check'>
                          <span>    <input type="radio" name="freelancingExperience" value="Yes" onChange={handleChange} /> Yes</span>
                          <span>   <input type="radio" name="freelancingExperience" value="No" onChange={handleChange} /> No</span>
                        </div>
                      </div>

                      <label className='pt-3'>
                        3. How many hours per week can you dedicate to CMD projects?</label>
                      <div className="d-grid w-75 pe-lg-5 form-check">
                        <select className="form-select" name="jobCommitment" onChange={handleChange}>
                          <option value="">Select your commitment</option>
                          <option value=" Full Time (40 hours/week)">Full Time (40 hours/week)</option>
                          <option value="Part Time (20 hours/week)">Part Time (20 hours/week)</option>
                          <option value="Hourly (10 hours/week)">Hourly (10 hours/week)</option>
                        </select>
                      </div>


                      <div>
                        <label className='pt-3'>
                          4. Which statement best describes you? (Select a maximum of 1 )</label>
                        <div className='d-grid form-check'>
                          <span>   <input type="radio" name="statement" value="I am a full-time freelancer" onChange={handleChange} /> I am a full-time freelancer</span>
                          <span>   <input type="radio" name="statement" value="I have a job plus freelance" onChange={handleChange} /> I have a job plus freelance</span>
                          <span>   <input type="radio" name="statement" value="I have a job, no freelance" onChange={handleChange} /> I have a job, no freelance</span>
                          <span>   <input type="radio" name="statement" value="I have my own team and interior design business" onChange={handleChange} /> I have my own team and interior design business</span>
                        </div>
                      </div>
                      <div className='py-3 d-flex'>

                        <label className='w-50 my-auto' > 5. LinkedIn Profile Link </label>
                        <input type='text' placeholder='Link goes here' name="LinkedIn" className="form-control mx-lg-3 w-50" onChange={handleChange} />
                      </div>
                    </div>


                  </div>
                )}
                {step === 4 && (
                  <div>
                    <div className='d-lg-flex ms-lg-4   pt-3 gap-3'>
                      <div className='' >

                        {renderFileInput('Aadhaar Card/Pan Card/ Id Proof *', 'aadhaarCardFront', 280, 200, 10, "Ex. adhar card,pan card,visa or any internationl document .", 'application/pdf,image/jpeg,image/png')}
                      </div>
                      <div>
                        <span></span>
                        {/* {renderFileInput(' Degree / Diploma / Course Certificate *', 'collegeCertificate', 280, 200, 10, "Any certification in interior design, 3D design, or a related course .", 'application/pdf/image/jpeg/image/png')} */}
                        {renderFileInput(' Degree / Diploma / Course Certificate *', 'collegeCertificate', 280, 200, 10, "Any certification in interior design, 3D design, or a related course .", 'application/pdf,image/jpeg,image/png')}
                      </div>

                    </div>

                    <div className='d-lg-flex ms-lg-4  pt-5 gap-3'>
                      <div>
                        <span></span>
                        {renderFileInput('Portfolio *', 'portfolio', 280, 200, 10, "Please add your work portfolio for verification.(pdf only)", 'application/pdf')}
                      </div>
                      <div>
                        <span></span>
                        {renderFileInput(' Thumbnail *', 'portfolioImages', 280, 200, 10, "Please upload your best work image for thumbnail .")}
                      </div>

                    </div>

                  </div>
                )}


                {step === 5 && (
                  <div className=' w-lg-75 ps-lg-5 '>
                    <Dos />
                    <input type="checkbox" name='agreetodos' value='agreetodos' className="large-checkbox ms-3" onChange={handleChange} required /><lable>I Agree to all the do’s  of connect my designer.*</lable>
                  </div>
                )}
                {step === 6 && (
                  <div>
                    <div className=' w-lg-75 px-lg-5 '>
                      <Dont />
                      <input type="checkbox" name='agreetodonts' value='agreetodonts' className="large-checkbox ms-3" onChange={handleChange} required /><lable>I Agree to all the Donts of connect my designer.*</lable>

                    </div>
                    <div className='d-flex'>
                      <button type="button" className="btn btn-secondary  mt-5 ms-5 border-gradient-btn-back  " onClick={prevStep}>
                        Back
                      </button>
                      <button type="button" className="btn btn-success div-center mt-5 mx-auto" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>

                )}

              </form>
            </div>
            <div className='col-lg-5 d-lg-flex d-none   fixed-right-center  div-center'>
              <div className=' shadow-sm demo-card-apply  px-3  py-2 col-8 justify-content-center'>
                <div className='div-center'>
                  <div className='border rounded-circle z-10 '  >
                    <img alt=''
                      className=' rounded-circle  profilephoto' style={{ width: '110px', height: '110px' }} src={imagePreviews.profilePhoto || '/assets/images/defouldp.png'} />
                  </div>
                </div>
                <div>
                  <div className=' div-center  fw-bold p-2 text-greadiant'><strong>Designer</strong><div className='  rounded py-1 m-1  card-subtitle' style={{ width: '100px', height: '30px' }} ><span className="text-greadiant"> {formData.name} </span></div> </div>
                  <div className='border-top-bottom py-2 gap-1 d-grid' style={{}}>
                    <span className='d-flex mt-2 '><strong className='my-auto'>Location : </strong> <div className='  rounded p-1 mx-lg-1 card-subtitle' style={{ minWidth: '100px', maxWidth: '250px', height: '30px' }} > {formData.city}</div></span>
                    <span className='d-flex mt-2 '><strong className='my-auto'>DOB : </strong> <div className='  rounded p-1 mx-lg-1 card-subtitle' style={{ minWidth: '100px', height: '30px' }} > {formData.dob}</div></span>
                    <span className='d-flex mt-2 '><strong className='my-auto'>Qualification : </strong><div className='  rounded p-1 mx-lg-1 card-subtitle' style={{ minWidth: '90px', height: '30px' }} > {formData.qualification}</div> </span>
                    <span className='d-flex mt-2 '><strong className='my-auto'>Experience : </strong><div className='  rounded p-1 mx-lg-1 card-subtitle' style={{ minWidth: '20px', height: '30px' }} >{formData.experience} Year</div> </span>
                  </div>
                  <span ><strong>About Me : </strong> </span>
                  <div className='  rounded p-1 card-subtitle  m-1' style={{ width: '100%', height: '100px' }} >
                    {formData.description}
                  </div>
                  <div className='d-flex border-top '>
                    <span className='col-2 my-auto' ><strong>Skills : </strong></span>
                    <div className='  rounded p-1 card-subtitle m-1' style={{ width: '100%', height: '30px' }}>
                      {formData.skills.join(', ')}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <span className='col-2 my-auto' ><strong>Styles : </strong> </span>
                    <div className='  rounded p-1 card-subtitle m-1' style={{ width: '100%', height: '30px' }}>
                      {formData.styles.join(', ')}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <span className='col-3 my-auto' ><strong>Softwares : </strong> </span>
                    <div className='  rounded p-1 card-subtitle m-1' style={{ width: '100%', height: '30px' }}>
                      {formData.software.join(', ')}
                    </div>
                  </div>
                  {/* <div className='d-flex'>
                    <span className='col-3 my-auto' ><strong>Languages : </strong> </span>
                    <div className='  rounded p-1 card-subtitle m-1' style={{ width: '100%', height: '30px' }}>
                      {formData.language.join(',')}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  };
  return (
    <div className='marginTop-18vh  '>
      <form>
        {renderStep()}
        <div className='d-flex pb-5 col-12 px-5 py-2 container '>
          <div className='  '>
            {step == 1 && (
              <button type="button" className="btn  border-gradient-btn-back  " onClick={() => navigate('/')}>
                Back to home
              </button>)}
          </div>
          <div className='col col-3'>
            {step > 1 && step < 6 && (
              <button
                type="button"
                className="btn  mt-0 px-lg-5 px-2 border-gradient-btn-back"
                onClick={prevStep}  >
                Back
              </button>
            )}
          </div>


          <div className='col col-lg-3 div-end '>
            {step < 6 && (
              <button type="button" className="border-gradient-btn-next btn px-lg-5 px-4" onClick={saveAndNext}>   Next   </button>)}

          </div>

        </div>
      </form>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Profile Photo Guidelines for Designers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <ul>
            <li>Your face should be clearly visible and centered.</li>
            <li>Use a professional, high-quality image with good lighting.</li>
            <li>A clean, neutral background is preferred.</li>
            <li>Avoid logos, text, or multiple people in the photo.</li>
          </ul>
          {photoToEdit && (
            <div style={{ position: 'relative', width: '100%', height: '300px' }}>
              <Cropper image={photoToEdit} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={handleCropComplete} cropShape="round" showGrid={false} style={{ cropAreaStyle: { borderRadius: '50%', }, }} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={handleSaveCrop}>Save</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default FormComponent;