import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase'; // Adjust the import path as needed

const HelpForm = () => {
   const [formData, setFormData] = useState({
      helpmobileNo: '',
      helpname: '',
      helpcategory: '',
      helpMessage: '',
      helpEmail: '',
   });
   const [isFormOpen, setIsFormOpen] = useState(false); // State to control form visibility
   const { user } = useAuth(); // Assuming useAuth returns an object with user information
   const modalRef = useRef(null); // Reference to the modal box

   useEffect(() => {
      if (user) {
         setFormData(prevState => ({ ...prevState, helpEmail: user.email }));
      }
   }, [user]);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (modalRef.current && !modalRef.current.contains(event.target)) {
            setIsFormOpen(false); // Close the form if clicked outside
         }
      };

      if (isFormOpen) {
         document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [isFormOpen]);

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value
      });
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      e.stopPropagation(); // Prevent the event from propagating to parent forms
      try {
          await axios.post('https://connectmydesigner.in/api/help', formData);
          alert('Help form submitted successfully');
          setFormData({
              helpmobileNo: '',
              helpname: '',
              helpcategory: '',
              helpMessage: '',
              helpEmail: user.email || '',
          });
          setIsFormOpen(false);
      } catch (error) {
          console.error('Error submitting the form', error);
          alert('Error submitting the form');
      }
  };
  

   const openForm = (e) => {
      e.preventDefault(); // Prevent default behavior (e.g., form submission)
      setIsFormOpen(true); // Open the form
  };

   const closeForm = () => {
      setIsFormOpen(false); // Close the form
   };

   return (
      <div>
       <button
    type="button" // Ensure the button does not trigger form submission
    className="help-button shadow btn bg-help-btn"
    onClick={openForm}
>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
               <path d="M17.5 4.5C9.525 4.5 3 9.9 3 16.5C3 19.65 4.45 22.65 7.06 24.75C7.06 25.65 6.48 28.05 3 31.5C3 31.5 8.075 31.5 12.425 27.75C14.02 28.2 15.76 28.5 17.5 28.5C25.475 28.5 32 23.1 32 16.5C32 9.9 25.475 4.5 17.5 4.5ZM18.95 22.5H16.05V19.5H18.95V22.5ZM21.56 15C21.125 15.6 20.545 15.9 19.965 16.2C19.53 16.5 19.385 16.65 19.24 16.95C18.95 17.25 18.95 17.55 18.95 18H16.05C16.05 17.25 16.195 16.8 16.485 16.35C16.775 16.05 17.355 15.6 18.08 15.15C18.515 15 18.805 14.7 18.95 14.4C19.095 14.1 19.24 13.65 19.24 13.35C19.24 12.9 19.095 12.6 18.805 12.3C18.515 12 18.08 11.85 17.645 11.85C17.21 11.85 16.92 12 16.63 12.15C16.34 12.3 16.195 12.6 16.195 13.05H13.295C13.44 12 13.875 11.1 14.6 10.5C15.325 9.9 16.34 9.75 17.645 9.75C18.95 9.75 20.11 10.05 20.835 10.65C21.56 11.25 21.995 12.15 21.995 13.2C22.14 13.8 21.995 14.4 21.56 15Z" fill="#FECC00"/>
            </svg>
            Help
         </button>

         {isFormOpen && (
            <div className="contact-modal col-lg-3 border px-4 position-fixed" ref={modalRef}>
               <div className="contact-header">
                  <h2>Contact Us</h2>
                  <button className="close-btn border rounded px-2 py-0" onClick={closeForm}>
                     <i className="fa-solid fa-xmark"></i>
                  </button>
               </div>
               <p className="subheading">We’re here to help! How can we help?</p>

               <form >
                  <div className="mb-3">
                     <label htmlFor="name" className="form-label">Name</label>
                     <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="helpname"
                        value={formData.helpname}
                        onChange={handleChange}
                        placeholder="Your full name"
                        required
                     />
                  </div>
                  <div className="mb-3">
                     <label htmlFor="mobile" className="form-label">Mobile Number</label>
                     <input
                        type="number"
                        className="form-control"
                        id="mobile"
                        name="helpmobileNo"
                        value={formData.helpmobileNo}
                        onChange={handleChange}
                        placeholder="Your mobile number"
                        required
                     />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="category" className="form-label">Category</label>
                     <select
                        className="form-select"
                        id="category"
                        name="helpcategory"
                        value={formData.helpcategory}
                        onChange={handleChange}
                        required
                     >
                        <option value="">Select category</option>
                        <option value="3D Designer">3D Designer</option>
                        <option value="Walkthrough Expert">Walkthrough Expert</option>
                        <option value="2D Drawing Designer">2D Drawing Designer</option>
                        <option value="Landscape Designer">Landscape Designer</option>
                     </select>
                  </div>

                  <div className="mb-3">
                     <label htmlFor="message" className="form-label">Message</label>
                     <textarea
                        className="form-control"
                        id="message"
                        name="helpMessage"
                        value={formData.helpMessage}
                        onChange={handleChange}
                        rows="3"
                        placeholder="Please explain your problem so that our team gets back to you."
                        required
                     />
                  </div>

                  {/* <button type="submit" className="help-submit-btn btn">Submit</button> */}
                  <button
    type="submit"
    className="help-submit-btn btn"
    onClick={handleSubmit} // Directly call handleSubmit on button click
>
    Submit
</button>

               </form>
            </div>
         )}
      </div>
   );
};

export default HelpForm;
