import React from 'react';
import { ReactTyped as Typed } from 'react-typed'; // Use ReactTyped as Typed

const TypingEffect = () => {
  return (
    <span className="gradient-text">
      <Typed
        strings={[
          "3D Designer",
          "Walkthrough Expert",
          "2D Drawing Designer",
          "Landscape Designer",
        ]}
        typeSpeed={100 }
        backSpeed={50}
        loop
      />
    </span>
  );
};

export default TypingEffect;
