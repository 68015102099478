import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../firebase';

const ManagePortfolio = ({ group, onGroupDeleted }) => {
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [groupName, setGroupName] = useState(group.groupName);
    const [userEmail, setUserEmail] = useState(group.userEmail); // Initialize with group userEmail
    const { user } = useAuth(); // Destructure user from useAuth hook

    // Fetch group images on mount
    const fetchGroupImages = async () => {
        try {
            const response = await axios.get(`https://connectmydesigner.com/portfolioapi/groups/${group._id}`);
            setImages(response.data.images);
        } catch (error) {
            console.error('Error fetching group images:', error);
        }
    };

    useEffect(() => {
        fetchGroupImages();
    }, [group]);

    // Handle image selection
    const handleImageChange = (e, index) => {
        const files = e.target.files;
        const newFiles = [...newImages];
        newFiles[index] = files[0]; // Replace the image at the given index
        setNewImages(newFiles);
    };

    // Handle adding new image input field
    const addNewImageField = () => {
        setNewImages([...newImages, null]); // Add a new empty slot for the image
    };

    // Handle removing an existing image
    const handleRemoveImage = async (indexToRemove) => {
        if (window.confirm('Are you sure you want to delete this image?')) {
            try {
                const imageToRemove = images[indexToRemove];
                await axios.delete(`https://connectmydesigner.com/portfolioapi/groups/${group._id}/image`, {
                    data: { image: imageToRemove }
                });
                const updatedImages = images.filter((_, index) => index !== indexToRemove);
                setImages(updatedImages);

            } catch (error) {
                console.error('Error deleting image:', error);
                alert('Failed to delete image');
            }
        }
    };

    // Handle group update
    const handleGroupUpdate = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('groupName', groupName);

        // Ensure userEmail is present during the update
        if (userEmail) {
            formData.append('userEmail', userEmail); // Retain the userEmail from the group
        } else if (user && user.email) {
            formData.append('userEmail', user.email); // Fallback to use the current authenticated user's email
        }

        newImages.forEach((image) => {
            if (image) {
                formData.append('images', image);
            }
        });

        try {
            await axios.put(`https://connectmydesigner.com/portfolioapi/groups/${group._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Group updated successfully!');
            setNewImages([]);
            fetchGroupImages(); // Reload updated images
        } catch (error) {
            console.error('Error updating group:', error);
        }
    };

    // Handle group deletion
    const handleDeleteGroup = async () => {
        if (window.confirm(`Are you sure you want to delete the group "${groupName}"? This action cannot be undone.`)) {
            try {
                await axios.delete(`https://connectmydesigner.com/portfolioapi/groups/${group._id}`);
                alert('Group deleted successfully!');
                onGroupDeleted(); // Call the parent function to refresh the group list or close modal
            } catch (error) {
                console.error('Error deleting group:', error);
            }
        }
    };

    return (
        <div className='container p-4'>
            <div className='d-flex justify-content-between align-items-center'>
                <h2>Manage Group: {groupName}</h2>
                <div style={{ marginTop: '20px' }}>
                    <button className='btn btn-danger'
                        style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', cursor: 'pointer' }}
                        onClick={handleDeleteGroup}
                    >
                        Delete Group
                    </button>
                </div>
            </div>

            {/* Edit group name */}
            <div className='d-flex align-items-center'>
                <label className='mr-2 mb-2'>Group Name: </label>
                <input
                    className='mx-2 px-2 rounded'
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
            </div>

            {/* Display current images */}
            <div>
                <div className='form-group d-flex justify-content-between align-items-center'>

                    <form onSubmit={handleGroupUpdate} encType="multipart/form-data">
                        <div className='brder' style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {images.map((image, index) => (
                                <div key={index} style={{ position: 'relative', margin: '30px' }}>
                                    <img
                                        src={`https://connectmydesigner.com/${image}`}
                                        alt={`Image ${index}`}
                                        style={{ width: '250px' }}
                                    />
                                    {/* Cross to remove image */}
                                    <button onClick={() => handleRemoveImage(index)} style={{ position: 'absolute', top: '0', right: '0', background: 'red', color: 'white', border: 'none',  borderRadius: '50%', width: '25px', height: '25px', cursor: 'pointer', }} >    X </button>
                                </div>
                            ))}
                        </div>

                        {/* Image picker inputs */}
                        <div className='border d-flex flex-wrap ' > {newImages.map((image, index) => (
                            <div key={index} className="image-picker col-3">
                                <label htmlFor={`newImage-${index}`} style={{ display: 'block', width: '200px', height: '200px', border: '2px dashed gray', textAlign: 'center', padding: '20px', margin: '20px', cursor: 'pointer', }}   >
                                    <div style={{ fontSize: '18px', color: 'gray' }}>
                                        <i className="fas fa-upload"></i><br />
                                        Max size: 5mb<br />  Upload Professional Photo
                                    </div>
                                </label>
                                <input id={`newImage-${index}`} type="file" onChange={(e) => handleImageChange(e, index)} style={{ display: 'none' }} />
                            </div>
                        ))}
                        </div>

                        {/* Add new image button */}
                        <button type="button" className="btn btn-secondary mt-2" onClick={addNewImageField}>
                            Add Image
                        </button>

                        {/* Submit button */}
                        <button className='btn btn-primary' type="submit" style={{ marginLeft: '10px' }}>Update Group</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ManagePortfolio;
