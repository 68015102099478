import React, { useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import MyData from '../designer/MyResidentialData';
import CommercialData from '../designer/myCommercialData';
import MyExteriorData from '../designer/myExteriorData';

const TabComponent = () => {
  const [activeKey, setActiveKey] = useState("residential");
  const [pricingType, setPricingType] = useState("Residential");

  const handleSelect = (k) => setActiveKey(k);
  const handlePricingChange = (val) => setPricingType(val);

  return (
    <div className=" min-height-60vh mt-1">
      <Tabs
        activeKey={activeKey}
        onSelect={handleSelect}
        className="mb-1 d-flex justify-content-between font-14px"
        fill >
        <Tab eventKey="residential" title=" ‎ ‎ ‎ ‎ ‎  ‎ ‎    3D Design ‎ ‎ ‎  ‎ ‎ ‎ ‎ ‎  " className="pt-4  ">
          <div className="d-flex justify-content-end mb-2 me-2">
            <div>
              <Button
                style={{
                  backgroundColor: pricingType === "Residential" ? "#FFD700" : "#D7D7D7", color: "#000",
                  transition: "background-color 0.8s, transform 0.8s", borderRadius: "10px 0 0 10px"
                }} className="border-0"
                onClick={() => handlePricingChange("Residential")}  >    Residential </Button>
              <Button style={{
                backgroundColor: pricingType === "Commercial" ? "#FFD700" : "#D7D7D7", color: "#000",
                transition: "background-color 0.8s, transform 0.8s",
              }}
                className="border-0 rounded-0" onClick={() => handlePricingChange("Commercial")}  >   Commercial </Button>
              <Button style={{
                backgroundColor: pricingType === "exterior" ? "#FFD700" : "#D7D7D7", color: "#000",
                transition: "background-color 0.8s, transform 0.8s", borderRadius: "0 10px 10px 0"
              }}
                className="border-0" onClick={() => handlePricingChange("exterior")}  > Exterior  </Button>
            </div>
          </div>
          {pricingType === "Residential" && <MyData />}
          {pricingType === "Commercial" && <CommercialData />}
          {pricingType === "exterior" && <MyExteriorData />}
        </Tab>
        <button disabled eventKey="commercial" title=" Walkthrough 🔒" className="pt-4 disabled-tab "></button>
        <button disabled eventKey="Exterior" title="2D Drawings 🔒" className="pt-4 disabled-tab"></button>
        <button disabled eventKey="2DDrawings" title="Landscape 🔒" className="pt-4 disabled-tab"></button>

      </Tabs>
    </div>
  );
};

export default TabComponent;
