


import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Accordion } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../firebase';

const MyFaq = () => {
    const [faqs, setFaqs] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editableFaqs, setEditableFaqs] = useState([]);
    const { user } = useAuth();
  
    useEffect(() => {
      if (user) {
        // Fetch FAQs from the backend for the current user
        axios.get(`https://connectmydesigner.com/faqs/?email=${user.email}`)
          .then(response => {
            setFaqs(response.data);
          })
          .catch(error => {
            console.error('There was an error fetching the FAQs:', error);
          });
      }
    }, [user]);

  const handleInputChange = (index, field, value) => {
    const updatedFaqs = [...editableFaqs];
    updatedFaqs[index][field] = value;
    setEditableFaqs(updatedFaqs);
  };

  const handleAddFaq = () => {
    setEditableFaqs([...editableFaqs, { question: '', answer: '' }]);
  };

  const handleRemoveFaq = (index) => {
    const updatedFaqs = editableFaqs.filter((_, i) => i !== index);
    setEditableFaqs(updatedFaqs);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredFaqs = editableFaqs.filter(faq => faq.question && faq.answer);
    const faqsWithEmail = filteredFaqs.map(faq => ({ ...faq, email: user.email }));

    // Save FAQs to the backend
    axios.post('https://connectmydesigner.com/faqs/', faqsWithEmail)
      .then(response => {
        setFaqs(response.data);
        setIsEditing(false);
      })
      .catch(error => {
        console.error('There was an error saving the FAQs:', error);
      });
  };

  const handleEdit = () => {
    setEditableFaqs(faqs.length > 0 ? [...faqs] : [{ question: '', answer: '' }]);
    setIsEditing(true);
  };

  return (
    <Container className="my-5  z-min">
     <div className='d-flex justify-content-between'>
     <span className="text-start ">My FAQs</span>    <div className="text-center ">
                <button className='btn border-0' onClick={handleEdit}>
                  {faqs.length > 0 ? 'Edit ' : 'Add FAQs'} <i className="fa-solid fa-pen-to-square"></i> 
                </button>
              </div>
     </div>

      {user ? (
        <Row className="justify-content-center z-min">
          <Col md={12}>
          {!isEditing ? (
            <>
              <Accordion>
                {faqs.map((faq, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>{faq.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
           
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              {editableFaqs.map((faq, index) => (
                <Card key={index} className="mb-3">
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Question</Form.Label>
                      <Form.Control
                        type="text"
                        value={faq.question}
                        onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={faq.answer}
                        onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Button variant="danger" size="sm" onClick={() => handleRemoveFaq(index)}>
                      Remove
                    </Button>
                  </Card.Body>
                </Card>
              ))}
              <div className="d-flex justify-content-between mt-3">
                <Button variant="secondary" onClick={handleAddFaq}>
                  Add More
                </Button>
                <Button type="submit" variant="primary">
                  Save FAQs
                </Button>
              </div>
            </Form>
          )}
        </Col>
        </Row>
      ) : (
        <p className="text-center">Please log in to view and manage your FAQs.</p>
      )}
    </Container>
  );
};

export default MyFaq;
