import React, { useRef, useState } from 'react';
import './blog.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useAuth } from '../firebase';
import HeaderUser from "./header-user";
import FooterDesigner from '../designer/footer-designer.js';


// Register chart components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Blog = () => {
    const [activeSection, setActiveSection] = useState('intro');
    const [isCardOpen, setIsCardOpen] = useState(false);

    const introRef = useRef(null);
    const opportunitiesRef = useRef(null);
    const challengesRef = useRef(null);
    const cmdRef = useRef(null);
    const chartRef = useRef(null);
    const visualRef = useRef(null);
    const conclusionRef = useRef(null);

    const handleScrollTo = (ref, sectionId) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        setActiveSection(sectionId);
    };

    const toggleCard = () => setIsCardOpen(!isCardOpen);

    // Data for the bar chart
    const data = {
        labels: ['2020', '2021', '2022'],
        datasets: [
            {
                label: 'Average Hourly Rate ($)',
                data: [21, 23, 26],
                backgroundColor: ['#A35196', '#CA50B6', '#F34A51'],
            },
        ],
    };

    return (
        <div >
            <HeaderUser />
            <div className="d-lg-flex gap-3  py-5 mt-lg-5 ">

                <aside className="col-lg-3 border-end ">
                    <ul>
                        <>
                            <div
                                className=" border p-2 fw-bold gre"
                                onClick={toggleCard}    >
                                Why Freelancing in India is the New 9-to-5 in 2024
                            </div>
                            {isCardOpen && (
                                <div className="card border-0">
                                    <ul>
                                        <li
                                            className={activeSection === 'intro' ? 'active' : ''}
                                            onClick={() => handleScrollTo(introRef, 'intro')}
                                        >
                                            Introduction
                                        </li>
                                        <li
                                            className={activeSection === 'opportunities' ? 'active' : ''}
                                            onClick={() => handleScrollTo(opportunitiesRef, 'opportunities')}
                                        >
                                            Opportunities
                                        </li>
                                        <li
                                            className={activeSection === 'challenges' ? 'active' : ''}
                                            onClick={() => handleScrollTo(challengesRef, 'challenges')}
                                        >
                                            Challenges
                                        </li>
                                        <li
                                            className={activeSection === 'cmd' ? 'active' : ''}
                                            onClick={() => handleScrollTo(cmdRef, 'cmd')}
                                        >
                                            How CMD Can Help
                                        </li>
                                        <li
                                            className={activeSection === 'chart' ? 'active' : ''}
                                            onClick={() => handleScrollTo(chartRef, 'chart')}
                                        >
                                            Earnings Growth
                                        </li>
                                        <li
                                            className={activeSection === 'visual' ? 'active' : ''}
                                            onClick={() => handleScrollTo(visualRef, 'visual')}
                                        >
                                            Visuals
                                        </li>
                                        <li
                                            className={activeSection === 'conclusion' ? 'active' : ''}
                                            onClick={() => handleScrollTo(conclusionRef, 'conclusion')}
                                        >
                                            Conclusion
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </>
                    </ul>
                </aside>

                <main className=" col-lg-9 container pe-5">
                    <header className="blog-header">
                        <h1>Why Freelancing in India is the New 9-to-5 in 2024</h1>
                    </header>

                    <section id="intro" ref={introRef} className={`blog-section ${activeSection === 'intro' ? 'active-section' : ''}`}>
                        <p>
                            The freelancing landscape in India is no longer just an alternative to traditional employment; it's becoming the new norm. With a 40% increase in freelancer participation and earnings over the past two years, freelancing is transforming how Indians work. However, while the opportunities are vast, the challenges are equally significant.
                        </p>
                    </section>

                    <section id="opportunities" ref={opportunitiesRef} className={`blog-section ${activeSection === 'opportunities' ? 'active-section' : ''}`}>
                        <h2>Opportunities</h2>
                        <ul>
                            <li><strong>Rapid Growth:</strong> The average hourly rate for Indian freelancers rose from $21 in 2020 to $26 in 2022. This increase reflects the growing demand for freelance services, particularly in fields like finance, marketing, and programming, which are among the highest-paid specialties.</li>
                            <li><strong>Flexibility and Autonomy:</strong> Freelancers enjoy the freedom to choose their projects, work hours, and clients, making it an attractive option for those seeking work-life balance.</li>
                            <li><strong>Digital Economy Boom:</strong> The rise of digital platforms like Upwork and Freelancer.com has made it easier for Indian freelancers to connect with global clients, expanding their market beyond geographical boundaries.</li>
                        </ul>
                    </section>

                    <section id="challenges" ref={challengesRef} className={`blog-section ${activeSection === 'challenges' ? 'active-section' : ''}`}>
                        <h2>Challenges</h2>
                        <ul>
                            <li><strong>Income Uncertainty:</strong> Despite the growth, freelancers face inconsistent income. A significant portion of freelancers struggles with financial instability, as payments are often delayed, and there is a lack of reliable payment solutions.</li>
                            <li><strong>Gender Pay Gap:</strong> While the freelance market offers more opportunities for women than traditional employment, the gender pay gap remains a challenge. Women freelancers in India earn only 82% of what their male counterparts make, with women averaging $19 per hour compared to $23 per hour for men.</li>
                            <li><strong>Taxation and GST:</strong> The complexities of GST and other tax regulations can be daunting for freelancers, leading to compliance challenges and potential financial penalties.</li>
                        </ul>
                    </section>

                    <section id="cmd" ref={cmdRef} className={`blog-section ${activeSection === 'cmd' ? 'active-section' : ''}`}>
                        <h2>How CMD Can Help Freelancers:</h2>
                        <p>
                            Connect My Designer (CMD) is India’s first 3D designer marketplace, offering a comprehensive solution for freelancers in the design industry. CMD connects freelancers with homeowners, interior designers, architects, and contractors through its tech-driven platform. Here's how CMD can assist freelancers:
                        </p>
                        <ul>
                            <li><strong>Secure Payments:</strong> CMD uses an escrow-based payment system, ensuring that freelancers are paid only after their work is approved, minimizing the risk of delayed or missed payments.</li>
                            <li><strong>Project Management Tools:</strong> CMD provides in-platform communication tools, such as chats and meetings, along with a dashboard to manage multiple projects seamlessly, making it easier for freelancers to stay organized.</li>
                            <li><strong>Access to Global Clients:</strong> CMD's AI-matching feature connects freelancers with the best-fit projects based on their skills and budget, giving them access to a broader range of clients, including international opportunities.</li>
                            <li><strong>Flexibility:</strong> CMD allows freelancers to easily change projects or clients if they're not satisfied, helping them find the perfect match for their skills and preferences.</li>
                        </ul>
                    </section>

                    <section id="chart" ref={chartRef} className={`blog-section ${activeSection === 'chart' ? 'active-section' : ''}`}>
                        <h3>Bar Graph - Freelancer Earnings Growth (2020-2022):</h3>
                        <ul>
                            <li><strong>Description:</strong> A bar graph showcasing the increase in average hourly rates for Indian freelancers from $21 in 2020 to $26 in 2022. This visual highlights the rapid growth of the freelancing economy in India.</li>    </ul>
                        <div className="">
                            <Bar data={data} />
                        </div>
                    </section>

                    <section id="visual" ref={visualRef} className={`blog-section ${activeSection === 'visual' ? 'active-section' : ''}`}>
                        <h3>Visuals:</h3>
                        <h4>Gender Pay Gap Infographic:</h4>
                        <ul>
                            <li><strong>Description:</strong> An infographic illustrating the gender pay gap among freelancers in India. It can compare the average hourly earnings between male ($23/hr) and female ($19/hr) freelancers, with a visual representation of the 82% earnings ratio.</li>
                        </ul>
                    </section>

                    <section id="conclusion" ref={conclusionRef} className={`blog-section ${activeSection === 'conclusion' ? 'active-section' : ''}`}>
                        <h2>Conclusion:</h2>
                        <p>
                            As freelancing continues to grow in India, platforms like CMD offer the tools and support needed to overcome challenges like income instability and payment security. With CMD, freelancers can focus on what they do best—creating exceptional designs—while leaving the administrative hassles behind.
                        </p>
                    </section>
                </main>

            </div>
            <FooterDesigner />
        </div>
    );
};

export default Blog;