import React from "react";
import { Link } from "react-router-dom";
import "./footer.css"; // Create a CSS file for any custom styles
// import logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary

const Footer = () => {
  return (
    <footer className="bg-dark text-light  py-4" style={{
      backgroundImage: "url('/assets/images/footer-bg.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}>
      <div className=" px-5 py-2 ">
        <div className="d-lg-flex  d-block">
          <div className="col-lg-2 col-sm-4">
            <img
              src="/assets/images/cmdlogo-white.png"
              alt="Logo"
              className=" mx-auto pt-5 "
              style={{ width: "200px  " }}
            />

          </div>
          <div className="col-lg-2 col-sm-4">
            <h5 className="text-warning  pb-3">About</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/user/about" className="text-light ">
                  <p> About us</p>
                </Link>
              </li>
              <li>
                <Link to="/user/steps-to-hire" className="text-light ">
                  <p> Steps to hire a designer</p>
                </Link>
              </li>
              <li>
                <Link to="/user/benefits" className="text-light ">
                  <p>    Benefits</p>
                </Link>
              </li>
              <li>
                <Link to="/user/best-designer" className="text-light ">
                  <p> Best designer</p>
                </Link>
              </li>
              <li>
                <Link to="/user/category" className="text-light ">
                  <p>   Category</p>
                </Link>
              </li>
              <li>
                <Link to="/user/success" className="text-light ">
                  <p>  Success</p>
                </Link>
              </li>
              <li>
                <Link to="/review" className="text-light ">
                  <p> Review</p>
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-light ">
                  <p>  FAQ</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4">
            <h5 className="text-warning pb-3">Product</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/features" className="text-light ">
                  <p> Features</p>
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-light ">
                  <p>Pricing</p>
                </Link>
              </li>
              <li>
                <Link to="/case-studies" className="text-light ">
                  <p> Case studies</p>
                </Link>
              </li>
              <li>
                <Link to="/reviews" className="text-light ">
                  <p>   Reviews</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4">
            <h5 className="text-warning pb-3">Company</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about" className="text-light ">
                  <p> About</p>
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-light ">
                  <p>  Contact us</p>
                </Link>
              </li>
              <li>
                <Link to="/careers" className="text-light ">
                  <p>   Careers</p>
                </Link>
              </li>
              <li>
                <Link to="/culture" className="text-light ">
                  <p>  Culture</p>
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-light ">
                  <p>  Blog</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4">
            <h5 className="text-warning pb-3">Support</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/help-center" className="text-light ">
                  <p>
                    Help center
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/report-bug" className="text-light ">
                  <p>  Report a bug</p>
                </Link>
              </li>
              <li>
                <Link to="/chat-support" className="text-light ">
                  <p>  Chat support</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-4 d-lg-flex">
            <div>
              <h5 className="text-warning pb-3">Contacts us</h5>
              <ul className="list-unstyled">
                <li className="text-light py-2 ">CMD@gmail.com</li>
                <li className="text-light py-2 ">986532659</li>
                <li className="text-light py-2 ">ABC</li>
              </ul>
            </div>

            <div className=" d-block icons-end  ">
              <p>
                <a href="https://facebook.com" className="text-info ">
                  <i className="fab fa-facebook "></i>
                </a>
              </p>
              <p>
                <a href="https://twitter.com" className="text-warning">
                  <i className="fab fa-twitter"></i>
                </a>
              </p>
              <p>
                <a href="https://instagram.com" className="text-denger">
                  <i className="fab fa-instagram"></i>
                </a>
              </p>
              <p>
                <a href="https://linkedin.com" className="text-warning">
                  <i className="fab fa-linkedin"></i>
                </a>
              </p>
              <p> <a href="https://youtube.com" className="text-warning">
                <i className="fab fa-youtube"></i>
              </a>
              </p>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
